import { Tabs } from "antd-mobile";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetGameV2 } from "../../hooks/home1bbet.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const CnySubcategoryList = ({
  selectedCategory = "",
  setSelectedCategory = () => {},
  setCurrentCategory = () => {},
  excludedCategories = [],
}) => {
  const { t } = useTranslation();
  const { data: allGames } = useGetGameV2(
    {
      enabled: !!CURRENCY,
    },
    {
      excludedCategories: excludedCategories,
    },
  );

  const onChangeCategory = (key) => {
    setSelectedCategory(key);
    if (allGames?.category_list && allGames?.category_list.length > 0) {
      const found = allGames?.category_list.findIndex(
        (category) => key === category.game_category_code,
      );
      if (found >= 0) {
        setCurrentCategory(allGames?.category_list[found]);
      }
    }
  };

  const renderTitle = (category) => {
    return (
      <div className={styles.outerContainer}>
        {/* {category.game_category_code === selectedCategory ? <img src={Before} alt="" /> : null} */}
        <div
          className={`${styles.titleContainer} ${
            category.game_category_code === selectedCategory ? styles.active : ""
          }`}
        >
          <div className={styles.title}>{t(`home.games.${category.game_category_code}`)}</div>
        </div>
        {/* {category.game_category_code === selectedCategory ? <img src={After} alt="" /> : null} */}
      </div>
    );
  };

  const renderTabItems = () => {
    if (!allGames?.category_list) return null;
    return allGames?.category_list.map((category) => {
      return <Tabs.Tab title={renderTitle(category)} key={category.game_category_code} />;
    });
  };
  useEffect(() => {
    if (allGames?.category_list && allGames?.category_list.length > 0) {
      setSelectedCategory(allGames?.category_list[0].game_category_code);
      setCurrentCategory(allGames?.category_list[0]);
    }
  }, [allGames]);
  return (
    <div className={`${styles.container}`}>
      <Tabs
        activeKey={selectedCategory}
        onChange={(key) => onChangeCategory(key)}
        className="cny-tab"
      >
        {renderTabItems()}
      </Tabs>
    </div>
  );
};

export default CnySubcategoryList;
