import { requestMain } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const defaultCurrency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetChurn = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/churn/list`, params, true, false);

//PAYMENT CHANNEL MENU
export const apiGetPaymentMethod = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/payment/method`, params, true, false);

export const apiGetWithdrawalMethod = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/withdrawal/method`, params, true, false);

//DEPOSIT VIRTUAL
export const apiGetDepositVirtualChannel = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/payment/usdt`, params, true, false);

export const apiPostDepositVirtual = ({ params }) =>
  requestMain("post", `/${defaultCurrency}/payment/usdt/deposit`, params, true, false);

export const apiPutDepositVirtualReceipt = ({ params }) =>
  requestMain("put", `/${defaultCurrency}/payment/usdt/deposit/receipt`, params, true, false);

//DEPOSIT BANK CARD

export const apiGetDepositBankList = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/payment/bank`, params, true, false);

export const apiPostDepositBank = ({ params }) =>
  requestMain("post", `/${defaultCurrency}/payment/bank/deposit`, params, true, false);

export const apiPutDepositBankReceipt = ({ params }) =>
  requestMain("put", `/${defaultCurrency}/payment/bank/deposit/receipt`, params, true, false);

//DEPOSIT ONLINE

export const apiGetPaymentOnlineMethods = () =>
  requestMain("get", `/${defaultCurrency}/payment/online/method`, {}, true, false);

export const apiGetPaymentOnlineProvider = ({ id }) =>
  requestMain("get", `/${defaultCurrency}/payment/online/${id}`, {}, true, false);

export const apiPostPaymentOnline = ({ params }) =>
  requestMain("post", `/${defaultCurrency}/payment/online/recharge`, params, true, false);

export const apiGetPromotionEvent = () =>
  requestMain("get", `/${defaultCurrency}/promotion/deposit`, {}, false, false);

//DEPOSIT 3rdParty USDT
export const apiGetPaymentUsdtOnlineMethods = () =>
  requestMain("get", `/${defaultCurrency}/payment/usdt/online/method`, {}, true, false);

export const apiGetPaymentUsdtOnlineProvider = ({ id }) =>
  requestMain("get", `/${defaultCurrency}/payment/usdt/online/${id}`, {}, true, false);

export const apiPostPaymentUsdtOnline = ({ params }) =>
  requestMain("post", `/${defaultCurrency}/payment/usdt/online/recharge`, params, true, false);

//WITHDRAW VIRTUAL

export const apiPostWithdrawVirtual = ({ params }) =>
  requestMain("post", `/${defaultCurrency}/withdrawal/usdt`, params, true, false);

//WITHDRAW BANK

export const apiPostWithdrawBank = ({ params }) =>
  requestMain("post", `/${defaultCurrency}/withdrawal`, params, true, false);

//RECORD

export const apiGetPaymentHistoryDeposit = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/payment/history/deposit`, params, true, false);

export const apiGetPaymentHistoryWithdrawal = ({ params }) =>
  requestMain("get", `/${defaultCurrency}/payment/history/withdrawal`, params, true, false);
