import { Image } from "antd-mobile";
import { useState } from "react";
import { PRODUCTION_NAME_DEV } from "../../../../../../config";
import { useGameLogin } from "../../../../../../context/GameLoginContext";
import { useLogin } from "../../../../../../context/LoginContext";
import styles from "./style.module.scss";
const GameItem = ({
  isPopup = false,
  isFromSearch = false,
  game,
  gameNameStyle = "",
  gameItemImageContainerStyle = "",
  gameBadgeStyle = "",
  maintainImageStyle = "",
  gameRecentStyle = "",
  gameItemPlatformStyle = "",
  grayScale = false,
}) => {
  const { handleLoginGame } = useGameLogin();
  const { checkIfLogin } = useLogin();
  const [imageLoaded, setImageLoaded] = useState(false);
  const renderMaintain = (game) => {
    if (game?.maintenance_status === 0) return null;
    return (
      <div
        className={`${styles.maintain} ${maintainImageStyle} ${
          grayScale === true ? styles.removeMask : ""
        }`}
      >
        <Image
          src={`/images/logo/${PRODUCTION_NAME_DEV}/game_maintain.png`}
          alt={`${game.game_code} maintenance`}
          className={`${styles.maintainImg}`}
          placeholder={false}
        />
      </div>
    );
  };

  const renderHot = (game) => {
    if (game?.is_hot === 0) return null;
    return (
      <Image
        src={`/images/logo/${PRODUCTION_NAME_DEV}/hot_game.png`}
        alt={`${game.game_code} hot`}
        className={`${styles.badge} ${gameBadgeStyle}`}
        placeholder={false}
      />
    );
  };

  const renderRecent = (game) => {
    if (!game?.is_recent) return null;
    return (
      <Image
        src={`/images/logo/${PRODUCTION_NAME_DEV}/recent.svg`}
        alt={`${game.game_code} recent`}
        className={`${styles.recentBadge} ${gameRecentStyle}`}
        placeholder={false}
      />
    );
  };

  const onGameClick = () => {
    if (game?.maintenance_status === 1) return;
    if (!checkIfLogin()) return;
    handleLoginGame({
      isPopup: isPopup,
      isFromSearch: isFromSearch,
      game3th: { game_3th_code: game?.game_3th_code },
      gameItem: game,
    });
  };

  const handleImageLoad = () => {
    setImageLoaded(!imageLoaded);
  };
  return (
    <div className={styles.gameItemContainer} onClick={onGameClick}>
      <div className={styles.gameImageContainer}>
        {renderHot(game)}
        {renderRecent(game)}
        {imageLoaded ? (
          <Image
            className={`${styles.platformImage} ${gameItemPlatformStyle}`}
            src={`/images/platform/logo/${game.game_3th_code}.png`}
            alt={game?.game_3th_code}
            placeholder={false}
          />
        ) : (
          ""
        )}

        <Image
          className={`${styles.gameImage} ${gameItemImageContainerStyle} ${
            grayScale === true ? (game?.maintenance_status === 0 ? "" : styles.maintainImg) : ""
          }`}
          src={game?.en_img_url}
          alt={game?.game_code}
          placeholder={
            <Image
              src={`/images/logo/${PRODUCTION_NAME_DEV}/game_loading.png`}
              className={styles.placeholder}
              placeholder={false}
            />
          }
          onLoad={handleImageLoad}
        />

        {imageLoaded ? renderMaintain(game) : ""}
      </div>
      <div className={styles.gameNameContainer}>
        {/* {imageLoaded ? ( */}
        <div className={`${styles.gameName} ${gameNameStyle}`}>
          {game?.en_name?.replaceAll(/\u00a0/g, " ")}
        </div>
        {/* ) : (
          ""
        )} */}
      </div>
    </div>
  );
};
export default GameItem;
