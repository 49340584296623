import { Image } from "antd-mobile";
import { Element } from "react-scroll";
import { PRODUCTION_NAME_DEV } from "../../../../../../config";
import { useGameLogin } from "../../../../../../context/GameLoginContext";
import { useLogin } from "../../../../../../context/LoginContext";
import { useSearch } from "../../../../context/SearchContext";
import gameListsEnum from "../../../../enumeration/gameListsEnum";
import styles from "./style.module.scss";

const PlatformItem = ({
  category,
  platformItemContainerStyle = "",
  platformImageType = "dark",
}) => {
  const { setShowSearchPopup, setSelectedGame3th } = useSearch();
  const { checkIfLogin } = useLogin();
  const { handleLoginGame } = useGameLogin();
  const onPlatformClick = (platform) => {
    if (platform.maintenance_status === 1) return;
    if (!checkIfLogin()) return;
    if (gameListsEnum[platform.game_3th_code].moreGameIsPopupV2) {
      setSelectedGame3th(platform);
      setShowSearchPopup(true);
    } else {
      handleLoginGame({ game3th: platform });
    }
  };

  const renderMaintain = (platform) => {
    if (platform.maintenance_status === 0) return null;
    return (
      <div className={styles.maintain}>
        <img
          src={`/images/logo/${PRODUCTION_NAME_DEV}/game_maintain.png`}
          alt={`${platform.game_3th_code} maintenance`}
          className={styles.maintainImg}
        />
      </div>
    );
  };

  const renderBadge = (platform) => {
    if (platform.is_hot === 0 && platform.is_new === 0) return null;
    if (platform.is_new === 1) {
      return (
        <div className={styles.badgeContainer}>
          <img
            src={`/images/logo/${PRODUCTION_NAME_DEV}/new.png`}
            alt={`${platform.game_3th_code} new`}
            className={styles.badge}
          />
        </div>
      );
    }
    if (platform.is_hot === 1) {
      return (
        <div className={styles.badgeContainer}>
          <img
            src={`/images/logo/${PRODUCTION_NAME_DEV}/hot_game.png`}
            alt={`${platform.game_3th_code} hot`}
            className={styles.badge}
          />
        </div>
      );
    }
  };

  const renderGamePlatformList = (platformList) => {
    return platformList.map((platform) => {
      //TODO: FIX this, hardcode remove blockchain game from platform list
      if (platform.game_3th_code === "blockchain" || platform.game_3th_code === "bglottery")
        return null;
      return (
        <div
          className={styles.platformContainer}
          onClick={() => onPlatformClick(platform)}
          key={platform.game_3th_code}
        >
          {renderBadge(platform)}
          {renderMaintain(platform)}
          <Image
            className={styles.banner}
            src={`/images/platform/banner/${platformImageType}/${PRODUCTION_NAME_DEV}/${platform.game_3th_code}.png`}
            alt={platform.game_3th_code}
            fallback={
              <Image
                className={styles.banner}
                src={`/images/platform/banner/${platformImageType}/${platform.game_3th_code}.png`}
                alt={platform.game_3th_code}
              />
            }
          />
        </div>
      );
    });
  };
  return (
    <>
      {category.game_category_code === "hot" ? (
        <div className={`${styles.gameContainer} ${platformItemContainerStyle}`}>
          <div className={styles.gameCategory}>{renderGamePlatformList(category.game_3th)}</div>
        </div>
      ) : (
        <Element
          className={`${styles.gameContainer} ${platformItemContainerStyle}`}
          name={category.game_category_code}
        >
          <div className={styles.gameCategory}>{renderGamePlatformList(category.game_3th)}</div>
        </Element>
      )}
    </>
  );
};
export default PlatformItem;
