import { LeftOutlined } from "@ant-design/icons";
import { Popup } from "antd-mobile";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CustomSearchBar, SearchItem } from "../../../../components";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import { useLazyLoad } from "../../../../hooks/lazyLoad/lazyLoad.hooks";
import styles from "./styleModule";
const SearchPopup = ({
  visible = false,
  onClose = () => {},
  game3thCode = "search_all",
  checkIfLogin = () => {},
  setSearchValue = () => {},
  searchResult = undefined,
  searchError = false,
  hasNextPage = false,
  fetchNextPage = () => {},
  isFetchingNextPage = false,
}) => {
  const { t } = useTranslation();
  const observer = useRef(null);

  const lazyLoad = useLazyLoad({
    triggerRef: observer,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    isFetching: isFetchingNextPage,
  });

  const onSearchClose = () => {
    setSearchValue("");
    onClose();
  };

  return (
    <Popup
      destroyOnClose={true}
      visible={visible}
      bodyStyle={{ width: "100vw", backgroundColor: "transparent" }}
      position={"right"}
      mask={false}
    >
      <div className={styles.searchContainer}>
        <div className={styles.container}>
          <img
            className={`${styles.logo}`}
            src={`/images/logo/${PRODUCTION_NAME_DEV}/logo_header.png`}
            alt={PRODUCTION_NAME_DEV}
          />
        </div>
        <div className={`${styles.titleBar}`}>
          <div className={styles.backButton} onClick={onSearchClose}>
            <LeftOutlined size={16} />
            <div className={`${styles.gameName}`}>
              {game3thCode === "search_all"
                ? t("home.search.placeholder")
                : t(`games.fullName.${game3thCode}`)}
            </div>
          </div>
        </div>
        <CustomSearchBar setSearchValue={setSearchValue} />
        <SearchItem
          className={styles.searchResultContainer}
          searchResult={searchResult}
          searchError={searchError}
          observer={observer}
          hasNextPage={hasNextPage}
          isPopup={true}
          checkIfLogin={checkIfLogin}
          gameNameStyle={styles.gameNameStyle}
        />
      </div>
    </Popup>
  );
};

export default SearchPopup;
