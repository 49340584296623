import { Button, Drawer } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppBar, FilterConditionGroup } from "../index.js";
import "./style";
import styles from "./styleModule";

function SearchFilter({
  title = "",
  visible,
  searchCondition,
  nowCondition,
  setNowCondition,
  nowConditionTextRender = (item) => {
    return item.name;
  },
  onReset = () => {},
  onOk = () => {},
  resetBtnText = " ",
  okBtnText = " ",
  onFilterClose = () => {},
}) {
  const { t } = useTranslation();
  const componentRender = () => {
    const foundItem = searchCondition.find((item) => item.id == nowCondition);
    if (foundItem?.component) return foundItem.component();
    return "";
  };
  return (
    <Drawer
      visible={visible}
      onClose={onFilterClose}
      placement="top"
      closable={false}
      bodyStyle={{ padding: "0", maxHeight: "80vh" }}
      height={"auto"}
    >
      <AppBar title={title} onClickEvent={onFilterClose} />
      <div className={`${styles.searchFilter} betRecord-search-filter`}>
        <FilterConditionGroup
          searchCondition={searchCondition}
          nowCondition={nowCondition}
          setNowCondition={setNowCondition}
          itemOnClick={onFilterClose}
          textRender={nowConditionTextRender}
        />
        <div className={`${styles.options}`}>{componentRender()}</div>
        <div className={`${styles.buttonGroup}`}>
          <Button
            type="primary"
            className={`${styles.button}`}
            ghost
            onClick={() => {
              const foundItem = searchCondition.find((item) => item.id == nowCondition);
              onReset(foundItem.id);
            }}
          >
            {resetBtnText}
          </Button>
          <Button type="primary" className={`${styles.button}`} onClick={onOk}>
            {okBtnText}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default SearchFilter;
