import { RightOutlined } from "@ant-design/icons";
import { Divider } from "antd-mobile";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer } from "..";
import { useLogin } from "../../context/LoginContext";
import { useLoading } from "../../context/modules/LoadingContext";
import { useGetAllCustomerService } from "../../hooks/customerService/customerService.hook";
import { checkUrlReg } from "../../plugins/utils";

function CustomerServiceListDrawer({ visible = false, handleVisible = () => {} }) {
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();
  const { setShowCustomerDrawer, setActiveCSLink, isLogin } = useLogin();

  const { data: csList, isLoading } = useGetAllCustomerService({
    enabled: !!isLogin === false && visible === true,
  });

  const openCSDrawer = () => {
    setShowCustomerDrawer(true);
  };

  const renderDrawerContent = () => {
    return (
      <div>
        {csList?.map((cs) => {
          return (
            <div style={{ margin: "16px 16px 0px" }} key={cs.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  if (!!cs.customer_service_link) {
                    setActiveCSLink(cs.customer_service_link);
                    checkUrlReg(cs.customer_service_link, openCSDrawer);
                  }
                  // window.location = cs.customer_service_link;
                }}
              >
                <div style={{ color: "var(--color-text-main)" }}>{cs.name}</div>
                <div style={{ color: "var(--color-radio-inactive)" }}>
                  <RightOutlined />
                </div>
              </div>
              <Divider style={{ marginTop: 16 }} />
            </div>
          );
        })}
      </div>
    );
  };
  const onClose = () => {
    handleVisible(false);
  };

  useEffect(() => {
    setIsLoading(!csList && visible === true);
  }, [csList, visible]);

  return (
    <Drawer
      placement={"right"}
      showDrawer={visible}
      setShowDrawer={handleVisible}
      title={t("normal.customerService")}
      height={100}
      onClose={onClose}
      renderContent={renderDrawerContent}
      closeIcon={false}
      destroyOnClose={true}
    />
  );
}

export default CustomerServiceListDrawer;
