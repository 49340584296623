import { Table } from "antd";
import React from "react";
import vars from "../../assets/styles/_variables.scss";
import "./style";

const CustomTable = ({
  columns,
  dataSource,
  padding = 0,
  pagination = {
    position: ["none", "none"],
  },
}) => {
  return (
    <div className="table-container">
      <Table
        columns={columns.filter((i) => i.hide !== true)}
        dataSource={dataSource}
        pagination={pagination}
        scroll={{
          x: Math.min(window.innerWidth, vars.maxWidthInPc) - padding,
        }}
      />
    </div>
  );
};

export default CustomTable;
