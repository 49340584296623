import { useInfiniteQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGameSearch } from "./service";

export const useSearchGame = (options, params) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useInfiniteQuery(
    [queryKey.SEARCH_GAME, ..._arrParams],
    ({ pageParam = 1 }) =>
      apiGameSearch({ ...params, page: pageParam }).then((res) => res?.data?.data),
    {
      ...options,
    },
  );
};
