import { Autoplay, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "../../../../components";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { checkUrlReg } from "../../../../plugins/utils";
import { useGetAllBanner } from "../../hooks/home.hook";
import styles from "./styleModule";

// Import Swiper styles
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-creative";
import { SHOW_YOUTUBE_CAROUSEL } from "../../../../config";
import { useLogin } from "../../../../context/LoginContext";
import { useFacebookPixel } from "../../../../hooks/fbPixel/fbPixel.hooks";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const Carousel = ({ bannerImageStyle = "" }) => {
  const { checkIfLogin } = useLogin();
  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();
  const navigate = useNavigate();
  const { data: allBanner } = useGetAllBanner({
    enabled: !!CURRENCY,
    staleTime: 60 * (60 * 1000), //60mins
    cacheTime: 60 * (60 * 1000), //60mins
    onError: (error) => {
      toast({ content: error, type: "error" });
    },
  });

  const itemOnClick = (item) => {
    if (!checkIfLogin()) return;

    if (isHavePixelIntegration) {
      pixelCreateCustomTracker({ eventId: "bannerClick", props: item });
    }

    if (item.type === "promo") {
      navigate(`/bonus?type=${item.promotion_type}&id=${item.promotion_id}`);
    } else {
      if (!item.url) return;
      checkUrlReg(item.url);
    }
  };

  return (
    <div className={styles.bannerContainer}>
      {SHOW_YOUTUBE_CAROUSEL ? (
        <iframe
          title="youtube video"
          src="https://www.youtube.com/embed/RTwNEfirmks?autoplay=1&controls=0&modestbranding=1"
          width={"100%"}
          frameborder="0"
          allow="autoplay"
          allowfullscreen
        />
      ) : allBanner?.length > 0 ? (
        <Swiper
          spaceBetween={0}
          slidesPerView={"auto"}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          grabCursor={true}
          loop={true}
          initialSlide={1}
          effect={"creative"}
          creativeEffect={
            allBanner?.length > 1
              ? {
                  prev: {
                    translate: ["-22%", 0, -500],
                  },
                  next: {
                    translate: ["22%", 0, -500],
                  },
                  perspective: true,
                }
              : {}
          }
          modules={[EffectCreative, Autoplay]}
          loopedSlides={allBanner?.length || 3}
          direction={"horizontal"}
          active
        >
          {[...allBanner.slice(-1), ...allBanner, ...allBanner.slice(0, -1)]?.map((item, index) => {
            return (
              <SwiperSlide
                key={`${item.id || item.promotion_id}_${index}`}
                onClick={() => {
                  itemOnClick(item);
                }}
              >
                <div className={styles.itemContainer}>
                  <div
                    // src={item.img}
                    style={{ backgroundImage: `url(${item.img}` }}
                    className={`${styles.image} ${bannerImageStyle}`}
                  />
                  {/* TEST
                </img> */}
                </div>
              </SwiperSlide>
            );
          })}
          {/* <swiper-slide>Slide 1</swiper-slide>
        <swiper-slide>Slide 2</swiper-slide>
        <swiper-slide>Slide 3</swiper-slide> */}
        </Swiper>
      ) : (
        <div className={styles.noData} />
      )}
    </div>
  );
};

export default Carousel;
