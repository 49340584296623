/* eslint-disable no-irregular-whitespace */
import { Badge, Button, Carousel } from "antd";
import React, { useEffect, useState } from "react";
import FastMarquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../../../components";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import { useLogin } from "../../../../context/LoginContext";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetMarqueeNotice } from "../../hooks/home.hook";
import MarqueeIcon from "../../icons/Marquee";
import "./style";
import styles from "./styleModule";
function Marquee({
  isHidden = false,
  showNotification = false,
  customImage = null,
  customMarqueeContainerStyle = {},
  marqueeTextContainerStyle = "",
}) {
  const { checkIfLogin, userInfo, isLogin } = useLogin();
  const { isError, error, data } = useGetMarqueeNotice({
    enabled: !!window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY),
    staleTime: 60 * (60 * 1000), //60mins
    cacheTime: 60 * (60 * 1000), //60mins
  });

  // const { data: message } = useGetMessage({
  //   enabled: !!isLogin && showNotification === true,
  // });

  const [isHaveNewMessage, setIsHaveNewMesssage] = useState(false);

  //1bbetv2
  // const checkNewMessage = () => {
  //   if (!message) return;
  //   const found = message.find((r) => r.read_status === 0);
  //   if (found) {
  //     setIsHaveNewMesssage(true);
  //   } else {
  //     setIsHaveNewMesssage(false);
  //   }
  // };

  // useEffect(() => {
  //   checkNewMessage();
  // }, [message]);
  //1bbetv2

  const [notice, setNotice] = useState("");
  const [modalNotice, setModalNotice] = useState();
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const getMarquee = () => {
    const result = data?.filter((item) => item.position == 2);
    if (!!result) {
      const regex = /(<([^>]+)>)/gi;
      let processNotice = result?.map((n) => {
        return n.content.replace(regex, "");
      });
      if (processNotice.length === 1) {
        setNotice(`${processNotice[0]}　　　　　　　　　　　　　　　　　　　　　　　　　　　`);
      } else {
        setNotice(processNotice?.join("　　　　　　　　　　　　　　　　　　　　　　　　　　　"));
      }
    }
  };

  const getModalNotice = () => {
    let result = data?.filter((item) => item.position == 3);
    const readRecord = JSON.parse(window.localStorage.getItem("noticeReadRecord"));
    if (!readRecord || result.length === 0) {
      setModalNotice(result);
    } else {
      const userRecord = readRecord.find((record) => record.user === userInfo?.account_id);
      if (!!userRecord) {
        // const hadReadIDGroup = Object.keys(userRecord.hadRead);
        // console.log("hadReadIDGroup", hadReadIDGroup);
        // const finalResult = result.filter(
        //   (item) => !hadReadIDGroup.includes(item.id.toString())
        // );
        const finalResult = [];
        result?.map((notice) => {
          if (userRecord.hadRead[notice.id]) {
            // console.log(
            //   "userRecord.hadRead[notice.id]",
            //   userRecord.hadRead[notice.id]
            // );
            const nowTime = new Date().valueOf();
            // console.log(
            //   "nowTime - userRecord.hadRead[notice.id]",
            //   nowTime - userRecord.hadRead[notice.id]
            // );
            if (nowTime - userRecord.hadRead[notice.id] > 86400000) {
              finalResult.push(notice);
            } else {
              return;
            }
          } else {
            finalResult.push(notice);
          }
        });
        setModalNotice(finalResult);
      } else {
        setModalNotice(result);
      }
    }
  };

  const ModalContentRender = () => {
    return (
      <div className="custom-notice-carousel">
        <Carousel
          afterChange={(currentSlide) => {
            // console.log(currentSlide);
          }}
        >
          {modalNotice?.map((notice, index) => {
            return (
              <div key={"notice" + index}>
                <div>
                  <div className="notice-title">{notice.title}</div>
                  <div
                    className="notice-content"
                    dangerouslySetInnerHTML={{ __html: notice.content }}
                  />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  };

  const saveDataToLocal = () => {
    if (!modalNotice || modalNotice?.length == 0) return;
    const readRecord = JSON.parse(window.localStorage.getItem("noticeReadRecord"));
    let record = [];
    let userRecord = { user: userInfo?.account_id, hadRead: {} };
    if (readRecord) {
      record = readRecord;
      const userIndex = record.findIndex((i) => i.user === userInfo?.account_id);
      if (userIndex > -1) {
        userRecord = readRecord[userIndex];
        record.splice(userIndex, 1);
      }
    }
    const nowTime = new Date().valueOf();
    modalNotice?.map((item) => {
      userRecord.hadRead[item.id] = nowTime;
    });
    record.push(userRecord);
    window.localStorage.setItem("noticeReadRecord", JSON.stringify(record));
  };

  useEffect(() => {
    //visible if loggedin
    if (!!data && !!userInfo) {
      getModalNotice();
    }
  }, [data, userInfo]);

  useEffect(() => {
    //visible even if not loggedin
    if (!!data) {
      getMarquee();
    }
  }, [data]);

  useEffect(() => {
    if (!!userInfo && modalNotice?.length > 0) setShowNoticeModal(true);
  }, [userInfo, modalNotice]);

  useEffect(() => {
    if (showNoticeModal) {
      saveDataToLocal();
    }
  }, [showNoticeModal]);

  // useEffect(() => {
  //   console.log("modalNotice", modalNotice);
  // }, [modalNotice]);

  const renderMessage = () => {
    if (customImage) return customImage;
    return <MarqueeIcon />;
  };

  const renderNotification = () => {
    if (showNotification === false) return null;
    return (
      <div
        onClick={() => {
          if (!checkIfLogin()) return;
          navigate("/notice");
        }}
        style={{ display: "flex", alignItems: "center", padding: 0, width: 22 }}
      >
        <Badge dot={isHaveNewMessage} offset={[-4, 3]} style={{ width: "8px", height: "8px" }}>
          <img
            src={`/images/logo/${PRODUCTION_NAME_DEV}/notification_icon.svg`}
            alt="notification"
          />
        </Badge>
      </div>
    );
  };

  return (
    <div
      className={`${styles.marquee} ${isHidden ? styles.hidden : ""}`}
      style={customMarqueeContainerStyle}
    >
      <Button
        type="link"
        onClick={() => {
          if (!checkIfLogin()) return;
          navigate("/announce");
        }}
        style={{ display: "flex", alignItems: "center", padding: 0, width: 22 }}
      >
        {renderMessage()}
      </Button>
      <div className={`${styles.marqueeTextContainer} ${marqueeTextContainerStyle}`}>
        <FastMarquee speed={80}>{notice}</FastMarquee>
      </div>
      {renderNotification()}
      <CustomModal
        isModalVisible={showNoticeModal}
        setIsModalVisible={setShowNoticeModal}
        title={t("notice.important")}
        content={ModalContentRender}
        okBtnText={t("normal.toCheck")}
        cancelBtnText={t("normal.close")}
        contentContainerStyle={styles.modalContainerStyle}
        onOk={() => {
          if (!checkIfLogin()) return;
          setShowNoticeModal(false);
          navigate("/announce");
        }}
      />
    </div>
  );
}

export default Marquee;
