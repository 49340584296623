import { requestWheel } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const defaultCurrency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetPrizeList = (id, params) =>
  requestWheel("get", `/${defaultCurrency}/promotion/roulette/${id}`, params, true, false);

export const apiSpinTheWheel = (params) =>
  requestWheel("put", `/${defaultCurrency}/promotion/roulette/spin`, params, true, true);
export const apiSpinTheWheelNew = (params) =>
  requestWheel("put", `/${defaultCurrency}/promotion/roulette/new/spin`, params, true, true);

export const apiGetSpinRecord = ({ params }) =>
  requestWheel("get", `/${defaultCurrency}/promotion/roulette/record`, params, true, false);

export const apiCheckShowBubble = ({ params }) =>
  requestWheel("get", `/${defaultCurrency}/promotion/roulette/check`, params, false, false);
