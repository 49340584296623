import { Grid } from "antd-mobile";
import React, { useEffect, useState } from "react";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useRecentPlayGames } from "../../context/RecentPlayGamesContext";
import { useGetGameV2 } from "../../hooks/home1bbet.hooks";
import { GameItem } from "../GameListContainer/components";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const CnyGameList = ({ currentCategory = {} }) => {
  const { recentPlayList } = useRecentPlayGames();
  const { data: allGames } = useGetGameV2({
    enabled: currentCategory.game_category_code === "hot" && !!CURRENCY,
  });
  const [gameList, setGameList] = useState([]);

  useEffect(() => {
    if (currentCategory) {
      if (currentCategory.game_category_code === "hot") {
        let _list = recentPlayList;
        if (allGames && allGames.hot_list) {
          _list = [..._list, ...allGames.hot_list];
        }
        setGameList(_list);
      } else {
      }
    }
  }, [currentCategory, allGames]);

  return (
    <div className={styles.container}>
      <Grid columns={4} gap={8} className={`${styles.hot_list}`}>
        {gameList.map((hot) => {
          return (
            <Grid.Item>
              <GameItem
                key={hot.game_code}
                game={hot}
                gameNameStyle={styles.gameNameStyle}
                maintainImageStyle={styles.maintainStyle}
                grayScale={true}
              />
            </Grid.Item>
          );
        })}
      </Grid>
    </div>
  );
};

export default CnyGameList;
