import { Spin } from "antd";
import { Grid } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import { GameItem } from "../../pages/Home/components/GameListContainer/components";
import NoData from "../NoData";
import styles from "./style.module.scss";
const SearchItem = ({
  className,
  searchResult,
  searchError,
  observer,
  hasNextPage,
  isPopup,
  isFromSearch = false,
  checkIfLogin,
  gameNameStyle,
}) => {
  const { t } = useTranslation();

  const renderSearchItem = () => {
    if (!searchResult || searchError || searchResult?.pages[0]?.data?.length <= 0)
      return <NoData title={t("normal.noData")} padding="0" height="calc(100vh - 192px)" />;
    return (
      <>
        <Grid columns={4} gap={8}>
          {searchResult?.pages?.map((perPageData, pageIndex) => {
            return perPageData?.data?.map((item, index) => {
              return (
                <Grid.Item key={`${item.game_code}-${item.game_id}`}>
                  <GameItem
                    isPopup={isPopup}
                    isFromSearch={isFromSearch}
                    game={item}
                    checkIfLogin={checkIfLogin}
                    gameNameStyle={gameNameStyle}
                  />
                </Grid.Item>
              );
            });
          })}
        </Grid>
        <div
          ref={observer}
          className={styles.loadingContainer}
          style={{ display: hasNextPage ? "flex" : "none" }}
        >
          <Spin />
        </div>
      </>
    );
  };

  return <div className={className}>{renderSearchItem()}</div>;
};

export default SearchItem;
