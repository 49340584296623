import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import {
  apiGetAllBanner,
  apiGetBanner,
  apiGetGameListByType,
  apiGetGameTypes,
  apiGetMessage,
  apiGetNotice,
  apiGetPromotionBanner,
  apiRedirectToGame,
} from "../service";

export const useGetBanner = (options) => {
  return useQuery([queryKey.HOME_BANNER], () => apiGetBanner().then((res) => res.data.data), {
    ...options,
  });
};

export const useGetPromotionBanner = (options) => {
  return useQuery(
    [queryKey.HOME_PROMOTION_BANNER],
    () =>
      apiGetPromotionBanner().then((res) => {
        const data = res.data.data;
        let newItems = [];
        data?.forEach((item) => {
          newItems.push({
            url: "",
            img: item.img_url,
            name: item.title,
            type: "promo",
            promotion_id: item.id,
            promotion_type: item.promotion_type,
          });
        });
        console.debug("data", data);
        return data;
      }),
    {
      ...options,
    },
  );
};

export const useGetAllBanner = (options) => {
  return useQuery(
    [queryKey.HOME_ALL_BANNER],
    () =>
      apiGetAllBanner().then((res) => {
        const globalBanner = res.data.data.global_banner;
        const promotionBanner = res.data.data.banner;
        return [
          ...globalBanner,
          ...promotionBanner.map((_banner) => ({
            ..._banner,
            url: "",
            img: _banner.img_url,
            name: _banner.title,
            type: "promo",
            promotion_id: _banner.id,
            promotion_type: _banner.promotion_type,
          })),
        ];
      }),
    {
      ...options,
    },
  );
};

export const useGetMarqueeNotice = (options) => {
  return useQuery([queryKey.MARQUEE], () => apiGetNotice().then((res) => res.data.data), {
    ...options,
  });
};

export const useGetGameTypes = (options) => {
  return useQuery(
    [queryKey.HOME_GAME_TYPE],
    () =>
      apiGetGameTypes().then((res) => {
        return res.data.data.filter((data) => data.code !== "coming_soon");
      }),
    {
      ...options,
    },
  );
};

export const useGetGameListByType = (options, gameTypeID, billingType) => {
  return useQuery(
    [queryKey.HOME_GAME_LIST, gameTypeID],
    () =>
      apiGetGameListByType({ billing_type: billingType }, gameTypeID).then((res) => res.data.data),
    {
      ...options,
    },
  );
};

export const useRedirectToGame = () => {
  const cache = useQueryClient();
  return useMutation(
    ({ params, query }) => apiRedirectToGame(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {},
    },
  );
};

export const useGetMessage = (options) => {
  return useQuery([queryKey.MESSAGE], () => apiGetMessage().then((res) => res.data.data), {
    ...options,
  });
};
