import { Form, Input } from "antd";
import "./style.scss";
const PasswordInput = ({ name, label, rules, dependencies, placeholder, onChange }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      dependencies={dependencies}
      className="Meta-Pass"
    >
      <Input.Password placeholder={placeholder} onChange={onChange} />
    </Form.Item>
  );
};

export default PasswordInput;
