const AgentIcon = ({ color1 = "#BFDBFC", color2 = "#4785F7" }) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2514_31074)">
        <path
          d="M16.1984 4.35938C13.0544 4.35938 10.4983 6.91549 10.4983 10.0596C10.4983 13.1438 12.9104 15.6399 16.0544 15.7479C16.1504 15.7359 16.2464 15.7359 16.3184 15.7479H16.4024C17.8777 15.6986 19.276 15.0776 20.3017 14.0161C21.3274 12.9546 21.9 11.5357 21.8986 10.0596C21.8986 6.91549 19.3425 4.35938 16.1984 4.35938Z"
          fill={color1}
        />
        <path
          d="M22.2942 18.9358C18.9462 16.7037 13.486 16.7037 10.1139 18.9358C8.5899 19.9558 7.74988 21.3359 7.74988 22.812C7.74988 24.288 8.5899 25.6561 10.1019 26.6641C11.782 27.7922 13.99 28.3562 16.1981 28.3562C18.4061 28.3562 20.6142 27.7922 22.2942 26.6641C23.8063 25.6441 24.6463 24.276 24.6463 22.788C24.6343 21.3119 23.8063 19.9438 22.2942 18.9358Z"
          fill={color1}
        />
        <path
          d="M29.3861 10.7627C29.5781 13.0908 27.9221 15.1309 25.63 15.4069H25.57C25.498 15.4069 25.426 15.4069 25.366 15.4309C24.202 15.4909 23.134 15.1189 22.33 14.4349C23.566 13.3309 24.274 11.6748 24.13 9.87471C24.0477 8.93867 23.7298 8.03869 23.206 7.25859C23.8348 6.95268 24.5286 6.80433 25.2275 6.82636C25.9264 6.84838 26.6095 7.04012 27.2178 7.38502C27.8261 7.72992 28.3413 8.21764 28.7191 8.80609C29.0968 9.39455 29.3258 10.0661 29.3861 10.7627Z"
          fill={color2}
        />
        <path
          d="M31.7848 21.8677C31.6888 23.0317 30.9448 24.0398 29.6968 24.7238C28.4967 25.3838 26.9847 25.6958 25.4847 25.6598C26.3487 24.8798 26.8527 23.9078 26.9487 22.8757C27.0687 21.3877 26.3607 19.9596 24.9446 18.8195C24.1406 18.1835 23.2046 17.6795 22.1846 17.3075C24.8366 16.5394 28.1727 17.0555 30.2248 18.7115C31.3288 19.5996 31.8928 20.7156 31.7848 21.8677Z"
          fill={color2}
        />
        <path
          d="M2.99497 10.7627C2.80297 13.0908 4.45901 15.1309 6.75106 15.4069H6.81107C6.88307 15.4069 6.95507 15.4069 7.01507 15.4309C8.1791 15.4909 9.24713 15.1189 10.0511 14.4349C8.81512 13.3309 8.1071 11.6748 8.2511 9.87471C8.33344 8.93867 8.65132 8.03869 9.17512 7.25859C8.54632 6.95268 7.85254 6.80433 7.15362 6.82636C6.4547 6.84838 5.77163 7.04012 5.16334 7.38502C4.55505 7.72992 4.03979 8.21764 3.66203 8.80609C3.28426 9.39455 3.05533 10.0661 2.99497 10.7627Z"
          fill={color2}
        />
        <path
          d="M0.597392 21.8696C0.693394 23.0337 1.43741 24.0417 2.68545 24.7257C3.88548 25.3858 5.39751 25.6978 6.89755 25.6618C6.03353 24.8818 5.52952 23.9097 5.43351 22.8777C5.31351 21.3896 6.02153 19.9615 7.43756 18.8215C8.24158 18.1855 9.17761 17.6814 10.1976 17.3094C7.54557 16.5414 4.20948 17.0574 2.15743 18.7135C1.0534 19.6015 0.489389 20.7176 0.597392 21.8696Z"
          fill={color2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2514_31074">
          <rect width="32" height="32.0008" fill="white" transform="translate(0.198853 0.359375)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AgentIcon;
