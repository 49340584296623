import { Col } from "antd";
import { useTranslation } from "react-i18next";
import styles from "../styleModule";
const CenterGroup = ({ title, titleEllipsis, span = 16 }) => {
  const { t } = useTranslation();
  return (
    <Col span={span}>
      <div className={`${styles.navBarTitleBox}`}>
        <span
          className={`${styles.navBarTitle} ${titleEllipsis ? styles.navBarTitleEllipsis : ""}`}
        >
          {title ? t(title) : ""}
        </span>
      </div>
    </Col>
  );
};

export default CenterGroup;
