import { LangSelectorPopover } from "../../../../components";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "../../../../config";
import styles from "./styleModule";
const LangHeader = ({
  style = {},
  langSelectorContainer = "",
  showLanguageSelector = true,
  logoStyle = "",
  showArrow = false,
  showTitle = false,
  showIcon = true,
  showBalance = false,
}) => {
  return (
    <div className={styles.container} style={{ ...style }}>
      <img
        className={`${styles.logo} ${logoStyle}`}
        src={`/images/logo/${PRODUCTION_NAME_DEV}/logo_header.png`}
        alt={PRODUCTION_NAME_DISPLAY}
      />
      {showLanguageSelector ? (
        <LangSelectorPopover
          customContainerStyle={langSelectorContainer}
          showArrow={showArrow}
          showTitle={showTitle}
          showIcon={showIcon}
        />
      ) : null}
    </div>
  );
};

export default LangHeader;
