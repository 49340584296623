import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetGameV2 } from "../../hooks/home1bbet.hooks";
import { VMCategoryItem } from "./components";
import styles from "./styles.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
const VMMenu = ({
  selectedCategory = "",
  setSelectedCategory = () => {},
  setCurrentCategory = () => {},
  excludedCategories = [],
}) => {
  const { t } = useTranslation();

  const { data: allGames } = useGetGameV2(
    {
      enabled: !!CURRENCY,
    },
    {
      excludedCategories: excludedCategories,
    },
  );

  const onSelectCategory = (category) => {
    setSelectedCategory(category.game_category_code);
    setCurrentCategory(category);
  };

  const renderCategoryList = () => {
    if (!allGames?.category_list) return null;
    return allGames?.category_list.map((category, index) => {
      const maxIndex = allGames?.category_list.length - 1;
      const normalize = Math.floor(Math.abs(index - maxIndex / 2));
      return (
        <VMCategoryItem
          key={category.game_category_code}
          selectedCategory={selectedCategory}
          category={category}
          onClick={() => onSelectCategory(category)}
          containerStyle={{
            transform: `translate(0, ${normalize * 4.7}px)`,
          }}
        />
      );
    });
  };
  const renderTitle = () => {
    return <div className={styles.title}>{t(`home.games.${selectedCategory}`).toUpperCase()}</div>;
  };

  useEffect(() => {
    if (allGames?.category_list) {
      setSelectedCategory(allGames?.category_list[0]?.game_category_code);
      setCurrentCategory(allGames?.category_list[0]);
    }
  }, [allGames]);
  return (
    <div
      className={styles.container}
      style={{
        background: `url("/images/gameCategories/${PRODUCTION_NAME_DEV}/background.png") no-repeat center center / 100%`,
      }}
    >
      <div className={styles.categoryContainer}>{renderCategoryList()}</div>
      {renderTitle()}
    </div>
  );
};

export default VMMenu;
