import { RightOutlined } from "@ant-design/icons";
import { Form, Select } from "antd";
import React from "react";
import "./style";

const CustomSelect = ({
  placeholder = "",
  // value,
  // defaultValue,
  style = {},
  label = "",
  name = "",
  rules = [],
  items = [],
  disabled = false,
  bordered = true,
  onClick = () => {},
  initialValue,
  tooltip,
}) => {
  const { Option } = Select;

  return (
    <div style={style} className={"container"}>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        className={"select-container"}
        initialValue={initialValue}
        tooltip={tooltip}
      >
        <Select
          bordered={bordered}
          disabled={disabled}
          showArrow={false}
          onClick={disabled ? () => {} : onClick}
          dropdownStyle={{ display: "none" }}
        >
          {items.map((item) => {
            return (
              <Option selected key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <RightOutlined
        className={`icon ${label === "" ? " borderless" : ""}`}
        onClick={disabled ? () => {} : onClick}
      />
    </div>
  );
};

export default CustomSelect;
