import { Col } from "antd";

const RightGroup = ({ span = 4 }) => {
  return (
    <Col
      span={span}
      style={{
        textAlign: "right",
      }}
    />
  );
};

export default RightGroup;
