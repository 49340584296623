import { Turnstile } from "@marsidev/react-turnstile";
import { useTranslation } from "react-i18next";
import { LOGIN_CLOUDFLARE_KEY } from "../../config";
import { getLang } from "../../plugins/utils";
import CustomModal from "../CustomModal";
import styles from "./style.module.scss";

const CloudflareAuth = ({
  ref,
  setPassCloudflare,
  theme,
  style,
  isModalVisible,
  setIsModalVisible,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Turnstile
        className={styles.cloudflare}
        ref={ref}
        siteKey={LOGIN_CLOUDFLARE_KEY}
        onSuccess={() => setPassCloudflare(true)}
        onError={() =>
          setTimeout(() => {
            ref.current.reset();
          }, [10000])
        }
        onExpire={() => {
          setPassCloudflare(false);
          ref.current.reset();
        }}
        options={{ language: getLang(), retry: "never", theme: theme }}
        style={{ width: "100%", ...style }}
      />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        content={t("errorMessage.notPassCloudflare")}
        needIcon={true}
        okBtnText={t("normal.confirm")}
        isHaveCancel={false}
      />
    </>
  );
};

export default CloudflareAuth;
