import { CloseOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { Mask, Radio } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDailyCheck } from "../../../../context/DailyCheckContext";
import { useLogin } from "../../../../context/LoginContext";
import localStorageKey from "../../../../enumerations/localStorageKey";
import styles from "./style.module.scss";
import "./style.scss";

const DailyCheckPopup = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data: dailyCheckData, lang } = useDailyCheck();
  const { userInfo, checkIfLogin } = useLogin();
  const tomorrowCheck = JSON.parse(localStorage.getItem(localStorageKey.TOMORROW_CHECK));
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleTomorrowCheck = () => {
    if (!checked) return;
    const tomorrowTime = new Date(new Date().toLocaleDateString()).getTime() + 86400000;
    if (tomorrowCheck) {
      const userCheckIndex = tomorrowCheck.findIndex((item) => item.user === userInfo?.account_id);
      if (userCheckIndex === -1) {
        tomorrowCheck.push({ user: userInfo?.account_id, time: tomorrowTime });
      } else {
        tomorrowCheck[userCheckIndex].time = tomorrowTime;
      }
      localStorage.setItem(localStorageKey.TOMORROW_CHECK, JSON.stringify(tomorrowCheck));
    } else {
      localStorage.setItem(
        localStorageKey.TOMORROW_CHECK,
        JSON.stringify([{ user: userInfo?.account_id, time: tomorrowTime }]),
      );
    }
  };

  const closeModal = () => {
    handleTomorrowCheck();
    setVisible(false);
  };

  useEffect(() => {
    if (!dailyCheckData) return;
    if (dailyCheckData.length !== 0 && dailyCheckData.current_signin_day && !state?.goBet) {
      if (!tomorrowCheck) {
        setVisible(true);
      } else {
        const userCheck = tomorrowCheck.filter((item) => item.user === userInfo?.account_id);
        if (userCheck.length <= 0) {
          setVisible(true);
        } else {
          const nowTime = new Date().getTime();
          if (nowTime - userCheck[0].time >= 0) {
            setVisible(true);
          }
        }
      }
    }
  }, [dailyCheckData]);

  return (
    <>
      <Mask visible={visible} opacity="thick" onMaskClick={closeModal}>
        <div className={styles.container}>
          <div className={styles.header}>
            <CloseOutlined className={styles.close} onClick={closeModal} />
          </div>
          <div className={styles.imgContainer}>
            <Image
              src={`/images/dailyCheck/${lang}/daily_check.png`}
              fallback="/images/dailyCheck/daily_check.png"
              alt="daily_check"
              className={styles.img}
              preview={false}
            />
          </div>
          <div className={styles.btnContainer}>
            <Image
              src={`/images/dailyCheck/${lang}/check_btn.png`}
              fallback="/images/dailyCheck/check_btn.png"
              alt="check_btn"
              className={styles.checkBtnImg}
              onClick={() => {
                if (!checkIfLogin()) return;
                closeModal();
                navigate(`/dailyCheck`);
              }}
              preview={false}
            />
          </div>
          <div className={`${styles.radioContainer} radio-container`}>
            <Radio checked={checked} onClick={() => setChecked(!checked)}>
              {t("dailyCheck.noMore.reminder")}
            </Radio>
          </div>
        </div>
      </Mask>
    </>
  );
};

export default DailyCheckPopup;
