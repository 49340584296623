import { useMutation, useQuery } from "@tanstack/react-query";
import { useLogin } from "../../context/LoginContext";
import queryKey from "../../enumerations/queryKey";
import { apiGetDailyCheck, apiPostDailyCheck } from "./service";

export const useGetDailyCheck = (options) => {
  const { isLogin } = useLogin();
  return useQuery([queryKey.DAILY_CHECK], () => apiGetDailyCheck().then((res) => res.data.data), {
    staleTime: 60 * (60 * 1000), //60mins
    cacheTime: 60 * (60 * 1000), //60mins
    enabled: !!isLogin,
    ...options,
  });
};

export const useCheck = (options) => {
  return useMutation(
    (id, params) =>
      apiPostDailyCheck(id, params).then((res) => {
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};
