import { HEAD_FBPIXEL_ID } from "../../config";

export const useFacebookPixel = () => {
  const pixelCreateCustomTracker = ({ eventId, props = {} }) => {
    window?.fbq("trackCustom", eventId, props);
  };

  return {
    isHavePixelIntegration: !!HEAD_FBPIXEL_ID,
    pixelCreateCustomTracker,
  };
};
