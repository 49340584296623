import { DatePicker, Form, TimePicker } from "antd";
import React from "react";
import "./style.scss";

const CustomDatePicker = ({
  label = "",
  rules = [],
  shouldUpdate,
  initialValue,
  tooltip,
  name = "",
  type = "",
  dateFormat = "YYYY-MM-DD",
  timeFormat = "HH:mm:ss",
  hidden = false,
}) => {
  const renderPicker = (type) => {
    switch (type) {
      case "time":
        return (
          <TimePicker format={timeFormat} placement={"topLeft"} inputReadOnly showNow={false} />
        );
      case "date":
        return (
          <DatePicker format={dateFormat} placement={"topLeft"} inputReadOnly showToday={false} />
        );
      default:
        return (
          <DatePicker format={dateFormat} placement={"topLeft"} inputReadOnly showToday={false} />
        );
    }
  };

  return (
    <Form.Item
      hidden={hidden}
      className="date_container"
      label={label}
      name={name}
      rules={rules}
      shouldUpdate={shouldUpdate}
      initialValue={initialValue}
      tooltip={tooltip}
    >
      {renderPicker(type)}
    </Form.Item>
  );
};

export default CustomDatePicker;
