import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_LANG } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import { useGetDailyCheck } from "../hooks/dailyCheck/dailyCheck.hooks";

const DailyCheckContext = createContext({
  data: [],
  setting: [],
  activeDay: 1,
  limitType: {},
  setActiveDay: () => {},
  refetchDailyCheckData: () => {},
  lang: "en",
});

export const DailyCheckContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const [data, setData] = useState([]);
  const [setting, setSetting] = useState([]);
  const [activeDay, setActiveDay] = useState(1);
  const [limitType, setLimitType] = useState({});
  const lang = localStorage.getItem(localStorageKey.LANG_SETTING) || DEFAULT_LANG;
  const { data: dailyCheckData, refetch: refetchDailyCheckData } = useGetDailyCheck();
  const limitTypeEnum = {
    0: {
      key: "none",
      src: "",
      fallback: "",
      name: "",
      total_value: "",
      onClick: () => {},
    },
    1: {
      key: "recharge",
      src: `/images/dailyCheck/${lang}/go_recharge.png`,
      fallback: "/images/dailyCheck/go_recharge.png",
      name: "go_recharge",
      total_value: "total_deposit_amount",
      onClick: () => navigate("/exchange"),
    },
    2: {
      key: "bet",
      src: `/images/dailyCheck/${lang}/go_bet.png`,
      fallback: "/images/dailyCheck/go_bet.png",
      name: "go_bet",
      total_value: "total_bet_amount",
      onClick: () =>
        navigate("/", {
          state: {
            goBet: true,
          },
        }),
    },
  };

  useEffect(() => {
    if (!dailyCheckData) return;
    setData(dailyCheckData);
    if (dailyCheckData.length !== 0) {
      setSetting(dailyCheckData.data.setting);
      setActiveDay(dailyCheckData.last_signin_day + 1);
      if (dailyCheckData.last_signin_day !== dailyCheckData.data.setting.length) {
        setLimitType(
          limitTypeEnum[dailyCheckData.data.setting[dailyCheckData.last_signin_day]?.type],
        );
      } else {
        setLimitType(limitTypeEnum[0]);
      }
    }
  }, [dailyCheckData]);

  useEffect(() => {
    if (pathname === "/dailyCheck") refetchDailyCheckData();
  }, [pathname]);

  return (
    <DailyCheckContext.Provider
      value={{
        data,
        setting,
        activeDay,
        limitType,
        setActiveDay,
        refetchDailyCheckData,
        lang,
      }}
    >
      {children}
    </DailyCheckContext.Provider>
  );
};
export const useDailyCheck = () => {
  const DailyCheckValue = useContext(DailyCheckContext);
  return DailyCheckValue;
};
