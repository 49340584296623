import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import styles from "./styleModule";

function FullWidthItem({
  title = "",
  content = "",
  showArrow = false,
  onClick = () => {},
  arrowOnClick = () => {},
  contentColor,
  borderBottom,
}) {
  return (
    <div
      className={`${styles.fullWidthItem}`}
      onClick={onClick}
      style={{ borderBottom: borderBottom }}
    >
      <div className={`${styles.title}`}>{title}</div>
      <div
        className={`${styles.content}`}
        style={{
          width: showArrow ? "calc(50% - 24px)" : "",
          color: contentColor,
        }}
      >
        {content}
      </div>
      {showArrow ? (
        <Button
          onClick={arrowOnClick}
          shape="circle"
          type="text"
          size="small"
          icon={<RightOutlined className={styles.arrow} />}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default FullWidthItem;
