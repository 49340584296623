import React from "react";

import AuthInfo from "./modules/AuthInfo";
import GameInfo from "./modules/GameInfo";
import Lang from "./modules/Lang";
import { WebsocketGameContextProvider } from "./WebsocketGameContext";
import { WebsocketStockContextProvider } from "./WebsocketStockContext";

import { DailyCheckContextProvider } from "./DailyCheckContext";
import { DeviceContextProvider } from "./DeviceContext";
import { GameLoginContextProvider } from "./GameLoginContext";
import { LoginContextProvider } from "./LoginContext";
import AuthInfoContext from "./modules/AuthInfo/AuthInfo";
import GameInfoContext from "./modules/GameInfo/GameInfo";
import LangContext from "./modules/Lang/Lang";
import { LoadingContextProvider } from "./modules/LoadingContext";

const ContextList = [
  // { name: "DeviceContextProvider", context: DeviceContextProvider },
  { name: "LoadingContext", context: LoadingContextProvider },
  { name: "AuthInfo", context: AuthInfo },
  { name: "GameInfo", context: GameInfo },
  { name: "WSGameProvider", context: WebsocketGameContextProvider },
  { name: "WSStockProvider", context: WebsocketStockContextProvider },
  { name: "Lang", context: Lang },
];

function Context(props) {
  let output = props.children;
  ContextList.forEach((contextInfo) => {
    output = <contextInfo.context>{output}</contextInfo.context>;
  });
  return output;
}

export {
  AuthInfoContext,
  DailyCheckContextProvider,
  DeviceContextProvider,
  GameInfoContext,
  GameLoginContextProvider,
  LangContext,
  LoadingContextProvider,
  LoginContextProvider,
  WebsocketGameContextProvider,
  WebsocketStockContextProvider,
};

export default Context;
