import { VIPIcon } from "../../../../../../components";
import { MIN_DECIMAL_POINT } from "../../../../../../config";
import { useLogin } from "../../../../../../context/LoginContext";
import { useGetUserWallet } from "../../../../../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import styles from "./style.module.scss";
const AccountContainer = ({
  containerStyle = "",
  userNameStyle = "",
  currencyStyle = "",
  moneyStyle = "",
  moneyFractionStyle = "",
  showImage = false,
  imageURL = "/images/home/purse.png",
}) => {
  const { data: walletData } = useGetUserWallet({});
  const { userInfo } = useLogin();
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={`${styles.username} ${userNameStyle}`}>
        <div className={styles.vip}>
          <VIPIcon vipLevel={userInfo?.level_name} />
        </div>
        <div>{userInfo?.account}</div>
      </div>
      <div className={`${styles.balance} ${showImage ? styles.withImage : ""}`}>
        {showImage ? <img src={imageURL} className={`${styles.purseImage}`} alt="purse" /> : null}
        <div className={`${styles.currency} ${currencyStyle}`}>{userInfo?.currency}</div>
        <div className={styles.moneyGroup}>
          <div className={`${styles.money} ${moneyStyle}`}>
            {walletData?.money ? moneyFormat(walletData.money).split(".")[0] : "0"}
          </div>
          {moneyFormat(walletData?.money).split(".")[1] !== "0" && MIN_DECIMAL_POINT > 0 ? (
            <div className={`${styles.moneyFraction} ${moneyFractionStyle}`}>
              .{walletData?.money ? moneyFormat(walletData.money).split(".")[1] : "00"}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountContainer;
