import { toast } from "../components";
import localStorageKey from "../enumerations/localStorageKey";

export const copyText = (text, successMessageContent) => {
  if (!text) return;
  // navigator.clipboard.writeText(text);
  const selectGroup = document.getElementById("copy-group");
  let tempArea = document.createElement("textarea");
  selectGroup?.appendChild(tempArea);
  tempArea.innerText = text;
  tempArea.select();
  document.execCommand("Copy");
  selectGroup?.removeChild(tempArea);
  toast({ content: successMessageContent, type: "success" });
};

export const checkUrlReg = (url, customEvent, notCorrectEvent = () => {}) => {
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const relativeExp = /\/[-a-zA-Z0-9@:%._\/+~#=]*/;
  const regex = new RegExp(expression);
  const relativeRegex = new RegExp(relativeExp);
  if (url.match(regex) || url.match(relativeRegex)) {
    if (!!customEvent) {
      customEvent();
    } else {
      window.location.href = url;
    }
  } else {
    notCorrectEvent();
    console.log("not right url");
    return;
  }
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getLang = () => {
  // change as ISO 639-1 two-letter language code
  const localLang = window.localStorage.getItem(localStorageKey.LANG_SETTING);
  switch (localLang) {
    case "jp":
      return "ja";
    default:
      return localLang;
  }
};
