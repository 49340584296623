import { Drawer } from "antd";
import { SpinLoading } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import localStorageKey from "../../enumerations/localStorageKey.js";
import { AppBar, CheckBoxSelector, toast } from "../index.js";
import { useGetAllLangList, useGetLangListByCurrency } from "./hooks/langSelector.hook.js";
import langDict from "./langDict.json";

import { DEFAULT_LANG } from "../../config.js";
import { PopoverMenu } from "../index.js";

import styles from "./styleModule";

function LangSelector({
  visible,
  setVisible = () => {},
  type = "drawer",
  children,
  setShowPopover = () => {},
}) {
  const getLangListByCurrency = useGetLangListByCurrency({
    enabled:
      (window.location.pathname == "/" || window.location.pathname == "/personal") &&
      !!window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY) &&
      visible === true,
  });
  const getAllLangList = useGetAllLangList({
    enabled:
      (window.location.pathname == "/signin" || window.location.pathname == "/signup") &&
      visible === true,
  });
  const [langList, setLangList] = useState([]);
  const [nowLang, setNowLang] = useState("");
  const [isLoadingLangs, setIsLoadingLangs] = useState(false);
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language;
  const onClose = () => {
    setVisible(false);
  };
  const formatLang = (data) => {
    if (!data || data?.length == 0) return;
    const formattedData = [];
    data.forEach((item) => {
      const found = langDict.find((i) => i.value == item.language);
      formattedData.push({
        ...item,
        label: item.name,
        value: item.language,
        shortName: found ? found.shortName : item.language,
        key: item.language,
        icon: (
          <div className={`${styles.imgContainer}`}>
            <img src={`/images/langIcons/${item.language}.png`} alt={item.language} />
          </div>
        ),
        text: item.name,
      });
    });
    setLangList(formattedData);
  };
  const getNowLang = () => {
    const langSet = window.localStorage.getItem(localStorageKey.LANG_SETTING);
    const foundLang = langList.find((i) => i.value === langSet);
    const foundDefaultLang = langList.find((i) => i.value === DEFAULT_LANG);
    if (foundLang && langSet) {
      i18n.changeLanguage(langSet);
      setNowLang(langSet);
    } else if (foundDefaultLang) {
      setNowLang(DEFAULT_LANG);
      window.localStorage.setItem(localStorageKey.LANG_SETTING, DEFAULT_LANG);
    } else {
      i18n.changeLanguage(langList[0].value);
      setNowLang(langList[0].value);
      window.localStorage.setItem(localStorageKey.LANG_SETTING, langList[0].value);
    }
  };
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setNowLang(lang);
    window.localStorage.setItem(localStorageKey.LANG_SETTING, lang);
    toast({ content: t("lang.select.complete"), type: "success" });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    if (langList.length > 0) getNowLang();
  }, [langList]);
  useEffect(() => {
    formatLang(getLangListByCurrency.data);
  }, [getLangListByCurrency.data]);
  useEffect(() => {
    formatLang(getAllLangList.data);
  }, [getAllLangList.data]);

  useEffect(() => {
    setIsLoadingLangs(getLangListByCurrency.isFetching || getAllLangList.isFetching);
  }, [getLangListByCurrency.isFetching, getAllLangList.isFetching]);

  return (
    <>
      {type === "drawer" ? (
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ padding: "0" }}
          width={"100%"}
          style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
        >
          <AppBar
            title={t("normal.language")}
            onClickEvent={() => {
              setVisible(false);
            }}
          />
          <CheckBoxSelector
            options={langList}
            selectedOption={nowLang}
            onChange={(target) => {
              changeLang(target.value);
            }}
          />
        </Drawer>
      ) : (
        ""
      )}
      {type === "popover" ? (
        <PopoverMenu
          mode="dark"
          actions={
            isLoadingLangs
              ? [
                  {
                    key: "loading",
                    icon: <SpinLoading style={{ "--size": "24px" }} />,
                    text: "Loading...",
                  },
                ]
              : langList
          }
          placement="bottom-end"
          onAction={(node) => {
            if (isLoadingLangs) return;
            if (node.key === nowLang) return;
            changeLang(node.key);
          }}
          trigger="click"
          setShowPopover={setShowPopover}
        >
          {children}
        </PopoverMenu>
      ) : (
        ""
      )}
    </>
  );
}

export default LangSelector;
