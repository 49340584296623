import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import {
  apiCheckShowBubble,
  apiGetPrizeList,
  apiGetSpinRecord,
  apiSpinTheWheel,
  apiSpinTheWheelNew,
} from "./service";

export const useGetPrizeList = ({ id, options }) => {
  return useQuery(
    [queryKey.PRIZE_LIST, id],
    () =>
      apiGetPrizeList(id, {}).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
    },
  );
};

export const useSpinTheWheel = () => {
  const cache = useQueryClient();
  return useMutation(
    ({ params, query }) => apiSpinTheWheel(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {
        // cache.invalidateQueries([queryKey.SPIN_RECORD]);
      },
    },
  );
};

export const useSpinTheWheelNew = () => {
  const cache = useQueryClient();
  return useMutation(
    ({ params, query }) => apiSpinTheWheelNew(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {
        // cache.invalidateQueries([queryKey.SPIN_RECORD]);
      },
    },
  );
};

export const useGetSpinRecord = ({ params, options }) => {
  return useQuery(
    [queryKey.SPIN_RECORD, params?.promotion_id],
    () =>
      apiGetSpinRecord({ params: params }).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
    },
  );
};

export const useCheckShowBubble = (options) => {
  return useQuery(
    [queryKey.SHOW_BUBBLE],
    () =>
      apiCheckShowBubble({}).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
    },
  );
};
