import { Form, Input } from "antd";
import "./style.scss";

const BasicInput = ({
  name,
  label,
  rules,
  placeholder,
  addonAfter,
  onClickEvent,
  onChange,
  value,
  disabled,
  readOnly,
  shouldUpdate,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      className="Meta-Input"
      shouldUpdate={shouldUpdate}
    >
      <Input
        placeholder={placeholder}
        addonAfter={addonAfter}
        onClick={onClickEvent}
        onChange={onChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
      />
    </Form.Item>
  );
};

export default BasicInput;
