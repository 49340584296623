import React from "react";

const PromoIcon = ({ color1 = "#F4BFAE", color2 = "#EFA78C" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.625 12V18C20.625 20.21 18.835 22 16.625 22H8.625C6.415 22 4.625 20.21 4.625 18V12C4.625 11.45 5.075 11 5.625 11H7.595C8.145 11 8.595 11.45 8.595 12V15.14C8.59604 15.671 8.80677 16.1802 9.18134 16.5566C9.5559 16.933 10.064 17.1463 10.595 17.15C10.975 17.15 11.355 17.04 11.685 16.82L12.635 16.2L13.515 16.79C14.125 17.2 14.905 17.25 15.555 16.9C16.215 16.55 16.625 15.88 16.625 15.13V12C16.625 11.45 17.075 11 17.625 11H19.625C20.175 11 20.625 11.45 20.625 12ZM22.125 7V8C22.125 9.1 21.595 10 20.125 10H5.125C3.595 10 3.125 9.1 3.125 8V7C3.125 5.9 3.595 5 5.125 5H20.125C21.595 5 22.125 5.9 22.125 7Z"
        fill="url(#paint0_linear_4247_28317)"
      />
      <path
        d="M12.2639 5.00128H6.74394C6.57952 4.82294 6.49075 4.58773 6.49634 4.34522C6.50194 4.10271 6.60147 3.87185 6.77394 3.70128L8.19394 2.28128C8.37366 2.10357 8.6162 2.00391 8.86895 2.00391C9.12169 2.00391 9.36423 2.10357 9.54394 2.28128L12.2639 5.00128ZM18.4969 5.00128H12.9769L15.6969 2.28128C15.8767 2.10357 16.1192 2.00391 16.3719 2.00391C16.6247 2.00391 16.8672 2.10357 17.0469 2.28128L18.4669 3.70128C18.8269 4.06128 18.8369 4.63128 18.4969 5.00128ZM14.5959 11.0003C15.1459 11.0003 15.5959 11.4503 15.5959 12.0003V15.1303C15.5959 15.9303 14.7059 16.4103 14.0459 15.9603L13.1459 15.3603C12.9827 15.2528 12.7914 15.1954 12.5959 15.1954C12.4004 15.1954 12.2092 15.2528 12.0459 15.3603L11.1059 15.9803C10.4459 16.4203 9.56594 15.9403 9.56594 15.1503V12.0003C9.56594 11.4503 10.0159 11.0003 10.5659 11.0003H14.5959Z"
        fill="url(#paint1_linear_4247_28317)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4247_28317"
          x1="12.625"
          y1="2.00391"
          x2="12.625"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} />
          <stop offset="1" stop-color={color2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4247_28317"
          x1="12.625"
          y1="2.00391"
          x2="12.625"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} />
          <stop offset="1" stop-color={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PromoIcon;
