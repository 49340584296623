//跳轉、錯誤處理、提示等方法放在這裡
// import {Message} from 'element-ui';
// import router from '../router/index';
import { toast } from "../components";

const ALLOWED_PATH = ["/signin", "/signup", "/forgotPassword", "/"];

export const isAllowedWithoutCurrency = (path) => {
  return ALLOWED_PATH.includes(path);
};

export const tip = (msg, code) => {
  toast({
    content: (
      <>
        {msg} <br /> {code}
      </>
    ),
    type: "error",
  });
};

export const toLogin = (nuxt) => {
  window.location.href = "/signin";
  // this.$router.push('/');
  // $router.replace({
  //     name: 'Login',
  //     query: {
  //         redirect: router.currentRoute.fullPath
  //     }
  // });
  // debugger;
  // nuxt.$router.push('/login');
  // window.location.pathname = "/error";
  // window.history.pushState("123","title","/Login");
};

export const to403Page = (nuxt) => {
  window.location.pathname = "/accessDenied";
};

export const toMaintenancePage = () => {
  window.location.pathname = "/inMaintenance";
};

export const toInternetErrorPage = () => {
  window.location.pathname = "/connectionFailed";
};
