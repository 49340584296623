import React from "react";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import playerPic from "../../icons/bingo-1-player-mask.png";
import { CDN_URL, PRODUCTION_NAME_DEV, DEFAULT_LANG } from "../../../../config";
import localStorageKey from "../../../../enumerations/localStorageKey";
const mockData = [
  // {
  //   name: "starbucks",
  //   icon: "https://www.holibear.com/hblog/wp-content/uploads/2020/07/342-3.jpg",
  //   link: "https://www.starbucks.com.tw",
  // },
  // {
  //   name: "google",
  //   icon: "https://media.wired.com/photos/5926ffe47034dc5f91bed4e8/191:100/w_1280,c_limit/google-logo.jpg",
  //   link: "https://www.google.com",
  // },
  // {
  //   name: "facebook",
  //   icon: "https://www.itri.org.tw/images/f_logo.png",
  //   link: "https://www.facebook.com.tw",
  // },
  // {
  //   name: "zeplin",
  //   icon: "https://cdn.sanity.io/images/wd3e2pma/production/716ae55a149ff63038853cb19d0c17676d406676-1200x600.jpg?rect=29,0,1143,600&w=1200&h=630",
  //   link: "https://zeplin.io/",
  // },
  // {
  //   name: "starbucks",
  //   icon: "https://www.holibear.com/hblog/wp-content/uploads/2020/07/342-3.jpg",
  //   link: "https://www.starbucks.com.tw",
  // },
  // {
  //   name: "google",
  //   icon: "https://media.wired.com/photos/5926ffe47034dc5f91bed4e8/191:100/w_1280,c_limit/google-logo.jpg",
  //   link: "https://www.google.com",
  // },
  // {
  //   name: "facebook",
  //   icon: "https://www.itri.org.tw/images/f_logo.png",
  //   link: "https://www.facebook.com.tw",
  // },
  // {
  //   name: "zeplin",
  //   icon: "https://cdn.sanity.io/images/wd3e2pma/production/716ae55a149ff63038853cb19d0c17676d406676-1200x600.jpg?rect=29,0,1143,600&w=1200&h=630",
  //   link: "https://zeplin.io/",
  // },
];
function PlayRecords({
  record = mockData,
  checkIfLogin,
  isLogin,
  onGameClick,
}) {
  const { t } = useTranslation();
  const LANG =
    localStorage.getItem(localStorageKey.LANG_SETTING) || DEFAULT_LANG;
  return (
    <div className={`${styles.playRecords}`}>
      <div className={`${styles.titleBox}`}>
        <div className={`${styles.border}`}></div>
        <span className={`${styles.title}`}>{t("home.recentlyPlay")}</span>
      </div>
      {record && record.length > 0 && isLogin ? (
        <div className={`${styles.hasRecord}`}>
          {record.map((item, index) => {
            const game_category = item?.data?.text[0]?.toLowerCase();
            const game_name = item?.data?.text[1]?.toLowerCase();
            const game_code = item?.code;
            return (
              <Button
                type="text"
                className={`${styles.gameItem}`}
                key={item.name + index}
                onClick={() => {
                  if (!checkIfLogin()) return;
                  onGameClick(item)
                  // window.location = item.link;
                }}
              >
                <img
                  src={`${CDN_URL}/imgs/${PRODUCTION_NAME_DEV}/${LANG}/${game_category}/${game_code}/home_right.png`}
                  alt={item.name}
                />
              </Button>
            );
          })}
        </div>
      ) : (
        <div className={`${styles.noRecord}`}>
          <span className={`${styles.slogan}`}>
            {t("home.recentlyPlay.slogan")}
          </span>
          <div className={`${styles.pic}`}>
            <img src={playerPic} alt="player" />
          </div>
        </div>
      )}
    </div>
  );
}

export default PlayRecords;
