import { Grid } from "antd-mobile";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useRecentPlayGames } from "../../context/RecentPlayGamesContext";
import { useGetGameV2 } from "../../hooks/home1bbet.hooks";
import { GameItem } from "../GameListContainer/components";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
const JpyHotList = () => {
  const { recentPlayList } = useRecentPlayGames();
  const { data: allGames } = useGetGameV2({
    enabled: !!CURRENCY,
  });
  const renderHotList = () => {
    let gameList = recentPlayList;
    if (allGames && allGames.hot_list) {
      gameList = [...gameList, ...allGames.hot_list];
    }
    return (
      <Grid columns={4} gap={8} className={`${styles.hot_list}`}>
        {gameList.map((hot) => {
          return (
            <Grid.Item>
              <GameItem key={hot.game_code} game={hot} gameNameStyle={styles.gameNameStyle} />
            </Grid.Item>
          );
        })}
      </Grid>
    );
    // }
  };

  return <div className={styles.container}>{renderHotList()}</div>;
};

export default JpyHotList;
