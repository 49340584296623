import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../context/LoginContext";
import { useDefaultLayout } from "../../defaultLayout";
import { DepositIcon, HomeIcon, ProfileIcon, PromoIcon } from "./images/1bbetv2";
import styles from "./styleModule";
const Footer = () => {
  const [currentMod, setCurrentMod] = useState("home");
  const { checkLoginProcess } = useDefaultLayout();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { checkIfLogin } = useLogin();

  const items = [
    {
      id: "lobby",
      name: t("1bbet.footer.lobby"),
      icon: (
        <HomeIcon color1={"var(--footer-icon-inactive1)"} color2={"var(--footer-icon-inactive2)"} />
      ),
      icon_active: (
        <HomeIcon color1={"var(--footer-icon-active1)"} color2={"var(--footer-icon-active2)"} />
      ),
      path: "/",
      style: "",
      textStyle: "",
    },
    {
      id: "promo",
      name: t("1bbet.footer.promo"),
      icon: (
        <PromoIcon
          color1={"var(--footer-icon-inactive1)"}
          color2={"var(--footer-icon-inactive2)"}
        />
      ),
      icon_active: (
        <PromoIcon color1={"var(--footer-icon-active1)"} color2={"var(--footer-icon-active2)"} />
      ),
      path: "/bonus",
      style: "",
      textStyle: "",
    },
    {
      id: "deposit",
      name: t("1bbet.footer.deposit"),
      icon: (
        <DepositIcon
          color1={"var(--footer-icon-inactive1)"}
          color2={"var(--footer-icon-inactive2)"}
        />
      ),
      icon_active: (
        <DepositIcon color1={"var(--footer-icon-active1)"} color2={"var(--footer-icon-active2)"} />
      ),
      path: "/exchange",
      style: "",
      textStyle: "",
    },
    {
      id: "profile",
      name: t("1bbet.footer.profile"),
      icon: (
        <ProfileIcon
          color1={"var(--footer-icon-inactive1)"}
          color2={"var(--footer-icon-inactive2)"}
        />
      ),
      icon_active: (
        <ProfileIcon color1={"var(--footer-icon-active1)"} color2={"var(--footer-icon-active2)"} />
      ),
      path: "/personal",
      style: "",
      textStyle: "",
    },
  ];

  const updateCurrentMod = () => {
    const target = items.find((m) => m.path === window.location.pathname);
    setCurrentMod(target?.id);
  };

  useEffect(() => {
    updateCurrentMod();
  }, [window.location.pathname]);

  const onHandleFooterClick = (item) => {
    if (!checkIfLogin()) return;

    if (!checkLoginProcess?.process()) {
      return;
    }

    navigate(item.path);
  };

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        {items.map((item) => {
          return (
            <div
              className={styles.footerItem}
              onClick={() => onHandleFooterClick(item)}
              key={item.id}
            >
              <div className={`${styles.iconContainer} ${item.style}`}>
                {item.id === currentMod ? item.icon_active : item.icon}
              </div>
              <div
                className={`${styles.text} ${item.id === currentMod ? styles.textActive : ""} ${
                  item.textStyle
                }`}
              >
                {item.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
