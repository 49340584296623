import { useQueryClient } from "@tanstack/react-query";
import { Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  ButtonGroup,
  DateButtonGroup,
  FilterConditionGroup,
  NoData,
  SearchFilter,
} from "../../components";
import { PRODUCTION_NAME_DEV } from "../../config";
import localStorageKey from "../../enumerations/localStorageKey";
import { useLazyLoad } from "../../hooks/lazyLoad/lazyLoad.hooks";
import DetailItem from "./components/DetailItem";
import { ColumnsContextProvider } from "./components/DetailItem/Columns";
import { useGetBetRecord, useGetGameList, useGetGameTypes } from "./hooks/betRecord.hook";
import styles from "./styleModule";
const findItemCode = (id, list) => {
  if (!id || !list) return;
  const found = list?.find((item) => {
    return item.value == id;
  });
  return found?.code;
};

const findChildCode = (code, list) => {
  if (!code || !list) return;
  const found = list?.find((item) => {
    return item.value == code;
  });
  return found?.code;
};

const findParentGameCode = (code, list) => {
  if (!code || !list) return;
  const found = list?.find((item) => {
    return item.value == code;
  });
  return found?.parentCode;
};

//!!FIXME: need lazyload and refactoring!
function BetRecord({ showHeader = true, searchType = undefined, searchGame = undefined }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const observer = useRef(null);
  const [gameTypeList, setGameTypeList] = useState();
  const [gameList, setGameList] = useState();
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [nowCondition, setNowCondition] = useState("");
  const [oldChosenVal, setOldChosenVal] = useState({
    dates: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
    date: "today",
  });
  const [date, setDate] = useState([dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")]);

  const [chosenDate, setChosenDate] = useState("today");
  const [chosenType, setChosenType] = useState();
  const [chosenGame, setChosenGame] = useState();
  const [nowGame, setNowGame] = useState();
  const [nowTypeText, setNowTypeText] = useState();
  const [nowGameText, setNowGameText] = useState();

  const cache = useQueryClient();
  // const [params] = useSearchParams();

  // const { data: userData } = useGetUserInfo({});
  const userData = JSON.parse(window.localStorage.getItem(localStorageKey.USER_INFO));

  const { data: gameTypes } = useGetGameTypes({
    enabled: searchType === undefined,
  });
  const { data: gameListData } = useGetGameList(
    { enabled: !!chosenType && searchGame === undefined },
    chosenType,
  );
  const {
    data: betRecord,
    refetch: refetchBetRecord,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isError,
  } = useGetBetRecord(
    {
      //FIXME:搜尋的流程需要再調整
      enabled: !!userData && !!chosenType && !!chosenGame && showSearchFilter === false,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < lastPage.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      // enabled: !!getUser.data && !!chosenGame,
      // refetch: false,
      onSuccess: (data) => {
        const typeCode = findItemCode(chosenType, gameTypeList);
        setNowGameText(
          t([
            `betRecord.games.${PRODUCTION_NAME_DEV}.${chosenGame}`,
            `betRecord.games.${chosenGame}`,
          ]),
        );
        setNowTypeText(t(`betRecord.category.${typeCode}`));
        setNowGame(findParentGameCode(chosenGame, gameList));
      },
      onError: (err) => {
        setNowGameText("");
        setNowTypeText("");
        setNowGame(undefined);
      },
    },
    {
      account: userData?.userInfo?.user?.account,
      game: findParentGameCode(chosenGame, gameList),
      // game_code: findChildCode(chosenGame, gameList),
      game_code: chosenGame,
      start_date: date[0],
      end_date: date[1],
      page_size: 10,
    },
  );
  const lazyLoad = useLazyLoad({
    triggerRef: observer,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    isFetching: isFetchingNextPage,
  });

  const dateOptions = [
    { label: t("search.today"), value: "today" },
    { label: t("search.yesterday"), value: "yesterday" },
    { label: t("search.seven.days"), value: "sevenDays" },
    { label: t("search.fifteen.days"), value: "fifteenDays" },
    { label: t("search.thirty.days"), value: "thirtyDays" },
  ];
  const searchCondition = [
    {
      name: t("searchFilter.date"),
      id: "date",
      component: () => {
        return (
          <DateButtonGroup
            dateOptions={dateOptions}
            chosenDate={chosenDate}
            setChosenDate={setChosenDate}
            setDate={setDate}
          />
        );
      },
    },
    {
      name: t("searchFilter.type"),
      id: "type",
      component: () => {
        return (
          <ButtonGroup
            options={gameTypeList}
            chosenVal={chosenType}
            setChosenVal={setChosenType}
            amountInOneRow={3}
          />
        );
      },
    },
    {
      name: t("searchFilter.game"),
      id: "game",
      component: () => {
        return (
          <ButtonGroup
            options={gameList}
            chosenVal={chosenGame}
            setChosenVal={setChosenGame}
            amountInOneRow={2}
          />
        );
      },
    },
  ];
  const nowValueDict = {
    date: { options: dateOptions, nowValue: chosenDate },
    type: { options: gameTypeList, nowValue: chosenType },
    game: { options: gameList, nowValue: chosenGame },
  };
  const formatDataList = (data, type) => {
    if (!data || data.length == 0) return [];
    const formattedData = [];
    data.forEach((item) => {
      if (type == "type")
        formattedData.push({
          ...item,
          label: t(`betRecord.category.${item.code}`),
          value: item.id,
        });
      if (type == "game") {
        item.game_sub?.forEach((childItem) => {
          formattedData.push({
            ...childItem,
            label: t([
              `betRecord.games.${PRODUCTION_NAME_DEV}.${childItem.code ?? item.code}`,
              `betRecord.games.${childItem.code ?? item.code}`,
            ]),
            value: childItem.code ?? item.code,
            parentCode: item.code,
          });
        });
      }
    });
    return formattedData;
  };

  const handleClosePage = () => {
    // if (!params.get("code")) {
    navigate(-1, {
      state: {
        isPopup: state?.isPopup,
      },
    });
    // } else {
    //   navigate(`/${params.get("code")}?tab=${params.get("tab")}`, {
    //     replace: true,
    //     state: {
    //       isPopup: state?.isPopup,
    //     },
    //   });
    // }
  };

  const onReset = (id) => {
    switch (id) {
      case "game":
        setChosenGame(oldChosenVal.game);
        break;
      case "date":
        setDate(oldChosenVal.dates);
        setChosenDate(oldChosenVal.date);
        break;
      case "type":
        setChosenType(oldChosenVal.type);
        break;
      default:
        setChosenDate(oldChosenVal.date);
        setChosenType(oldChosenVal.type);
        setChosenGame(oldChosenVal.game);
        setDate(oldChosenVal.dates);
        break;
    }
  };
  const onFilterClose = (item) => {
    if (!item.id || item.id === nowCondition) {
      setShowSearchFilter(false);
      setNowCondition("");
    } else {
      setNowCondition(item.id);
    }
  };

  useEffect(() => {
    if (searchType) {
      const typeList = formatDataList(searchType, "type");
      setGameTypeList(typeList);
      setChosenType(typeList[0]?.value);
      setOldChosenVal((prevState) => {
        return { ...prevState, type: typeList[0]?.value };
      });
      return;
    }
    // console.log("data", getGameTypes.data);
    if (gameTypes) {
      const typeList = formatDataList(gameTypes, "type");
      setGameTypeList(typeList);
      // const searchType = params.get("type");
      // const typeDetail = typeList.find((item) => item.code === searchType);
      // if (!!typeDetail) {
      //   setChosenType(typeDetail.id);
      //   setOldChosenVal((prevState) => {
      //     return { ...prevState, type: typeDetail.id };
      //   });
      // } else {
      setChosenType(typeList[0]?.value);
      setOldChosenVal((prevState) => {
        return { ...prevState, type: typeList[0]?.value };
      });
      // }
    }
  }, [gameTypes, searchType]);

  useEffect(() => {
    if (searchGame) {
      const _gameList = formatDataList(searchGame, "game");
      setGameList(_gameList);
      setChosenGame(_gameList[0]?.value);
      setOldChosenVal((prevState) => {
        return { ...prevState, game: _gameList[0]?.value };
      });
      return;
    }
    if (gameListData) {
      const _gameList = formatDataList(gameListData, "game");

      // const searchType = params.get("type");
      // const typeDetail = gameTypeList.find((item) => item.code === searchType);

      setGameList(_gameList);
      // if (!!searchGame && chosenType === typeDetail.id) {
      //   // const searchGame = params.get("game");
      //   setChosenGame(searchGame);
      //   setOldChosenVal((prevState) => {
      //     return { ...prevState, game: searchGame };
      //   });
      // } else {
      setChosenGame(_gameList[0]?.value);
      setOldChosenVal((prevState) => {
        return { ...prevState, game: _gameList[0]?.value };
      });
      // }
    }
  }, [gameListData, /*chosenType, gameTypeList,*/ searchGame]);

  // const handleRefetch = async () => {
  //   await refetchBetRecord();
  // };

  // useEffect(() => {
  //   // console.debug(!!userData, !!chosenType, !!chosenGame, showSearchFilter);
  //   if (
  //     !!userData &&
  //     !!chosenType &&
  //     !!chosenGame &&
  //     showSearchFilter === false
  //     // &&
  //     // isLoading === false
  //   ) {
  //     console.debug(!!userData, !!chosenType, !!chosenGame, showSearchFilter);
  //     console.debug("refetching", showSearchFilter);
  //     handleRefetch();
  //   }
  // }, [showSearchFilter, !!chosenGame, !!chosenType, !!userData]);
  // useEffect(() => {
  //   cache.clear([queryKey.BET_RECORD]);
  // }, []);

  return (
    <ColumnsContextProvider>
      <div className={`${styles.betRecord}`}>
        {showHeader ? (
          <AppBar
            title={t("page.betRecord")}
            showBackButton={true}
            onClickEvent={handleClosePage}
          />
        ) : null}
        <div className={styles.betRecordContainer}>
          <div style={{ padding: "0 16px" }}>
            <FilterConditionGroup
              searchCondition={searchCondition}
              nowCondition={nowCondition}
              setNowCondition={setNowCondition}
              itemOnClick={(item) => {
                if (item.id == "game" && !chosenType) return;
                setShowSearchFilter(true);
              }}
              textRender={(item) => {
                const found = nowValueDict[item.id].options?.find((option) => {
                  return option.value == nowValueDict[item.id].nowValue;
                });
                return found?.label;
              }}
            />
          </div>
          <div>
            {isError ? (
              <NoData title={t("normal.noData")} padding="0" height="calc(100vh - 101px)" />
            ) : betRecord?.pages[0]?.data.length > 0 ? (
              <>
                {betRecord?.pages?.map((perPageData, pageIndex) => {
                  return perPageData.data.map((item, index) => {
                    return (
                      <DetailItem
                        data={item}
                        key={"data" + index}
                        nowGameText={nowGameText}
                        game={nowGame}
                      />
                    );
                  });
                })}
                <div
                  ref={observer}
                  className={styles.loadingContainer}
                  style={{ display: hasNextPage ? "flex" : "none" }}
                >
                  <Spin />
                </div>
              </>
            ) : (
              <NoData title={t("normal.noData")} padding="0" height="calc(100vh - 101px)" />
            )}
          </div>
        </div>
        <SearchFilter
          title={t("page.betRecord")}
          visible={showSearchFilter}
          setVisible={setShowSearchFilter}
          nowCondition={nowCondition}
          setNowCondition={setNowCondition}
          searchCondition={searchCondition}
          okBtnText={t("normal.confirm")}
          resetBtnText={t("normal.reset")}
          onReset={(id) => onReset(id)}
          onOk={() => {
            setShowSearchFilter(false);
            setNowCondition("");
            // const params = {
            //   account: getUser?.data?.account,
            //   game: findParentGameCode(chosenGame, gameList),
            //   game_code: chosenGame,
            //   start_date: date[0],
            //   end_date: date[1],
            // };
            // console.log("submit data", params);
            // getBetRecord.refetch();
          }}
          onFilterClose={onFilterClose}
        />
      </div>
    </ColumnsContextProvider>
  );
}

export default BetRecord;
