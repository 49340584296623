import { Button, Drawer, Form } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import styles from "./style.module.scss";
import "./style.scss";

function DrawerForm(props) {
  const { visible, setVisible } = props;
  const [form] = Form.useForm();
  const onClose = () => {
    setVisible(false);
  };
  const confirmBtnStyle = {
    marginBottom: "2px",
    borderRadius: "4px",
    minHeight: "52px",
    fontSize: "var(--fontSetting-bigger)",
    fontWeight: "600",
    textShadow: "none",
  };
  const cancelBtnStyle = {
    borderRadius: "4px",
    minHeight: "52px",
    fontSize: "var(--fontSetting-bigger)",
    fontWeight: "600",
    textShadow: "none",
  };
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Drawer
      placement={"bottom"}
      closable={false}
      onClose={onClose}
      visible={visible}
      className={`${styles.drawerForm} drawer-form`}
      contentWrapperStyle={{ boxShadow: "none", height: "auto" }}
      style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
    >
      <Form
        {...props.formProps}
        onFinish={(values) => {
          props.onFinish(values);
        }}
        autoComplete="off"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        form={form}
        requiredMark={false}
      >
        <div className={`${styles.formContent}`}>{props.children}</div>
        <Button type="primary" htmlType="submit" block style={confirmBtnStyle}>
          {t("normal.confirm")}
        </Button>
        <Button block style={cancelBtnStyle} onClick={onClose}>
          {t("normal.cancel")}
        </Button>
      </Form>
    </Drawer>
  );
}

export default DrawerForm;
