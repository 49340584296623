const MessagesIcon = ({ color1 = "#BFDBFC", color2 = "#4785F7" }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6667 12.359C22.6667 17.519 18.1867 21.6923 12.6667 21.6923L11.4267 23.1857L10.6934 24.0657C10.0667 24.8123 8.86669 24.6523 8.45335 23.759L6.66669 19.8257C4.24002 18.119 2.66669 15.4123 2.66669 12.359C2.66669 7.19898 7.14669 3.02565 12.6667 3.02565C16.6934 3.02565 20.1734 5.25232 21.7334 8.45232C22.3334 9.63898 22.6667 10.959 22.6667 12.359Z"
        fill={color1}
      />
      <path
        d="M29.3333 17.5059C29.3333 20.5592 27.76 23.2659 25.3333 24.9726L23.5466 28.9059C23.1333 29.7992 21.9333 29.9726 21.3066 29.2126L19.3333 26.8392C16.1066 26.8392 13.2266 25.4126 11.4266 23.1859L12.6666 21.6926C18.1866 21.6926 22.6666 17.5192 22.6666 12.3592C22.6666 10.9592 22.3333 9.63923 21.7333 8.45256C26.0933 9.45256 29.3333 13.1326 29.3333 17.5059ZM16 13.3592H9.33331C8.78665 13.3592 8.33331 12.9059 8.33331 12.3592C8.33331 11.8126 8.78665 11.3592 9.33331 11.3592H16C16.5466 11.3592 17 11.8126 17 12.3592C17 12.9059 16.5466 13.3592 16 13.3592Z"
        fill={color2}
      />
    </svg>
  );
};

export default MessagesIcon;
