import { CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "../../../../config";
// import AppIcon from "../../images/app_icon.png";
import styles from "./style.module.scss";

const AppDownloadBar = ({ downloadVisible = true, setDownloadVisible = () => {}, desc = null }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClose = () => {
    setDownloadVisible(false);
  };
  return (
    <div className={`${styles.container} ${downloadVisible === false ? styles.hide : ""}`}>
      <div className={styles.leftContainer}>
        <div className={styles.closeContainer} onClick={onClose}>
          <CloseCircleOutlined
            style={{
              fontSize: "var(--fontSetting-large)",
              color: "var(--color-appDownloadBar-close)",
            }}
          />
        </div>
        <img
          src={`/images/logo/${PRODUCTION_NAME_DEV}/app_icon.png`}
          alt={""}
          className={styles.downloadImage}
        />
        <div className={styles.titleContainer}>
          <div className={`${styles.title}`}>{PRODUCTION_NAME_DISPLAY}</div>
          {desc ? <div className={styles.desc}>{desc}</div> : null}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div
          className={`${styles.downloadContainer}`}
          onClick={() => {
            navigate("/appDownload");
          }}
        >
          <div className={styles.downloadTitle}>{t("win.normal.download")}</div>
        </div>
      </div>
    </div>
  );
};

export default AppDownloadBar;
