import { DownOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import "./style";
import styles from "./styleModule";

function CustomCollapse({ collapseItems }) {
  const { Panel } = Collapse;
  return (
    <div className="custom-collapse">
      <Collapse
        ghost
        expandIconPosition="end"
        expandIcon={({ isActive }) => {
          return (
            <DownOutlined
              rotate={isActive ? 180 : 0}
              className={`${styles.arrow} ${isActive && styles.active}`}
            />
          );
        }}
      >
        {collapseItems?.map((item, index) => {
          return (
            <Panel header={item.title} key={item.id}>
              {item.img ? (
                <div className="custom-collapse-img-container">
                  <img src={item.img} alt={item.title} />
                </div>
              ) : (
                ""
              )}
              <p className={styles.content} dangerouslySetInnerHTML={{ __html: item.content }} />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

export default CustomCollapse;
