import { LeftOutlined } from "@ant-design/icons";
import { Grid, Popup } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import renderMoreGameSetting from "../../enumeration/renderMoreGameSetting";
import {
  useGetPgGameList,
  useGetPpGameList,
  useGetT1GameList,
  useGetWinGameList,
  useGetYggGameList,
} from "../../hooks/home1bbet.hooks";
import BalanceHeader from "../BalanceHeader";
import styles from "./style.module.scss";

function MoreGamesPopup({
  open,
  setOpen,
  gameCode = "",
  onGameItemClick,
  setDrawerTitle,
  maintenance,
}) {
  const [gameList, setGameList] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  //FIXME: need other games api
  const { data, refetch: refetchGameWinList } = useGetWinGameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });
  const { data: t1Games, refetch: refetchT1List } = useGetT1GameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });

  const { data: ppGames, refetch: refetchPpList } = useGetPpGameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });
  const { data: pgGames, refetch: refetchPgList } = useGetPgGameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });

  const { data: yggGames, refetch: refetchYggList } = useGetYggGameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });

  const { data: evoplayGames, refetch: refetchEvoPlayList } = useGetYggGameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });

  const { data: jiliGames, refetch: refetchJiliList } = useGetYggGameList({
    enabled: false,
    onSuccess: (data) => setGameList(data),
    onError: () => setGameList([]),
  });

  const getGameList = () => {
    if (gameCode === "winlottery") {
      refetchGameWinList();
    } else if (gameCode === "t1") {
      refetchT1List();
    } else if (gameCode === "pp") {
      refetchPpList();
    } else if (gameCode === "pg") {
      refetchPgList();
    } else if (gameCode === "ygg") {
      refetchYggList();
    } else if (gameCode === "evoplay") {
      refetchEvoPlayList();
    } else if (gameCode === "jili") {
      refetchJiliList();
    } else {
      return;
    }
  };
  const titleRender = (gameDetail) => {
    if (gameCode === "t1") {
      return gameDetail.code.charAt(0).toUpperCase() + gameDetail.code.slice(1);
    } else if (gameCode === "pg") {
      return gameDetail.game_name;
    } else {
      return gameDetail.name;
    }
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (!open) {
      setGameList([]);
    } else {
      getGameList();
    }
  }, [open]);

  return (
    <Popup
      visible={open}
      onMaskClick={() => {
        setOpen(false);
      }}
      bodyStyle={{ width: "100vw" }}
      destroyOnClose={true}
      position={"right"}
      mask={false}
    >
      <div className={`${styles.container}`}>
        <BalanceHeader isLogin={true} />
        <div className={`${styles.titleBar}`}>
          <LeftOutlined onClick={onClose} size={16} />
          <div className={`${styles.gameName}`}>{t(`home.games.${gameCode}`)}</div>
        </div>
        <div className={`${styles.gameWrapper}`}>
          <Grid
            columns={renderMoreGameSetting[gameCode] ? renderMoreGameSetting[gameCode].column : 3}
            gap={[12, 16]}
            style={{ padding: "8px 12px" }}
          >
            {gameList?.map((item, index) => {
              return (
                //item - game_category_detail_type
                <Grid.Item key={index}>
                  {maintenance === 0 ? (
                    <div
                      className={`${styles.game}`}
                      onClick={() => {
                        const gameSetting = renderMoreGameSetting[gameCode];
                        if (!!gameSetting) {
                          if (gameSetting.clickEvent === "navigateToRoute") {
                            navigate(`/${item.code.toLowerCase()}`);
                          } else if (gameSetting.clickEvent === "openIFrameApiLink") {
                            setDrawerTitle(titleRender(item));
                            onGameItemClick({ code: gameCode }, item);
                          }
                          // else if (gameSetting.clickEvent === "openPopupWithApiLink") {
                          //   setDrawerTitle(item.name);
                          //   onGameItemClick(item.code, item.code);
                          // }
                        }
                      }}
                    >
                      <img
                        className={styles.gameImage}
                        src={
                          renderMoreGameSetting[gameCode].isImgFromApi
                            ? item[renderMoreGameSetting[gameCode].imgApiParamKey]
                            : renderMoreGameSetting[gameCode].imgPathPrefix +
                              item[renderMoreGameSetting[gameCode].imgPathKey] +
                              ".png"
                        }
                        alt={item?.name}
                      />
                    </div>
                  ) : (
                    <div className={`${styles.game}`}>
                      <img
                        className={styles.gameImage}
                        src={
                          renderMoreGameSetting[gameCode].isImgFromApi
                            ? item[renderMoreGameSetting[gameCode].imgApiParamKey]
                            : renderMoreGameSetting[gameCode].imgPathPrefix +
                              item[renderMoreGameSetting[gameCode].imgPathKey] +
                              ".png"
                        }
                        alt={item?.name}
                      />
                      <img
                        className={styles.maintiainImage}
                        src="images/icons/maintain.png"
                        alt="maintain"
                      />
                    </div>
                  )}
                </Grid.Item>
              );
            })}
          </Grid>
        </div>
      </div>
    </Popup>
  );
}

export default MoreGamesPopup;
