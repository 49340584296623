import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import WebsocketHeartbeatJs from "websocket-heartbeat-js";
import { GAME_SOCKET_URL, WS_PROTOCOL } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import webSocketActionEnum from "../enumerations/webSocketActionEnum";

const WebsocketGameContext = createContext({
  closeWebsocket: () => {},
  openWebsocket: () => {},
  subscribe: () => {},
  ping: () => {},
  setWebsocketChannel: () => {},
  isWebsocketConnected: false,
  currentPeriod: {},
  prevPeriod: {},
  setWebsocketToken: () => {},
  websocketToken: null,
  isWaitPeriod: false,
  switchChannel: () => {},
  counter: null,
  setCounter: () => {},
  currentChannel: null,
  refetchData: false,
  websocketTimeout: false,
});

export const WS_CHANNEL = {
  MARK_SIX_5: "LOTMS5",
  FAST3_3: "LOTFT3",
  EVERY_COLOR_5: "LOTEC5",
};

export const WebsocketGameContextProvider = ({ children }) => {
  const MAX_REPEAT = 3;
  const webSocket = useRef(null);
  const currentChannel = useRef(null);
  const repeatCount = useRef(0);
  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);
  const [websocketTimeout, setWebsocketTimeout] = useState(false);
  // const [websocketChannel, setWebsocketChannel] = useState("");
  const [prevPeriod, setPrevPeriod] = useState({});
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [websocketToken, setWebsocketToken] = useState();
  const [wsUrl, setwsUrl] = useState();
  const [isWaitPeriod, setIsWaitPeriod] = useState(false);
  const [counter, setCounter] = useState();
  const [refetchData, setRefetchData] = useState(false);

  const ping = () => {
    webSocket.current.send("ping");
  };

  const subscribe = ({ channel = null }) => {
    // console.log("CHANNEL", channel);
    // console.log("WS CHANNEL", currentChannel.current);
    // console.log("SUBS to", {
    //   method: "SUBSCRIBE",
    //   channel: channel ? channel : currentChannel.current,
    // });
    webSocket.current.send(
      JSON.stringify({
        method: "SUBSCRIBE",
        channel: channel ? channel : currentChannel.current,
      }),
    );
  };

  const unsubscribe = () => {
    // console.log("UNSUBS from", {
    //   method: "UNSUBSCRIBE",
    //   channel: currentChannel.current,
    // });
    webSocket.current.send(
      JSON.stringify({
        method: "UNSUBSCRIBE",
        channel: currentChannel.current,
      }),
    );
    currentChannel.current = null;
  };

  const switchChannel = ({ oldChannel, newChannel }) => {
    if (currentChannel.current === oldChannel) {
      setPrevPeriod({});
      // setCurrentPeriod({});
      unsubscribe();
      subscribe({ channel: newChannel });
      currentChannel.current = newChannel;
    }
  };

  const openWebsocket = ({ channel }) => {
    if (!wsUrl) return;

    if (!webSocket.current) {
      const opt = {
        url: wsUrl,
        pingMsg: "ping",
        repeatLimit: MAX_REPEAT,
      };
      try {
        currentChannel.current = channel;
        webSocket.current = new WebsocketHeartbeatJs(opt);
      } catch (error) {
        console.log("error creating webscoket", error);
      }
      setIsWebsocketConnected(true);
    }
  };

  const resetState = () => {
    setPrevPeriod({});
    setCurrentPeriod({});
    setIsWaitPeriod(false);
    setWebsocketTimeout(false);
  };

  const closeWebsocket = () => {
    if (webSocket.current) {
      try {
        unsubscribe();
        webSocket.current.close();
        webSocket.current = null;
        setIsWebsocketConnected(false);
        resetState();
        repeatCount.current = 0;
      } catch (error) {}
    }
  };

  useEffect(() => {
    // console.debug(
    //   "++++++++++++++++++++++++++PREV PERIOD    ",
    //   `${prevPeriod.period}:   `,
    //   "action:    ",
    //   `${prevPeriod.action}     `,
    //   "CURRENT PERIOD:   ",
    //   `${currentPeriod.period}   `,
    //   "action:    ",
    //   `${currentPeriod.action}     `,
    // );
  }, [prevPeriod, currentPeriod]);

  useEffect(() => {
    if (currentChannel.current) {
      if (
        currentChannel.current.includes("LOTWIN") ||
        currentChannel.current.includes("LOTBTC") ||
        currentChannel.current.includes("LOTETH")
      ) {
        if (counter <= 1) {
          if (refetchData === false) {
            setRefetchData(true);
          }
        } else {
          if (refetchData === true) {
            setRefetchData(false);
          }
        }
      } else {
        if (prevPeriod.action === webSocketActionEnum.END) {
          if (refetchData === false) {
            setRefetchData(true);
          }
        } else {
          if (refetchData === true) {
            setRefetchData(false);
          }
        }
      }
    }
  }, [refetchData, counter, prevPeriod.action]);

  useEffect(() => {
    if (websocketToken) {
      const token = websocketToken.replace(/"/g, "");
      setwsUrl(`${WS_PROTOCOL}://${GAME_SOCKET_URL}?token=${token}`);
    }
  }, [websocketToken]);

  useEffect(() => {
    setWebsocketToken(window.localStorage.getItem(localStorageKey.TOKEN));
  }, []);

  useEffect(() => {
    if (webSocket.current && isWebsocketConnected) {
      webSocket.current.onopen = () => {
        repeatCount.current = 0;
        subscribe({ channel: null });
      };
      webSocket.current.onerror = () => {
        console.log("WebSocket error");
      };
      webSocket.current.onclose = () => {
        // unsubscribe();
        console.log("WebSocket Connection Closed");
      };
      webSocket.current.onreconnect = (error) => {
        repeatCount.current = repeatCount.current + 1;
        if (repeatCount.current >= MAX_REPEAT) {
          setWebsocketTimeout(true);
        }
      };

      webSocket.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.channel === currentChannel.current) {
            // console.debug(
            //   `${data.period}:   `,
            //   "action:    ",
            //   `${data.action}     `,
            //   "lottery number:   ",
            //   `${data.lottery_number}   `,
            // );
            // if (data.channel.includes("LOTLAUNCH")) {
            const action = data.action;
            switch (action) {
              //START === CURRENT PERIOD
              case webSocketActionEnum.START:
                if (data.lottery_number === "") {
                  if (data.period === null) {
                    setIsWaitPeriod(true);
                  } else {
                    setIsWaitPeriod(false);
                  }
                  setCurrentPeriod(data);
                }
                break;
              case webSocketActionEnum.PENDING:
                if (!prevPeriod.period || prevPeriod.period !== data.period) {
                  setPrevPeriod(data);
                }
                break;
              case webSocketActionEnum.END:
                if (!prevPeriod.period || prevPeriod.period === data.period) {
                  setPrevPeriod(data);
                }
                break;
              default:
                // PENDING || END === PREV PERIOD
                // PENDING: KILL RATE STILL CALCULATING, lottery_number == empty
                // END    : KILL RATE ALRD FINISH CALCULATE, lottery_number == have data inside
                break;
            }
          }
        } catch (error) {
          console.log(event.data);
        }
      };
    }
  }, [isWebsocketConnected, isWaitPeriod, prevPeriod.period]);

  return (
    <WebsocketGameContext.Provider
      value={{
        openWebsocket,
        closeWebsocket,
        subscribe,
        ping,
        setWebsocketToken,
        switchChannel,
        setCounter,
        wsUrl,
        // setWebsocketChannel,
        isWebsocketConnected,
        currentPeriod,
        prevPeriod,
        websocketToken,
        isWaitPeriod,
        counter,
        refetchData,
        currentChannel: currentChannel.current,
        websocketTimeout,
      }}
    >
      {children}
    </WebsocketGameContext.Provider>
  );
};
export const useGameWebsocket = () => {
  const WebsocketValue = useContext(WebsocketGameContext);
  return WebsocketValue;
};
