import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { PRODUCTION_NAME_DEV } from "../../../../../../config";
import styles from "./styleModule";

const CategoryItem = ({
  category,
  active = false,
  onClick = () => {},
  selectedCategory,
  categoryItemContainerStyle = "",
  categoryItemContainerActiveStyle = "",
  categoryItemImageStyle = "",
  categoryTitleStyle = "",
  categoryTitleActiveStyle = "",
}) => {
  const { t } = useTranslation();
  return (
    <Link
      to={category?.game_category_code}
      containerId={"gameContainer"}
      spy={selectedCategory !== "hot"}
      duration={250}
      smooth={true}
      className={`${styles.categoryContainer} ${categoryItemContainerStyle} ${
        active ? styles.active : ""
      } ${active ? categoryItemContainerActiveStyle : ""}`}
      onClick={() => {
        onClick(category?.game_category_code);
      }}
      onSetActive={() => {
        onClick(category?.game_category_code);
      }}
    >
      <img
        className={`${styles.image} ${categoryItemImageStyle}`}
        src={
          active
            ? `/images/logo/${PRODUCTION_NAME_DEV}/${category?.game_category_code}_active.png`
            : `/images/logo/${PRODUCTION_NAME_DEV}/${category?.game_category_code}.png`
        }
        alt={category?.game_category_code}
      />
      <div
        className={`${styles.title} ${categoryTitleStyle} ${active ? styles.active : ""} ${
          active ? categoryTitleActiveStyle : ""
        }`}
      >
        {t([
          `home.games.${PRODUCTION_NAME_DEV}.${category?.game_category_code}`,
          `home.games.${category?.game_category_code}`,
        ])}
      </div>
    </Link>
  );
};
export default CategoryItem;
