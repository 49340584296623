import React from "react";

const ProfileIcon = ({ color1 = "#F4BFAE", color2 = "#EFA78C" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.125 12C22.125 6.49 17.635 2 12.125 2C6.615 2 2.125 6.49 2.125 12C2.125 14.9 3.375 17.51 5.355 19.34C5.355 19.35 5.355 19.35 5.345 19.36C5.445 19.46 5.565 19.54 5.665 19.63C5.725 19.68 5.775 19.73 5.835 19.77C6.015 19.92 6.215 20.06 6.405 20.2L6.605 20.34C6.795 20.47 6.995 20.59 7.205 20.7C7.275 20.74 7.355 20.79 7.425 20.83C7.625 20.94 7.835 21.04 8.055 21.13C8.135 21.17 8.215 21.21 8.295 21.24C8.515 21.33 8.735 21.41 8.955 21.48C9.035 21.51 9.115 21.54 9.195 21.56C9.435 21.63 9.675 21.69 9.915 21.75C9.985 21.77 10.055 21.79 10.135 21.8C10.415 21.86 10.695 21.9 10.985 21.93C11.025 21.93 11.065 21.94 11.105 21.95C11.445 21.98 11.785 22 12.125 22C12.465 22 12.805 21.98 13.135 21.95C13.175 21.95 13.215 21.94 13.255 21.93C13.545 21.9 13.825 21.86 14.105 21.8C14.175 21.79 14.245 21.76 14.325 21.75C14.565 21.69 14.815 21.64 15.045 21.56C15.125 21.53 15.205 21.5 15.285 21.48C15.505 21.4 15.735 21.33 15.945 21.24C16.025 21.21 16.105 21.17 16.185 21.13C16.395 21.04 16.605 20.94 16.815 20.83C16.895 20.79 16.965 20.74 17.035 20.7C17.235 20.58 17.435 20.47 17.635 20.34C17.705 20.3 17.765 20.25 17.835 20.2C18.035 20.06 18.225 19.92 18.405 19.77C18.465 19.72 18.515 19.67 18.575 19.63C18.685 19.54 18.795 19.45 18.895 19.36C18.895 19.35 18.895 19.35 18.885 19.34C20.875 17.51 22.125 14.9 22.125 12ZM17.065 16.97C14.355 15.15 9.915 15.15 7.185 16.97C6.745 17.26 6.385 17.6 6.085 17.97C4.50981 16.3823 3.62564 14.2365 3.625 12C3.625 7.31 7.435 3.5 12.125 3.5C16.815 3.5 20.625 7.31 20.625 12C20.625 14.32 19.685 16.43 18.165 17.97C17.875 17.6 17.505 17.26 17.065 16.97Z"
        fill="url(#paint0_linear_4247_28328)"
      />
      <path
        d="M12.125 6.92969C10.055 6.92969 8.375 8.60969 8.375 10.6797C8.375 12.7097 9.965 14.3597 12.075 14.4197H12.255C13.2257 14.3878 14.146 13.9798 14.8215 13.2819C15.497 12.584 15.8747 11.6509 15.875 10.6797C15.875 8.60969 14.195 6.92969 12.125 6.92969Z"
        fill="url(#paint1_linear_4247_28328)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4247_28328"
          x1="12.125"
          y1="2"
          x2="12.125"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} />
          <stop offset="1" stop-color={color2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4247_28328"
          x1="12.125"
          y1="2"
          x2="12.125"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} />
          <stop offset="1" stop-color={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileIcon;
