import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomFloatingBubble } from "../../../../components";
import { useLogin } from "../../../../context/LoginContext";
import { useCheckShowBubble } from "../../../../hooks/bonusWheel/bonusWheel.hook";

import CloseIcon from "../../icons/CloseIcon";

import { SHOW_TG_BUBBLE } from "../../../../config";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetTGLink } from "../../../../hooks/tgLink/tglink.hooks";
import TelegramBubble from "./components/TelegramBubble";
import styles from "./style.module.scss";
import "./style.scss";

const DEFAULT_CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

function BonusWheelBubble({
  isFloatingBubble = true,
  closeIconColor = "var(--color-bonusWheel-bubble-close)",
  initialBottomPosition = "76px",
  setIsShowing = () => {},
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkIfLogin, isLogin } = useLogin();
  const currency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

  const { data: tgLink } = useGetTGLink({
    options: { enabled: !!currency },
  });
  const { data: apiShowBubble } = useCheckShowBubble({
    enabled: DEFAULT_CURRENCY !== null && isLogin,
    staleTime: 60 * (60 * 1000), //60mins
    cacheTime: 60 * (60 * 1000), //60mins
  });
  const [showBonusWheelBubble, setShowBonusWheelBubble] = useState(true);
  const [showTelegramBubble, setShowTelegramBubble] = useState(true);

  const toWheel = () => {
    if (!checkIfLogin()) return;
    navigate(`/bonusWheel?id=${apiShowBubble.id}`);
  };

  useEffect(() => {
    if (apiShowBubble?.status) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
    // setIsShowing(true);
  }, [apiShowBubble]);

  return (
    <>
      {SHOW_TG_BUBBLE && tgLink?.data && showTelegramBubble && (
        <TelegramBubble
          apiShowBubble={apiShowBubble}
          initialBottomPosition={initialBottomPosition}
          tgLink={tgLink}
          setShowTelegramBubble={setShowTelegramBubble}
          closeIconColor={closeIconColor}
        />
      )}
      {apiShowBubble?.status && showBonusWheelBubble ? (
        isFloatingBubble ? (
          <>
            <CustomFloatingBubble
              onClick={() => {
                // if (!checkIfLogin()) return;
                // navigate("/bonusWheel");
              }}
              positionRight={"6px"}
              edgeDistance={"6px"}
              style={{
                "--initial-position-bottom": initialBottomPosition,
                "--z-index": "999",
                "--background": "transparent",
                "--border-radius": 0,
              }}
              className={`${styles.bonusWheelBubble} bonus-wheel-bubble`}
            >
              <img
                src="/images/bonusWheel/home_wheel.gif"
                alt="wheel"
                className={styles.wheel}
                draggable={false}
                onClick={toWheel}
              />
              <div
                className={styles.close}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowBonusWheelBubble(false);
                }}
              >
                <CloseIcon color={closeIconColor} />
              </div>
              <div className={styles.text} onClick={toWheel}>
                {t("home.bonusWheelBubble.text")}
              </div>
            </CustomFloatingBubble>
          </>
        ) : (
          <div className={`${styles.bonusWheelBubble}`}>
            <img
              src="/images/bonusWheel/home_wheel.gif"
              alt="wheel"
              className={styles.wheel}
              draggable={false}
              onClick={toWheel}
            />
            <div
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation();
                setShowBonusWheelBubble(false);
                setIsShowing(false);
              }}
            >
              <CloseIcon color={closeIconColor} />
            </div>
            <div className={styles.text} onClick={toWheel}>
              {t("home.bonusWheelBubble.text")}
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
}

export default BonusWheelBubble;
