import { Form, InputNumber } from "antd";
import React from "react";
import "./style";

const CustomInputNumber = ({
  placeholder = "",
  addonBefore = null,
  addonAfter = null,
  bordered = true,
  label = "",
  name = "",
  rules = [],
  disabled = false,
  readOnly = false,
  value,
  onChange = () => {},
  formatter,
  parser,
  rightAlign,
  shouldUpdate,
  initialValue,
  tooltip,
}) => {
  return (
    <Form.Item
      className="number_container"
      label={label}
      name={name}
      rules={rules}
      shouldUpdate={shouldUpdate}
      initialValue={initialValue}
      tooltip={tooltip}
    >
      <InputNumber
        className={rightAlign ? "right-align" : "left-align"}
        // defaultValue={value}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        bordered={bordered}
        onChange={onChange}
        formatter={formatter}
        parser={parser}
      />
    </Form.Item>
  );
};

export default CustomInputNumber;
