import { createContext, useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { PC_URL } from "../config";

const DeviceContext = createContext({
  isMobile: false,
});

export const DeviceContextProvider = ({ children }) => {
  const [params] = useSearchParams();
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  useEffect(() => {
    if (isMobile === false) {
      if (PC_URL !== "") {
        if (params.get("invite")) {
          window.location.replace(`${PC_URL}?invite=${params.get("invite")}`);
        } else {
          window.location.replace(PC_URL);
        }
      }
    }
  }, [params]);

  return (
    <DeviceContext.Provider
      value={{
        isMobile,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
export const useDevice = () => {
  const DeviceValue = useContext(DeviceContext);
  return DeviceValue;
};
