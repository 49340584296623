import React from "react";
import styles from "./style.module.scss";

const Dice = ({ color, text = "", customContainerStyle = "", customTextStyle = "" }) => {
  return (
    <div
      className={`${styles.diceContainer} ${customContainerStyle} ${customTextStyle}`}
      style={{ backgroundColor: `${color}` }}
    >
      {text}
    </div>
  );
};

export default Dice;
