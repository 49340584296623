import React from "react";

function CloseIcon({ color = "rgba(255,255,255,.7)" }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2120_8584)">
        <path
          d="M19.0711 4.92898C15.1749 1.03282 8.82509 1.03282 4.92893 4.92898C1.03277 8.82514 1.03277 15.175 4.92893 19.0711C8.82509 22.9673 15.1749 22.9673 19.0711 19.0711C22.9672 15.175 22.9672 8.82514 19.0711 4.92898ZM14.2981 15.3588L12 13.0607L9.7019 15.3588C9.41199 15.6487 8.93116 15.6487 8.64124 15.3588C8.35133 15.0689 8.35133 14.5881 8.64124 14.2981L10.9393 12L8.64124 9.70195C8.35133 9.41203 8.35133 8.9312 8.64124 8.64129C8.93116 8.35137 9.41199 8.35137 9.7019 8.64129L12 10.9394L14.2981 8.64129C14.588 8.35137 15.0688 8.35137 15.3588 8.64129C15.6487 8.9312 15.6487 9.41203 15.3588 9.70195L13.0607 12L15.3588 14.2981C15.6487 14.5881 15.6487 15.0689 15.3588 15.3588C15.0688 15.6487 14.588 15.6487 14.2981 15.3588Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2120_8584">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
