import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LAST_PLAY_COUNT } from "../../../config";
import localStorageKey from "../../../enumerations/localStorageKey";
import { usePutRecentPlayStatus } from "./hooks/recentplay.hooks";

const RecentPlayGamesContext = createContext({
  recentPlayList: [],
  setRecentPlayGameList: ({ game }) => {},
  resetRecentPlayGameList: () => {},
});

export const RecentPlayGamesContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [recentPlayList, setRecentPlayList] = useState([]);
  const { mutate: updateRecentPlayStatus, isIdle, isLoading } = usePutRecentPlayStatus({});

  const getRecentPlayGameList = async () => {
    const _recentPlayList = JSON.parse(localStorage.getItem(localStorageKey.RECENT_PLAY));
    const localCacheTime = localStorage.getItem(localStorageKey.STATUS_CHECK_CACHE);
    const nowTime = new Date().getTime();
    const cacheTime = new Date().getTime() + 60000;
    if (
      !_recentPlayList ||
      _recentPlayList.length <= 0 ||
      (localCacheTime && nowTime <= localCacheTime)
    )
      return [];
    setRecentPlayList(_recentPlayList);
    await updateRecentPlayStatus(
      {
        data: _recentPlayList.map((_recent) => ({
          game_3th_code: _recent.game_3th_code,
          game_code: _recent.game_code,
          game_id: _recent.game_id,
        })),
      },
      {
        onSuccess: (data) => {
          const updated = _recentPlayList
            .map((recent) => {
              const newStatus = data.find((_data) => _data.game_id === recent.game_id);
              return {
                ...recent,
                ...newStatus,
              };
            })
            .filter((recent) => recent.status === 1);
          setRecentPlayList(updated);
          localStorage.setItem(localStorageKey.RECENT_PLAY, JSON.stringify(updated));
          localStorage.setItem(localStorageKey.STATUS_CHECK_CACHE, cacheTime);
        },
      },
    );
  };

  const setRecentPlayGameList = ({ game }) => {
    let _recentPlayList = JSON.parse(localStorage.getItem(localStorageKey.RECENT_PLAY)) || [];
    const index = _recentPlayList.findIndex((_game) => _game.game_code === game.game_code);
    if (index >= 0) {
      _recentPlayList = [..._recentPlayList.slice(0, index), ..._recentPlayList.slice(index + 1)];
    }
    const newRecentPlaylist = JSON.stringify([game, ..._recentPlayList].slice(0, LAST_PLAY_COUNT));
    localStorage.setItem(localStorageKey.RECENT_PLAY, newRecentPlaylist);
    setRecentPlayList(JSON.parse(newRecentPlaylist));
  };

  useEffect(() => {
    if (pathname === "/") {
      console.debug("get recent while settled true");
      getRecentPlayGameList();
    }
  }, [pathname]);

  const resetRecentPlayGameList = () => {
    localStorage.removeItem(localStorageKey.RECENT_PLAY);
    setRecentPlayList([]);
  };

  return (
    <RecentPlayGamesContext.Provider
      value={{
        recentPlayList,
        setRecentPlayGameList,
        resetRecentPlayGameList,
      }}
    >
      {children}
    </RecentPlayGamesContext.Provider>
  );
};
export const useRecentPlayGames = () => {
  const RecentPlayGamesValue = useContext(RecentPlayGamesContext);
  return RecentPlayGamesValue;
};
