import React, { useEffect } from "react";
import Lang from "./Lang";
import { useTranslation } from "react-i18next";
import useLocalStorage from "../../useLocalStorage";
const langList = [
  { id: 1, lang: "zh", name: "简中" },
  { id: 2, lang: "en", name: "English" },
  { id: 3, lang: "idr", name: "印尼 (Indonesian)" },
];
function Context(props) {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage("lang", 'zh');

  const CHANG_LANG = (lang) => {
    setLang(lang.id);
    i18n.changeLanguage(lang.lang);
  };
  const GET_LANG = () => {
    return parseInt(window.localStorage.getItem("lang"), 10);
  };
  const contextValue = {
    lang,
    langList,
    GET_LANG,
    CHANG_LANG,
  };
  return <Lang.Provider value={contextValue}>{props.children}</Lang.Provider>;
}

export default Context;
