import { requestGlobalUserData, requestMain } from "../../../apis";

export const apiGetUserInfo = (params) =>
  requestGlobalUserData("get", `/user/info`, params, true);

export const apiGetUserWalletData = (params) =>
  requestMain("get", `/user/wallet`, params, true);

export const apiGetBetDetails = (gameType, params) =>
  requestMain("get", `/game/bet/history?code=${gameType}`, params, true);
