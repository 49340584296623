import { Suspense, useContext, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { LoadingView } from "../components";
import { DailyCheckContextProvider, DeviceContextProvider, LoginContextProvider } from "../context";
import { AppContextProvider } from "../context/AppContext";
import { FocusContextProvider } from "../context/FocusContext";
import { RecentPlayGamesContextProvider } from "../pages/Home/context/RecentPlayGamesContext";
import RouterContext from "./RouterContext";
import layouts from "./layouts";
import RouterList from "./router";

const findRouteConfig = (currentPath = "") => {
  let target = RouterList.find((route) => {
    return route.path === currentPath ?? window.location.pathname;
  });
  if (!target) return "default";

  return !!target ? target : {}; //background
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const RouterRender = ({ router, match, showTransform }) => {
  let { location } = router;
  const { changeLayout, changeNavType } = useContext(RouterContext);

  useEffect(() => {
    // console.log(location);
    changeLayout(findRouteConfig(location.pathname)?.layout);
    changeNavType(findRouteConfig(location.pathname)?.navType);
  }, [location]);

  return (
    <TransitionGroup>
      <CSSTransition classNames={"fade"} in={match != null} timeout={0} key={location?.key} appear>
        <Suspense fallback={<LoadingView />}>
          <Routes>
            {RouterList.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component {...route.props} />}
                />
              );
            })}
          </Routes>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};

const RouterRenderWithRouter = withRouter(RouterRender);

const ProcessRouter = () => {
  const [layout, setLayout] = useState(findRouteConfig()?.layout ?? "default");
  const [navType, setNavType] = useState("");
  const actions = {
    changeLayout: (layout = "default") => {
      setLayout(layout ?? "default");
    },
    changeNavType: (navType = {}) => {
      setNavType(navType ?? {});
    },
  };

  var LayoutComponet = layouts[layout];

  return (
    <RouterContext.Provider value={actions}>
      <Router>
        <FocusContextProvider>
          <DeviceContextProvider>
            <LoginContextProvider>
              <AppContextProvider>
                <DailyCheckContextProvider>
                  <LayoutComponet navType={navType ? navType : { type: "default" }}>
                    <RecentPlayGamesContextProvider>
                      <RouterRenderWithRouter />
                    </RecentPlayGamesContextProvider>
                  </LayoutComponet>
                </DailyCheckContextProvider>
              </AppContextProvider>
            </LoginContextProvider>
          </DeviceContextProvider>
        </FocusContextProvider>
      </Router>
    </RouterContext.Provider>
  );
};

export default ProcessRouter;
