export const platformAdScript = {
  FBpixel: {
    script: ({ id }) =>
      `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${id}');
      fbq('track', 'PageView');`,
    noscript: ({ id }) =>
      `<img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"
      />`,
  },
  "51la": {
    script: ({ id }) =>
      `!function(p)
      {"use strict";
      !function(t){var s=window,e=document,i=p,c="".concat("https:"===e.location.protocol?"https://":"http://","sdk.51.la/js-sdk-pro.min.js"),n=e.createElement("script"),r=e.getElementsByTagName("script")[0];
      n.type="text/javascript",n.setAttribute("charset","UTF-8"),n.async=!0,n.src=c,n.id="LA_COLLECT",i.d=n;
      var o=function(){s.LA.ids.push(i)};
      s.LA?s.LA.ids&&o():(s.LA=p,s.LA.ids=[],o()),r.parentNode.insertBefore(n,r)}()}({id:"${id}",ck:"${id}"});`,
  },
};
