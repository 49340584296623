import { Space } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AGENT_MOBILE_URL } from "../../../../../../config";
import { useApp } from "../../../../../../context/AppContext";
import { useLogin } from "../../../../../../context/LoginContext";
import localStorageKey from "../../../../../../enumerations/localStorageKey";
import { useGetTGLink } from "../../../../../../hooks/tgLink/tglink.hooks";
import DrawerAgent from "../../../../../Personal/components/DrawerAgent";
import {
  AffilateIcon,
  AnnouncementIcon,
  BankCardIcon,
  BetRecordIcon,
  DownloadIcon,
  FAQIcon,
  FinancialRecordIcon2,
  PromoIcon,
  TelegramIcon,
  VIPIcon,
  WithdrawalIcon2,
} from "../../../../../Personal/images";
import { useSearch } from "../../../../context/SearchContext";
import { MenuItem } from "./components";
import { AgentIcon, MessagesIcon, MoreIcon, SearchIcon } from "./images/1bbetv2";
import CSIcon from "./images/cs.svg";
import styles from "./styleModule";

const Menu = ({ textStyle = "" }) => {
  const currency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
  const { t, i18n } = useTranslation();
  const { isApp } = useApp();
  const { checkIfLogin, openCSDrawer } = useLogin();
  const { setShowSearchPopup, setSelectedGame3th } = useSearch();
  const getLanguage = () => i18n.language;
  const nowLang = getLanguage();
  const navigate = useNavigate();
  const [showDrawerAgent, setShowDrawerAgent] = useState(false);
  // const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
  const [agentUrl, setAgentUrl] = useState("");
  const [showCsPopover, setShowCsPopover] = useState(false);
  const [showMorePopover, setShowMorePopover] = useState(false);
  // const isApp = window.localStorage.getItem(localStorageKey.IS_APP);

  const { data: tgLink } = useGetTGLink({
    options: { enabled: !!currency },
  });

  const buttonGroup = [
    {
      key: "agent",
      name: t("menu.agent"),
      icon: (
        <AgentIcon
          color1={"var(--home-account-area-menu1)"}
          color2={"var(--home-account-area-menu2)"}
        />
      ),
      type: "url",
      onClick: (e) => {
        if (!checkIfLogin()) return;
        const token = window.localStorage.getItem("token");
        setAgentUrl(`${AGENT_MOBILE_URL}?token=${token}&lang=${nowLang}&currency=${currency}`);
        setShowDrawerAgent(true);
      },
      showPopover: false,
      setShowPopover: () => {},
    },
    {
      key: "search",
      name: t("home.search"),
      icon: (
        <SearchIcon
          color1={"var(--home-account-area-menu1)"}
          color2={"var(--home-account-area-menu2)"}
        />
      ),
      type: "url",
      onClick: () => {
        if (!checkIfLogin()) return;
        setSelectedGame3th({ game_3th_code: "search_all" });
        setShowSearchPopup(true);
      },
      showPopover: false,
      setShowPopover: () => {},
    },
    {
      key: "cs",
      name: t("home.cs"),
      icon: (
        <MessagesIcon
          color1={"var(--home-account-area-menu1)"}
          color2={"var(--home-account-area-menu2)"}
        />
      ),
      type: tgLink?.data !== "" ? "popover" : "url",
      onClick: () => {
        if (tgLink?.data === "") {
          openCSDrawer();
        }
      },
      showPopover: showCsPopover,
      setShowPopover: setShowCsPopover,
      popoverMenu: [
        {
          text: t("home.cs"),
          onClick: () => {
            openCSDrawer();
          },
          icon: <img className={styles.icon} src={CSIcon} alt={"cs"} />,
        },
        {
          text: t("normal.tgChannel"),
          onClick: () => {
            window.open(tgLink?.data);
          },
          icon: <img className={styles.icon} src={TelegramIcon} alt={t("normal.tgChannel")} />,
        },
      ],
    },
    {
      key: "more",
      name: t("home.more"),
      icon: (
        <MoreIcon
          color1={"var(--home-account-area-menu1)"}
          color2={"var(--home-account-area-menu2)"}
        />
      ),
      type: "popover",
      onClick: () => {},
      showPopover: showMorePopover,
      setShowPopover: setShowMorePopover,
      popoverMenu: [
        {
          text: t("personal.affiliateCenter"),
          onClick: (e) => {
            if (!checkIfLogin()) return;
            const token = window.localStorage.getItem("token");
            setAgentUrl(`${AGENT_MOBILE_URL}?token=${token}&lang=${nowLang}&currency=${currency}`);
            setShowDrawerAgent(true);
          },
          icon: (
            <img
              className={styles.icon}
              src={AffilateIcon}
              alt={t("page.personal.affiliateCenter")}
            />
          ),
        },
        {
          text: t("withdraw.manage.title"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/home/wallet/manager");
          },
          icon: <img className={styles.icon} src={BankCardIcon} alt={t("withdraw.manage.title")} />,
        },
        {
          text: t("personal.betRecord"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/betRecord");
          },
          icon: <img className={styles.icon} src={BetRecordIcon} alt={t("personal.betRecord")} />,
        },
        {
          text: t("transaction.record.title"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/exchange/history");
          },
          icon: (
            <img
              className={styles.icon}
              src={FinancialRecordIcon2}
              alt={t("transaction.record.title")}
            />
          ),
        },
        {
          text: t("normal.vip"),
          onClick: () => {
            if (!checkIfLogin()) return;
            // navigate("/home/bonus?tab=vip");
            navigate("/vip");
          },
          icon: <img className={styles.icon} src={VIPIcon} alt={t("normal.vip")} />,
        },
        {
          text: t("1bbet.footer.promo"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/home/bonus");
          },
          icon: <img className={styles.icon} src={PromoIcon} alt={t("normal.promo")} />,
        },
        {
          text: t("normal.withdraw"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/exchange?tab=withdraw");
          },
          icon: <img className={styles.icon} src={WithdrawalIcon2} alt={t("normal.withdraw")} />,
        },
        {
          text: t("normal.announce"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/announce");
          },
          icon: <img className={styles.icon} src={AnnouncementIcon} alt={t("normal.announce")} />,
        },
        {
          text: t("personal.FAQs"),
          onClick: () => {
            if (!checkIfLogin()) return;
            navigate("/commonIssues");
          },
          icon: <img className={styles.icon} src={FAQIcon} alt={t("personal.FAQs")} />,
        },
        {
          text: t("personal.appDownload"),
          onClick: () => {
            navigate("/appDownload");
          },
          icon: <img className={styles.icon} src={DownloadIcon} alt={t("personal.appDownload")} />,
          hideItem: isApp ? true : false,
        },
        {
          text: t("normal.tgChannel"),
          onClick: () => {
            window.open(tgLink?.data);
          },
          icon: <img className={styles.icon} src={TelegramIcon} alt={t("normal.tgChannel")} />,
          hideItem: tgLink?.data === "" ? true : false,
        },
      ],
    },
  ];

  return (
    <div className={styles.container}>
      <Space>
        {buttonGroup.map((button) => {
          return <MenuItem buttonSetting={button} key={button.key} textStyle={textStyle} />;
        })}
      </Space>
      <DrawerAgent
        onClose={() => {
          setShowDrawerAgent(false);
        }}
        showDrawer={showDrawerAgent}
        setShowDrawer={setShowDrawerAgent}
        title={t("personal.affiliateCenter")}
        url={agentUrl}
      />
    </div>
  );
};
export default Menu;
