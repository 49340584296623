import { Image, Tabs } from "antd-mobile";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetGameV2 } from "../../hooks/home1bbet.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
const JpyMenu = ({
  selectedCategory = "",
  setSelectedCategory = () => {},
  setCurrentCategory = () => {},
  excludedCategories = [],
}) => {
  const { t } = useTranslation();

  const { data: allGames } = useGetGameV2(
    {
      enabled: !!CURRENCY,
    },
    {
      excludedCategories: excludedCategories,
    },
  );

  useEffect(() => {
    if (allGames?.category_list && allGames?.category_list.length > 0) {
      setSelectedCategory(allGames.category_list[0].game_category_code);
      setCurrentCategory(allGames.category_list[0]);
    }
  }, [allGames]);

  const onChangeCategory = (key) => {
    setSelectedCategory(key);
    if (allGames?.category_list && allGames?.category_list.length > 0) {
      const found = allGames.category_list.findIndex(
        (category) => key === category.game_category_code,
      );
      if (found >= 0) {
        setCurrentCategory(allGames.category_list[found]);
      }
    }
  };

  const renderTitle = (category) => {
    return (
      <div className={styles.titleContainer}>
        <Image
          width={20}
          height={20}
          src={`/images/category_list/${PRODUCTION_NAME_DEV}/${category.game_category_code}.svg`}
        />
        <div className={styles.title}>{t(`home.games.${category.game_category_code}`)}</div>
      </div>
    );
  };

  const renderTabItems = () => {
    if (!allGames?.category_list) return null;
    return allGames?.category_list.map((category) => {
      return <Tabs.Tab title={renderTitle(category)} key={category.game_category_code} />;
    });
  };

  return (
    <div className={`${styles.container}`}>
      <Tabs
        activeKey={selectedCategory}
        activeLineMode={"full"}
        stretch={true}
        onChange={(key) => onChangeCategory(key)}
        className="jpy-tab"
      >
        {renderTabItems()}
      </Tabs>
    </div>
  );
};

export default JpyMenu;
