import { Checkbox } from "antd";
import { Image, Popup, Swiper } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomFloatingBubble, CustomModal } from "../../../../components";
import Lobby from "../../../../components/CustomGamePopup/icons/Lobby";
import { DEFAULT_LANG, GCODE } from "../../../../config";
import localStorageKey from "../../../../enumerations/localStorageKey";
import HomeFill from "../DrawerTransfer/icons/HomeFill";
import LotteryNotification from "../LotteryNotification";
import LotteryPop from "../LotteryPop";
import tutorialSettingEnum from "./enumerations/tutorialSettingEnum";
import SwipeGIF from "./images/swipe.gif";
import styles from "./style.module.scss";
import "./style.scss";

const GamePopup = ({
  visible = false,
  onClose = () => {},
  title = "",
  showHeader = true,
  showBubble = false,
  gameURL = "",
  gameHTML = "",
  showTutorial = true,
  game3thName = "",
  closeTutorial = () => {},
  showLotteryButton = false,
}) => {
  const { t } = useTranslation();
  const LANG = localStorage.getItem(localStorageKey.LANG_SETTING) || DEFAULT_LANG;
  const [showBackPopup, setShowBackPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [currentSwipeIndex, setCurrentSwipeIndex] = useState(0);
  const [showLotteryDrawer, setShowLotteryDrawer] = useState(false);
  const [showNotification, setShowNotification] = useState(true);

  //lottery notification animation method start
  const nowMinute = new Date().getMinutes();
  const nowSeconds = new Date().getSeconds();
  const animationInterval = 5;
  const getFirstAnimatedTime = () => {
    // console.log("nowMinute", nowMinute);
    // console.log("nowSeconds", nowSeconds);
    if (nowMinute % animationInterval === 0 && nowSeconds === 0) {
      return 0;
    } else {
      return (
        animationInterval * 60000 - ((nowMinute % animationInterval) * 60000 + nowSeconds * 1000)
      );
    }
  };
  const firstAnimateTime = getFirstAnimatedTime();
  //lottery notification animation method end

  const renderBubble = () => {
    if (showBubble === false) return;
    return (
      <>
        <CustomFloatingBubble
          className={styles.floatingBubble}
          onClick={() => {
            setShowBackPopup(true);
          }}
          style={{
            "--background": "var(--color-floating-bubble-bg)",
          }}
        >
          <Lobby />
        </CustomFloatingBubble>
        <CustomModal
          isModalVisible={showBackPopup}
          setIsModalVisible={setShowBackPopup}
          content={t("backToLobby.modal.hint")}
          okBtnText={t("backToLobby.modal.stay")}
          cancelBtnText={t("backToLobby.modal.back")}
          onOk={() => {
            setShowBackPopup(false);
          }}
          onCancel={() => {
            setShowBackPopup(false);
            onClose();
          }}
          needIcon={true}
          okBtnStyle={{
            "--ant-primary-color": "var(--color-mg-continue-btn-bg)",
          }}
        />
      </>
    );
  };

  const onRememberCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCloseTutorial = () => {
    closeTutorial(isChecked, game3thName);
  };

  const renderTutorialFooter = (index, count) => {
    if (index < count - 1) {
      return null;
    } else {
      return (
        <>
          <div className={`checkBoxTutorialPopupStyle`}>
            <Checkbox onChange={onRememberCheck} checked={isChecked}>
              {t("1bbet.tutorial.skip.title")}
            </Checkbox>
          </div>
          <div onClick={handleCloseTutorial} className={styles.button}>
            {t("1bbet.tutorial.openGame.title")}
          </div>
        </>
      );
    }
  };

  const renderLotteryButton = () => {
    return (
      <div
        id="navBarLotteryClick"
        className={styles.lotteryButtonContainer}
        onClick={() => {
          if (!showLotteryDrawer) {
            if (GCODE !== "" && GCODE !== null && GCODE !== undefined) {
              window.dataLayer.push({
                event: "button-click",
                eventProps: {
                  value: "GamePopup_Navbar_Icon_Button",
                },
              });
            }
          }
          setShowLotteryDrawer((prevState) => !prevState);
        }}
      >
        <img
          className={styles.lotteryButtonImg}
          src={require(`./images/chest.gif`)}
          alt={"lottery"}
        />
        <img
          className={styles.lotteryButtonImg}
          src={require(`./images/coin.gif`)}
          alt={"lottery"}
        />
      </div>
    );
  };

  const renderLottery = () => {
    // console.debug("render Lottery");
    if (showLotteryDrawer === false) return null;
    // console.debug("render Lottery show");
    return (
      <LotteryPop
        setShowLotteryDrawer={setShowLotteryDrawer}
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    );
  };

  const handleOnClose = () => {
    setShowLotteryDrawer(false);
    setShowNotification(true);
    onClose();
  };

  const renderTutorial = () => {
    if (showTutorial)
      return (
        <div className={styles.tutorialContainer}>
          <Swiper
            loop={false}
            onIndexChange={(index) => {
              setCurrentSwipeIndex(index);
            }}
            indicatorProps={{
              className: styles.dotStyle,
            }}
          >
            {[...Array(tutorialSettingEnum[game3thName].count)].map((tutorial, index) => {
              return (
                <Swiper.Item key={`tutorial_${index}`}>
                  <div className={`${styles.tutorialImage} ${showHeader ? styles.withHeader : ""}`}>
                    <img
                      className={styles.image}
                      src={
                        LANG === "pt"
                          ? require(`./images/pt/tutorial_${game3thName}_${index + 1}.png`)
                          : LANG === "vi"
                          ? require(`./images/vn/tutorial_${game3thName}_${index + 1}.png`)
                          : require(`./images/en/tutorial_${game3thName}_${index + 1}.png`)
                      }
                      alt="saba_1"
                    />
                    <div className={styles.footerContainer}>
                      {renderTutorialFooter(index, tutorialSettingEnum[game3thName].count)}
                    </div>
                  </div>
                </Swiper.Item>
              );
            })}
          </Swiper>
          {currentSwipeIndex < tutorialSettingEnum[game3thName].count - 1 ? (
            <Image src={SwipeGIF} className={styles.swipe} />
          ) : null}
        </div>
      );
  };

  const renderLotteryNotification = () => {
    return (
      <LotteryNotification
        showLotteryDrawer={showLotteryDrawer}
        setShowLotteryDrawer={setShowLotteryDrawer}
        isAnimate={showNotification}
        firstAnimateTime={firstAnimateTime}
        animationInterval={animationInterval}
      />
    );
  };

  const renderIframe = () => {
    if (gameURL) {
      return (
        <iframe
          className={`${styles.iframe}`}
          seamless="seamless"
          frameBorder={0}
          src={gameURL}
          title={gameURL}
        />
      );
    } else if (gameHTML) {
      return (
        <iframe
          className={`${styles.iframe}`}
          seamless="seamless"
          frameBorder={0}
          srcDoc={gameHTML}
          title={gameHTML}
        />
      );
    }
  };
  return (
    <Popup
      destroyOnClose={true}
      visible={visible}
      bodyStyle={{ width: "100vw", backgroundColor: "transparent" }}
      position={"right"}
      mask={false}
    >
      <div className={styles.gamePopupContainer}>
        {showHeader ? (
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.homeIcon} onClick={handleOnClose}>
              <HomeFill size={20} color={"var(--color-drawer-header-icon)"} />
            </div>
            {showLotteryButton ? renderLotteryButton() : null}
            {showLotteryButton ? renderLotteryNotification() : null}
            <div className={styles.rightPadding} />
          </div>
        ) : null}
        <div className={`${styles.drawerContentContainer} ${showHeader ? styles.withHeader : ""}`}>
          {renderIframe()}
          {renderLottery()}
        </div>
        {renderBubble()}
        {renderTutorial()}
      </div>
    </Popup>
  );
};

export default GamePopup;
