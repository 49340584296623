import { useMutation, useQuery } from "@tanstack/react-query";
import { HOT_GAMES_COUNT, OPEN_SABA_CRICKET } from "../../../config";
import queryKey from "../../../enumerations/queryKey";
import {
  apiGetAesexyGameLoginUrl,
  apiGetCQ9GameLoginUrl,
  apiGetEvoplayGameLoginUrl,
  apiGetFCFishGameLoginUrl,
  apiGetFCGameLoginUrl,
  apiGetGameCategoryV2,
  apiGetGameV2,
  apiGetHotGameV2,
  apiGetJDBFishGameLoginUrl,
  apiGetJDBSlotGameLoginUrl,
  apiGetJiliGameLoginUrl,
  apiGetLuckySportGameLoginUrl,
  apiGetMgGameLoginUrl,
  apiGetPPLiveGameLoginUrl,
  apiGetPTGameLoginUrl,
  apiGetPgGameLoginUrl,
  apiGetPlayStarGameLoginUrl,
  apiGetPpGameLoginUrl,
  apiGetSpribeGameLoginUrl,
  apiGetSpribeoneGameLoginUrl,
  apiGetT1LoginUrl,
  apiGetTcgGameLoginUrl,
  apiGetViaCasinoLiveGameLoginUrl,
  apiGetWeblockchainGameLoginUrl,
  apiGetWefishGameLoginUrl,
  apiGetWeliveGameLoginUrl,
  apiGetWelotteryGameLoginUrl,
  apiGetWeslotGameLoginUrl,
  apiGetWesportGameLoginUrl,
  apiGetYbslotGameLoginUrl,
  apiGetYggGameLoginUrl,
  apiGetYoubetGameLoginUrl,
  getAesexyGameList,
  getCQ9GameList,
  getEvoGameUrl,
  getEvoplayGameList,
  getFCFishGameList,
  getFCGameList,
  getJDBFishGameList,
  getJDBSlotGameList,
  getJiliGameList,
  getLuckySportGameList,
  getPPLIveGameList,
  getPTGameList,
  getPgGameList,
  getPlayStarGameList,
  getPpGameList,
  getSpribeGameList,
  getSpribeoneGameList,
  getT1GameList,
  getTcgGameList,
  getViaCasinoLiveGameList,
  getWeblockchainGameList,
  getWefishGameList,
  getWeliveGameList,
  getWelotteryGameList,
  getWeslotGameList,
  getWesportGameList,
  getWinCategory,
  getYbslotGameList,
  getYggGameList,
  getYoubetGameList,
} from "../service";

export const useGetWinGameList = (options) => {
  return useQuery(
    [queryKey.HOME_1BBET_WIN_GAME],
    () =>
      getWinCategory().then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) =>
          result.push({ code: item.game_category_detail_type, name: item.name }),
        );
        return result;
      }),
    {
      ...options,
    },
  );
};

export const useGetMgGameLoginLink = (params, options) => {
  return useQuery(
    [queryKey.MG_GAME_LINK],
    () =>
      apiGetMgGameLoginUrl(params).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      enabled: false,
      ...options,
    },
  );
};

export const useGetT1GameLoginLink = () => {
  return useMutation(
    ({ params, query }) => apiGetT1LoginUrl(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {},
    },
  );
};

export const useGetT1GameList = (options) => {
  return useQuery(
    [queryKey.T1_GAME_LIST],
    () =>
      getT1GameList().then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ code: item.game_code, name: item.name }));
        return result;
      }),
    {
      ...options,
    },
  );
};

export const useGetEvoGameLoginLink = () => {
  return useMutation(
    ({ params, query }) => getEvoGameUrl(params, query).then((res) => res.data.data),
    {
      onSuccess: () => {},
    },
  );
};

export const useGetPpGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.PP_GAME_LINK],
    ({ params, query }) =>
      apiGetPpGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};

export const useGetPpGameList = (options) => {
  return useQuery(
    [queryKey.PP_GAME_LIST],
    () =>
      getPpGameList().then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ code: item.game_code, name: item.name }));
        return result;
      }),
    {
      ...options,
    },
  );
};
//pg
export const useGetPgGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.PG_GAME_LINK],
    ({ params, query }) =>
      apiGetPgGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetPgGameList = (options) => {
  return useQuery(
    [queryKey.PG_GAME_LIST],
    () =>
      getPgGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//ygg
export const useGetYggGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.YGG_GAME_LINK],
    ({ params, query }) =>
      apiGetYggGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetYggGameList = (options) => {
  return useQuery(
    [queryKey.YGG_GAME_LIST],
    () =>
      getYggGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//evoplay
export const useGetEvoplayGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.EVOPLAY_GAME_LINK],
    ({ params, query }) =>
      apiGetEvoplayGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetEvoplayGameList = (options) => {
  return useQuery(
    [queryKey.EVOPLAY_GAME_LIST],
    () =>
      getEvoplayGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//jili
export const useGetJiliGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.JILI_GAME_LINK],
    ({ params, query }) =>
      apiGetJiliGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetJiliGameList = (options) => {
  return useQuery(
    [queryKey.JILI_GAME_LIST],
    () =>
      getJiliGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//spribe
export const useGetSpribeGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.SPRIBE_GAME_LINK],
    ({ params, query }) =>
      apiGetSpribeGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetSpribeGameList = (options) => {
  return useQuery(
    [queryKey.SPRIBE_GAME_LIST],
    () =>
      getSpribeGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//PT
export const useGetPTGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.PT_GAME_LINK],
    ({ params, query }) =>
      apiGetPTGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetPTGameList = (options) => {
  return useQuery(
    [queryKey.PT_GAME_LIST],
    () =>
      getPTGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//PPLive
export const useGetPPLiveGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.PPLIVE_GAME_LINK],
    ({ params, query }) =>
      apiGetPPLiveGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetPPLiveGameList = (options) => {
  return useQuery(
    [queryKey.PPLIVE_GAME_LIST],
    () =>
      getPPLIveGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//FC
export const useGetFCGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.FC_GAME_LINK],
    ({ params, query }) =>
      apiGetFCGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetFCGameList = (options) => {
  return useQuery(
    [queryKey.FC_GAME_LIST],
    () =>
      getFCGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//CQ9
export const useGetCQ9GameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.CQ9_GAME_LINK],
    ({ params, query }) =>
      apiGetCQ9GameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetCQ9GameList = (options) => {
  return useQuery(
    [queryKey.CQ9_GAME_LIST],
    () =>
      getCQ9GameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    {
      ...options,
    },
  );
};

//PLAY STAR
export const useGetPlayStarGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.PLAYSTAR_GAME_LINK],
    ({ params, query }) =>
      apiGetPlayStarGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetPlayStarGameList = (options) => {
  return useQuery(
    [queryKey.PLAYSTAR_GAME_LIST],
    () =>
      getPlayStarGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetWeblockchainGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.WEBLOCKCHAIN_GAME_LINK],
    ({ params, query }) =>
      apiGetWeblockchainGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetWeblockchainGameList = (options) => {
  return useQuery(
    [queryKey.WEBLOCKCHAIN_GAME_LIST],
    () =>
      getWeblockchainGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetWelotteryGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.WELOTTERY_GAME_LINK],
    ({ params, query }) =>
      apiGetWelotteryGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetWelotteryGameList = (options) => {
  return useQuery(
    [queryKey.WELOTTERY_GAME_LIST],
    () =>
      getWelotteryGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetWeliveGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.WELIVE_GAME_LINK],
    ({ params, query }) =>
      apiGetWeliveGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetWeliveGameList = (options) => {
  return useQuery(
    [queryKey.WELIVE_GAME_LIST],
    () =>
      getWeliveGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetTcgGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.TCG_GAME_LINK],
    ({ params, query }) =>
      apiGetTcgGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetTcgGameList = (options) => {
  return useQuery(
    [queryKey.TCG_GAME_LIST],
    () =>
      getTcgGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetYoubetGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.YOUBET_GAME_LINK],
    ({ params, query }) =>
      apiGetYoubetGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetYoubetGameList = (options) => {
  return useQuery(
    [queryKey.YOUBET_GAME_LIST],
    () =>
      getYoubetGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetAesexyGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.AESEXY_GAME_LINK],
    ({ params, query }) =>
      apiGetAesexyGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetAesexyGameList = (options) => {
  return useQuery(
    [queryKey.AESEXY_GAME_LIST],
    () =>
      getAesexyGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetSpribeoneGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.SPRIBEONE_GAME_LINK],
    ({ params, query }) =>
      apiGetSpribeoneGameLoginUrl(params, query).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetSpribeoneGameList = (options) => {
  return useQuery(
    [queryKey.SPRIBEONE_GAME_LIST],
    () =>
      getSpribeoneGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetWesportGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.WESPORT_GAME_LINK],
    ({ params, query }) =>
      apiGetWesportGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetWesportGameList = (options) => {
  return useQuery(
    [queryKey.WESPORT_GAME_LIST],
    () =>
      getWesportGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};
export const useGetLuckySportGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.LUCKYSPORT_GAME_LINK],
    ({ params, query }) =>
      apiGetLuckySportGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetLuckySportGameList = (options) => {
  return useQuery(
    [queryKey.LUCKYSPORT_GAME_LIST],
    () =>
      getLuckySportGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetYbslotGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.YBSLOT_GAME_LINK],
    ({ params, query }) =>
      apiGetYbslotGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetYbslotGameList = (options) => {
  return useQuery(
    [queryKey.YBSLOT_GAME_LIST],
    () =>
      getYbslotGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetWefishGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.WEFISH_GAME_LINK],
    ({ params, query }) =>
      apiGetWefishGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetWefishGameList = (options) => {
  return useQuery(
    [queryKey.WEFISH_GAME_LIST],
    () =>
      getWefishGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetWeslotGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.WESLOT_GAME_LINK],
    ({ params, query }) =>
      apiGetWeslotGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetWeslotGameList = (options) => {
  return useQuery(
    [queryKey.WESLOT_GAME_LIST],
    () =>
      getWeslotGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetFCFishGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.FCFISH_GAME_LINK],
    ({ params, query }) =>
      apiGetFCFishGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data?.gameUrl;
        return _result;
      }),
    { ...options },
  );
};
export const useGetFCFishGameList = (options) => {
  return useQuery(
    [queryKey.FCFISH_GAME_LIST],
    () =>
      getFCFishGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetJDBFishGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.JDBFISH_GAME_LINK],
    ({ params, query }) =>
      apiGetJDBFishGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data?.gameUrl;
        return _result;
      }),
    { ...options },
  );
};
export const useGetJDBFishGameList = (options) => {
  return useQuery(
    [queryKey.JDBFISH_GAME_LIST],
    () =>
      getJDBFishGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetJDBSlotGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.JDBSLOT_GAME_LINK],
    ({ params, query }) =>
      apiGetJDBSlotGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data?.gameUrl;
        return _result;
      }),
    { ...options },
  );
};
export const useGetJDBSlotGameList = (options) => {
  return useQuery(
    [queryKey.JDBSLOT_GAME_LIST],
    () =>
      getJDBSlotGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

export const useGetViaCasinoLiveGameLoginLink = (params, options) => {
  return useMutation(
    [queryKey.VIACASINOLIVE_GAME_LINK],
    ({ params, query }) =>
      apiGetViaCasinoLiveGameLoginUrl(params, query).then((res) => {
        console.debug("res", res);
        const _result = res.data.data;
        return _result;
      }),
    { ...options },
  );
};
export const useGetViaCasinoLiveGameList = (options) => {
  return useQuery(
    [queryKey.VIACASINOLIVE_GAME_LIST],
    () =>
      getViaCasinoLiveGameList({ page_size: 0 }).then((res) => {
        const _result = res.data.data;
        const result = [];
        _result?.map((item) => result.push({ ...item, code: item.id }));
        return result;
      }),
    { ...options },
  );
};

//1bbetv2
export const useGetGameCategoryV2 = (options, params) => {
  return useQuery(
    [queryKey.HOME_GAME_CATEGORY],
    () =>
      apiGetGameCategoryV2().then((res) => {
        const categories = res.data.data
          .filter(
            (data) =>
              data.game_3th.length > 0 &&
              !params?.excludedCategories.includes(data.game_category_code),
          )
          .map((category) => {
            if (OPEN_SABA_CRICKET === false) return category;
            if (category.game_category_code === "sport") {
              const sabaIndex = category.game_3th.findIndex(
                (_sport) => _sport.game_3th_code === "saba",
              );
              if (sabaIndex >= 0) {
                return {
                  ...category,
                  game_3th: [
                    ...category.game_3th.slice(0, sabaIndex + 1),
                    {
                      ...category.game_3th[sabaIndex],
                      game_3th_code: "sabacricket",
                      sorting: category.game_3th[sabaIndex].sorting + 1,
                    },
                    ...category.game_3th.slice(sabaIndex + 1, category.game_3th.length),
                  ],
                };
              }
            }
            return category;
          });
        return categories;
        // return res.data.data.filter(
        //   (data) =>
        //     data.game_3th.length > 0 &&
        //     !params?.excludedCategories.includes(data.game_category_code),
        // );
      }),
    {
      ...options,
    },
  );
};

export const useGetHotGameV2 = (options, params) => {
  return useQuery(
    [queryKey.HOT_GAME],
    () =>
      apiGetHotGameV2(params).then((res) => {
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameV2 = (options, params) => {
  return useQuery(
    [queryKey.ALL_GAME],
    () =>
      apiGetGameV2({ ...params, showNum: HOT_GAMES_COUNT }).then((res) => {
        const category = res.data.data.category_list
          .filter(
            (data) =>
              data.game_3th.length > 0 &&
              !params?.excludedCategories.includes(data.game_category_code),
          )
          .map((category) => {
            if (OPEN_SABA_CRICKET === false) return category;
            if (category.game_category_code === "sport") {
              const sabaIndex = category.game_3th.findIndex(
                (_sport) => _sport.game_3th_code === "saba",
              );
              if (sabaIndex >= 0) {
                return {
                  ...category,
                  game_3th: [
                    ...category.game_3th.slice(0, sabaIndex + 1),
                    {
                      ...category.game_3th[sabaIndex],
                      game_3th_code: "sabacricket",
                      sorting: category.game_3th[sabaIndex].sorting + 1,
                    },
                    ...category.game_3th.slice(sabaIndex + 1, category.game_3th.length),
                  ],
                };
              }
            }
            return category;
          });
        const hot = res.data.data.hot_list;
        return { hot_list: hot, category_list: category };
      }),
    {
      staleTime: 10 * (60 * 1000), //10mins
      cacheTime: 10 * (60 * 1000), //10mins
      ...options,
    },
  );
};
