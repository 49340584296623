import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../../../../context/LoginContext";
import styles from "./style.module.scss";
const LoginLink = ({ loginLinkContainerStyle = "" }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isHaveInvitation, agentCode } = useLogin();
  const redirectToLogin = () => {
    if (isHaveInvitation === true) {
      navigate(`/signup?invite=${agentCode}`);
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className={`${styles.container} ${loginLinkContainerStyle}`} onClick={redirectToLogin}>
      <div className={styles.text}>
        <span className={styles.loginText}>{t("1bbet.normal.login")}</span>
        <span className={styles.or}>{t("normal.or")}</span>
        <span className={styles.loginText}>{t("1bbet.normal.register")}</span>
      </div>
      <div className={styles.loginDesc}>{t("1bbet.login.desc")}</div>
    </div>
  );
};

export default LoginLink;
