import { Grid, Image } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HeaderItem, TimerItem, WithdrawItem } from "./components";
import { useGetStatistics } from "./hooks/webruntime.hooks";
import { Online, Player, TotalBet } from "./images";
import styles from "./style.module.scss";

// Import Swiper styles
import "swiper/css";

import { useEffect } from "react";
import { PRODUCTION_NAME_DEV } from "../../../../config";
import "./style.scss";

// const DAY_SECOND = 60 * 60 * 24;
// const HOUR_SECOND = 60 * 60;
// const MINUTE_SECOND = 60;

const RunningTime = () => {
  const { t } = useTranslation();
  const { data: statistics } = useGetStatistics({
    staleTime: 60 * (60 * 1000), //60mins
    cacheTime: 60 * (60 * 1000), //60mins
  });
  // const [time, setTime] = useState();

  // const countdown = useCallback(() => {
  //   if (statistics && statistics.start_time) {
  //     const timeStringProcess = (timeString) => {
  //       const regex = /\-/g;
  //       return timeString.replace(regex, "/");
  //     };
  //     const difference = dayjs().diff(dayjs(`${timeStringProcess(statistics.start_time)}`), "s");
  //     return difference;
  //   }
  //   return 0;
  // }, [statistics]);

  // const timer = useCallback(() => {
  //   const _timer = countdown();
  //   const days = String(parseInt(_timer / DAY_SECOND + 100)).slice(-2);
  //   const hours = String(parseInt(((_timer / HOUR_SECOND) % HOUR_SECOND) + 100)).slice(-2);
  //   const minutes = String(parseInt(((_timer / MINUTE_SECOND) % MINUTE_SECOND) + 100)).slice(-2);
  //   const seconds = String(parseInt((_timer % MINUTE_SECOND) + 100)).slice(-2);
  //   return { days, hours, minutes, seconds };
  // }, [countdown]);

  const countUpFromTime = (countFrom) => {
    const countFromTime = new Date(countFrom).getTime();
    const now = new Date();
    const timeDifference = now - countFromTime;
    const secondsInADay = 60 * 60 * 1000 * 24;
    const secondsInAHour = 60 * 60 * 1000;

    const days = Math.floor((timeDifference / secondsInADay) * 1);
    const hours = Math.floor(((timeDifference % secondsInADay) / secondsInAHour) * 1);
    const mins = Math.floor(
      (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1,
    );
    const secs = Math.floor(
      ((((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) / 1000) * 1,
    );
    document.getElementById("days").innerHTML = days;
    document.getElementById("hours").innerHTML = String(hours + 100).slice(-2);
    document.getElementById("minutes").innerHTML = String(mins + 100).slice(-2);
    document.getElementById("seconds").innerHTML = String(secs + 100).slice(-2);
    clearTimeout(countUpFromTime.interval);
    countUpFromTime.interval = setTimeout(function () {
      countUpFromTime(countFrom);
    }, 1000);
  };
  useEffect(() => {
    if (statistics && statistics.start_time) {
      countUpFromTime(statistics.start_time);
    }
    return () => {
      clearTimeout(countUpFromTime.interval);
    };
  }, [statistics]);

  const HEADER_STATISTICS = [
    {
      title: t("webRuntime.header.playerstats"),
      icon: Player,
      value: "player",
    },
    {
      title: t("webRuntime.header.totalbet"),
      icon: TotalBet,
      value: "total_betting",
      isMoney: true,
    },
    {
      title: t("webRuntime.header.online"),
      icon: Online,
      value: "online",
    },
  ];

  const TIME_STATISTICS = [
    {
      title: t("webRuntime.header.days"),
      value: "days",
    },
    {
      title: t("webRuntime.header.hours"),
      value: "hours",
    },

    {
      title: t("webRuntime.header.minutes"),
      value: "minutes",
    },

    {
      title: t("webRuntime.header.seconds"),
      value: "seconds",
    },
  ];

  const WITHDRAW_MOCK = [
    {
      account: "*****abbbccc1",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc2",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc3",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc4",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc5",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc6",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc7",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc8",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc9",
      amount: 1000,
      time: "13:17",
    },
    {
      account: "*****abbbccc10",
      amount: 1000,
      time: "13:17",
    },
  ];

  return (
    <div className={`${styles.container} running-time-container`}>
      <Grid
        columns={3}
        className={styles.header}
        style={{
          backgroundImage: `url('/images/runningTime/${PRODUCTION_NAME_DEV}/header_background.png')`,
        }}
      >
        {HEADER_STATISTICS.map((item) => {
          return (
            <HeaderItem
              key={item.value}
              title={item.title}
              icon={item.icon}
              content={statistics ? statistics[item.value] : "0"}
              isMoney={item.isMoney}
            />
          );
        })}
      </Grid>
      <Grid columns={4} gap={16} className={styles.statisticContainer}>
        {TIME_STATISTICS.map((item) => {
          return <TimerItem key={item.value} title={item.title} content={0} type={item.value} />;
        })}
      </Grid>
      <div className={styles.withdrawHeader}>{t("webRuntime.withdraw.title")}</div>
      <div className={styles.withdrawBody}>
        <div className={styles.withdrawContainer}>
          <Swiper
            direction={"vertical"}
            slidesPerView={8}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="custom-swipe"
          >
            {statistics
              ? [...statistics.Withdrawal_list].map((item) => {
                  return (
                    <SwiperSlide key={`${item.account}_${item.withdrawal_at}`}>
                      <WithdrawItem
                        account={item.account}
                        amount={item.withdrawal_amount}
                        time={item.withdrawal_at}
                      />
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </div>
        <Image
          src={require("./images/table_background.png")}
          alt="withdraw"
          className={styles.imageBackground}
        />
      </div>
    </div>
  );
};

export default RunningTime;
