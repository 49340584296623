import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GCODE } from "../../../../config";
import styles from "./style.module.scss";
import "./style.scss";

const LotteryNotification = ({
  showLotteryDrawer,
  setShowLotteryDrawer = () => {},
  isAnimate = true,
  firstAnimateTime,
  animationInterval,
}) => {
  const { t } = useTranslation();
  const [disableOnClick, setDisableOnClick] = useState(true);
  const interval = useRef(undefined);
  const firstTimeOut = useRef(undefined);
  const animateShow = () => {
    setDisableOnClick(false);
    const element = document.getElementById("notifContainer");
    element.style.opacity = 1;
    element?.classList.add("animate__slideInRight");
    element?.classList.remove("animate__slideOutRight");

    const marquee_element = document.getElementById("movingText");
    marquee_element.style.animation = `marquee ${
      (t("lotterypop.notification.marquee").length / 26) * 4
    }s linear infinite`;
  };
  const animateHide = () => {
    setDisableOnClick(true);
    const element = document.getElementById("notifContainer");
    element?.classList.add("animate__slideOutRight");
    element?.classList.remove("animate__slideInRight");
    setTimeout(() => {
      element.style.opacity = 0;

      const marquee_element = document.getElementById("movingText");
      marquee_element.style.animation = null;
    }, 700);
  };
  const animationFunc = () => {
    animateShow();
    setTimeout(() => {
      animateHide();
    }, 30000);
  };
  useEffect(() => {
    if (isAnimate) {
      firstTimeOut.current = setTimeout(() => {
        animateShow();
        setTimeout(() => {
          animateHide();
        }, 30000);
        interval.current = setInterval(animationFunc, animationInterval * 60000);
      }, firstAnimateTime);
    } else {
      clearInterval(interval.current);
      clearTimeout(firstTimeOut.current);
    }
  }, [isAnimate]);

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
      clearTimeout(firstTimeOut.current);
    };
  }, []);

  return (
    // <div className={`${styles.notifContainer} ${isAnimate === true ? styles.animate : ""}`}>
    <div className={`${styles.notifContainer} animate__animated`} id="notifContainer">
      <div
        className={styles.lotteryButtonContainer}
        onClick={() => {
          if (disableOnClick) return;
          setShowLotteryDrawer((prevState) => !prevState);
        }}
      >
        <img
          className={styles.lotteryButtonImg}
          src={require(`../GamePopup/images/chest.gif`)}
          alt={"lottery"}
        />
        <img
          className={styles.lotteryButtonImg}
          src={require(`../GamePopup/images/coin.gif`)}
          alt={"lottery"}
        />
      </div>
      <div className={styles.noticeContent}>
        <div className={styles.movingTextContainer}>
          <div className={styles.movingText} id="movingText">
            {t("lotterypop.notification.marquee")}
          </div>
        </div>
      </div>
      <div
        className={styles.betButton}
        id="lotteryNotificationClick"
        onClick={() => {
          if (!showLotteryDrawer) {
            if (GCODE !== "" && GCODE !== null && GCODE !== undefined) {
              window.dataLayer.push({
                event: "button-click",
                eventProps: {
                  value: "Lottery_Notification_Button",
                },
              });
            }
          }
          setShowLotteryDrawer((prevState) => !prevState);
        }}
      >
        <div className={styles.text}>{t("loterypop.notification.betnow")}</div>
        <img src={require(`../GamePopup/images/play.gif`)} alt={"lottery"} />
      </div>
    </div>
  );
};

export default LotteryNotification;
