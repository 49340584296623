import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toLogin } from "../../../../apis/utils";
import { Drawer } from "../../../../components";
import localStorageKey from "../../../../enumerations/localStorageKey";
import HomeFill from "../../../Home/components/DrawerTransfer/icons/HomeFill";
import styles from "./style.module.scss";
import "./style.scss";

const DrawerAgent = ({
  onClose = () => {},
  placement = "right",
  showDrawer = false,
  setShowDrawer,
  title = "",
  url = "",
}) => {
  const { t } = useTranslation();

  const renderDrawerContent = () => {
    return (
      <div className={styles.container} style={{ padding: 0, height: "100%" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          seamless="seamless"
          frameBorder={0}
          src={url}
          title={title}
        />
      </div>
    );
  };

  const handleMessageListen = (event) => {
    if (event.data === "invalid_token") {
      localStorage.removeItem(localStorageKey.TOKEN);
      localStorage.removeItem(localStorageKey.USER_INFO);
      setTimeout(() => {
        toLogin();
      }, 1000);
    }
  };

  useEffect(() => {
    if (showDrawer === true) {
      window.addEventListener("message", handleMessageListen);
    }
    return () => {
      window.removeEventListener("message", handleMessageListen);
    };
  }, [showDrawer]);

  return (
    <Drawer
      destroyOnClose={true}
      className={"custom-drawer"}
      placement={placement}
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      title={t("personal.affiliateCenter")}
      height={100}
      onClose={onClose}
      renderContent={renderDrawerContent}
      closeIcon={() => (
        <div style={{ display: "inline", marginLeft: "10px" }}>
          <HomeFill size={20} color={"var(--color-drawer-header-icon)"} />
        </div>
      )}
    />
  );
};

export default DrawerAgent;
