export const resultColorMapping = {
  R: "#D94036",
  G: "#5EAE3D",
  Y: "#E6B435",
  W: "#C9CACA",
  P: "#F565F4",
  B: "#31ADD7",
};

export const betResultColorMapping = {
  red: "#D94036",
  green: "#5EAE3D",
  yellow: "#E6B435",
  white: "#C9CACA",
  pink: "#F565F4",
  blue: "#31ADD7",
};
