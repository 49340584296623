import { requestSignIn } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetDailyCheck = (params) =>
  requestSignIn("get", `/${currency}/promotion/signin/setting`, params, true, true);

export const apiPostDailyCheck = (params) =>
  requestSignIn("post", `/${currency}/promotion/signin`, params, true, true);
