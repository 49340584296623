import { useLogin } from "../../../../context/LoginContext";
import { AccountContainer, LoginLink, Menu } from "./components";
import styles from "./styleModule";
const AccountArea = ({
  csLink,
  containerStyle = "",
  menuTextStyle = "",
  accountNameStyle = "",
  currencyStyle = "",
  moneyStyle = "",
  moneyFractionStyle = "",
  showImage = false,
  imageURL = undefined,
}) => {
  const { isLogin } = useLogin();
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={styles.leftContainer}>
        {isLogin ? (
          <AccountContainer
            userNameStyle={accountNameStyle}
            currencyStyle={currencyStyle}
            moneyStyle={moneyStyle}
            moneyFractionStyle={moneyFractionStyle}
            showImage={showImage}
            imageURL={imageURL}
          />
        ) : (
          <LoginLink />
        )}
      </div>
      <div className={styles.rightContainer}>
        <Menu textStyle={menuTextStyle} />
      </div>
    </div>
  );
};

export default AccountArea;
