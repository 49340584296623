import { Grid } from "antd-mobile";
import React from "react";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useRecentPlayGames } from "../../context/RecentPlayGamesContext";
import { useGetGameV2 } from "../../hooks/home1bbet.hooks";
import { GameItem } from "../GameListContainer/components";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
const VMHotList = () => {
  const { recentPlayList } = useRecentPlayGames();
  const { data: allGames } = useGetGameV2({
    enabled: !!CURRENCY,
  });

  const renderHotList = () => {
    let gameList = recentPlayList;
    if (allGames && allGames.hot_list) {
      gameList = [...gameList, ...allGames.hot_list];
    }
    return (
      <Grid columns={3} gap={12} className={`${styles.hotGames}`}>
        {gameList.map((hot) => {
          return (
            <Grid.Item>
              <GameItem
                key={hot.game_code}
                game={hot}
                gameNameStyle={styles.gameNameStyle}
                gameItemImageContainerStyle={styles.gameItemImageContainerStyle}
                gameItemPlatformStyle={styles.gameItemPlatformStyle}
                gameBadgeStyle={styles.gameBadgeStyle}
                gameRecentStyle={styles.gameRecentStyle}
                maintainImageStyle={styles.maintainImageStyle}
              />
            </Grid.Item>
          );
        })}
      </Grid>
    );
  };
  return <div className={styles.container}>{renderHotList()}</div>;
};

export default VMHotList;
