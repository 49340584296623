import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";

import "./style";

const CustomInput = ({
  placeholder = "",
  addonBefore = null,
  addonAfter = null,
  bordered = true,
  label = "",
  name = "",
  rules = [],
  disabled = false,
  readOnly = false,
  password = false,
  shouldUpdate,
  dependencies = "",
  initialValue,
  tooltip,
  onChange,
  hidden = false,
  iconRender = (visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />),
}) => {
  const renderInput = (password) => {
    switch (password) {
      case true:
        return (
          <Input.Password
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            bordered={bordered}
            iconRender={iconRender}
            dependencies={dependencies}
            onChange={onChange}
          />
        );
      default:
        return (
          <Input
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            bordered={bordered}
            onChange={onChange}
          />
        );
    }
  };
  return (
    <Form.Item
      style={{ display: hidden ? "none" : "block" }}
      className="input_container"
      label={label}
      name={name}
      rules={rules}
      shouldUpdate={shouldUpdate}
      initialValue={initialValue}
      tooltip={tooltip}
    >
      {renderInput(password)}
    </Form.Item>
  );
};

export default CustomInput;
