const disableLoginContextPathList = [
  "/lotbit",
  "/lotwin",
  "/lotgophers",
  "/lotlaunch",
  "/lotfederal",
  "/betRecord",
  "/bonusWheel",
  "/exchange",
  "/exchange/record",
  "/exchange/history",
  "/betRecord",
  "/bonus",
];

const disableUserInfoPage = [
  "/",
  "/signin",
  "/signup",
  "/emailVerify",
  "/forgotPassword",
  "/accessDenied",
  "/inMaintenance",
  "/appDownload",
  ...disableLoginContextPathList,
];

const allowedAppContextPathList = ["/", "/personal"];

export { allowedAppContextPathList, disableLoginContextPathList, disableUserInfoPage };
