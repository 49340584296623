import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANG } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import cn from "../locals/cn.json";
import en from "../locals/en.json";
import es from "../locals/es.json";
import id from "../locals/id.json";
import ind from "../locals/in.json"; //india: cannot use in as variable
import jp from "../locals/jp.json";
import kr from "../locals/kr.json";
import my from "../locals/my.json";
import pt from "../locals/pt.json";
import th from "../locals/th.json";
import vn from "../locals/vn.json";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: en },
  zh: { translation: cn },
  vi: { translation: vn },
  pt: { translation: pt },
  th: { translation: th },
  id: { translation: id },
  ms: { translation: my },
  jp: { translation: jp },
  ko: { translation: kr },
  es: { translation: es },
  in: { translation: ind },
};
const nowLangProcess = () => {
  const localLang = window.localStorage.getItem(localStorageKey.LANG_SETTING);
  if (localLang) {
    return localLang;
  } else {
    return DEFAULT_LANG;
  }
};
const nowLang = nowLangProcess();
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: nowLang,
    fallbackLng: nowLang,
    // lng: "en",
    // fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
