import { RightOutlined } from "@ant-design/icons";
import { Avatar, Button, Card } from "antd";
import React from "react";
import styles from "./style.module.scss";

import "./style.scss";

const MenuButton = ({
  onClick = () => {},
  title = "",
  content = "",
  disabled = false,
  icon = <Avatar size={36} />,
}) => {
  return (
    <div className="menu-button-container">
      <Button
        type={"text"}
        disabled={disabled}
        className={`${styles.container}`}
        onClick={onClick}
      >
        <div className={styles.avatarContainer}>
          {icon}
          <div className={styles.textContainer}>
            <div className={styles.title}>{title}</div>
            {content ? <div className={styles.content}>{content}</div> : null}
          </div>
        </div>
        <RightOutlined style={{ color: "var(--color-text-main)" }} />
      </Button>
    </div>
  );
};

export default MenuButton;
