import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import queryKey from "../../../../../enumerations/queryKey";
import {
  apiGetCurrentLottery,
  apiGetGameRecord,
  apiGetGameWayMethod,
  apiPostGameBet,
} from "../service";

export const usePostGameBet = () => {
  const cache = useQueryClient();
  return useMutation(({ params }) => apiPostGameBet(params), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetGameRecord = (params, options) => {
  return useQuery(
    [queryKey.LOTTERY_GAME_RECORD, params?.game_type],
    () =>
      apiGetGameRecord(params).then((res) => {
        return res.data?.data?.data?.slice(0, 1);
      }),
    {
      ...options,
    },
  );
};

export const useGetCurrentLottery = (params, options) => {
  return useQuery(
    [queryKey.CURRENT_LOTTERY, params?.game_type],
    () =>
      apiGetCurrentLottery(params).then((res) => {
        return res.data?.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameWay = (params, options) => {
  return useQuery(
    [queryKey.GAME_WAY, params?.game_way_type],
    () =>
      apiGetGameWayMethod(params).then((res) => {
        const game_way = res?.data?.data;
        const game_method = game_way?.game_method[0];
        const bet_option = game_method?.option[0];
        const odds = game_method?.odds;
        const highest_single_bet = bet_option?.highest_single_bet;
        const lowest_single_bet = bet_option?.lowest_single_bet;
        const max_payout = bet_option?.max_payout;

        return { odds, highest_single_bet, lowest_single_bet, max_payout };
      }),
    {
      ...options,
    },
  );
};
