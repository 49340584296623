import { useTranslation } from "react-i18next";
import { CustomFloatingBubble } from "../../../../../../components";
import CloseIcon from "../../../../icons/CloseIcon";
import TelegramIcon from "../../images/tg_icon.png";
import styles from "./style.module.scss";
import "./style.scss";

const TelegramBubble = ({
  apiShowBubble,
  initialBottomPosition,
  tgLink,
  setShowTelegramBubble,
  closeIconColor,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomFloatingBubble
        positionRight={"6px"}
        edgeDistance={"6px"}
        style={{
          "--initial-position-bottom": apiShowBubble?.status ? "206px" : initialBottomPosition,
          "--z-index": "999",
          "--background": "transparent",
          "--border-radius": 0,
        }}
        className={`${styles.tgBubble} telegram-bubble`}
      >
        <div className={styles.tgIconContainer}>
          <img
            src={TelegramIcon}
            alt={t("normal.tgChannel")}
            onClick={() => {
              window.open(tgLink?.data);
            }}
            className={styles.tgIcon}
          />
          <div
            className={styles.close}
            onClick={(e) => {
              e.stopPropagation();
              setShowTelegramBubble(false);
            }}
          >
            <CloseIcon color={closeIconColor} />
          </div>
        </div>
      </CustomFloatingBubble>
    </>
  );
};

export default TelegramBubble;
