const MoreIcon = ({ color1 = "#BFDBFC", color2 = "#4785F7" }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 29.6923C23.364 29.6923 29.3334 23.723 29.3334 16.359C29.3334 8.99498 23.364 3.02565 16 3.02565C8.63602 3.02565 2.66669 8.99498 2.66669 16.359C2.66669 23.723 8.63602 29.6923 16 29.6923Z"
        fill={color1}
      />
      <path
        d="M16 20.3724C15.7467 20.3724 15.4933 20.2791 15.2933 20.0791L10.5867 15.3724C10.4007 15.1842 10.2964 14.9303 10.2964 14.6657C10.2964 14.4012 10.4007 14.1473 10.5867 13.9591C10.9733 13.5724 11.6133 13.5724 12 13.9591L16 17.9591L20 13.9591C20.3867 13.5724 21.0267 13.5724 21.4133 13.9591C21.8 14.3457 21.8 14.9857 21.4133 15.3724L16.7067 20.0791C16.5067 20.2791 16.2533 20.3724 16 20.3724Z"
        fill={color2}
      />
    </svg>
  );
};

export default MoreIcon;
