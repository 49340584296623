import { useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomModal, toast } from "../components";
import { PRODUCTION_NAME_DEV } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import queryKey from "../enumerations/queryKey";
import { useFacebookPixel } from "../hooks/fbPixel/fbPixel.hooks";
import { GamePopup } from "../pages/Home/components";
import { useRecentPlayGames } from "../pages/Home/context/RecentPlayGamesContext";
import gameListsEnum from "../pages/Home/enumeration/gameListsEnum";
import { useRedirectToGame } from "../pages/Home/hooks/home.hook";
import {
  useGetAesexyGameLoginLink,
  useGetCQ9GameLoginLink,
  useGetEvoGameLoginLink,
  useGetEvoplayGameLoginLink,
  useGetFCFishGameLoginLink,
  useGetFCGameLoginLink,
  useGetJDBFishGameLoginLink,
  useGetJDBSlotGameLoginLink,
  useGetJiliGameLoginLink,
  useGetLuckySportGameLoginLink,
  useGetMgGameLoginLink,
  useGetPPLiveGameLoginLink,
  useGetPTGameLoginLink,
  useGetPgGameLoginLink,
  useGetPlayStarGameLoginLink,
  useGetPpGameLoginLink,
  useGetSpribeGameLoginLink,
  useGetSpribeoneGameLoginLink,
  useGetT1GameLoginLink,
  useGetTcgGameLoginLink,
  useGetViaCasinoLiveGameLoginLink,
  useGetWeblockchainGameLoginLink,
  useGetWefishGameLoginLink,
  useGetWeliveGameLoginLink,
  useGetWelotteryGameLoginLink,
  useGetWeslotGameLoginLink,
  useGetWesportGameLoginLink,
  useGetYbslotGameLoginLink,
  useGetYggGameLoginLink,
  useGetYoubetGameLoginLink,
} from "../pages/Home/hooks/home1bbet.hooks";
import { useLogin } from "./LoginContext";
import { useLoading } from "./modules/LoadingContext";

const GameLoginContext = createContext({
  showGamePopup: false,
  setShowGamePopup: () => {},
  handleLoginGame: ({ isPopup = false, isFromSearch = false, game3th = {}, gameItem = "" }) => {},
});

export const GameLoginContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const cache = useQueryClient();
  const navigate = useNavigate();
  const getLanguage = () => i18n.language;
  const { setIsLoading } = useLoading();
  const { setRecentPlayGameList } = useRecentPlayGames();
  const { userInfo } = useLogin();
  const nowLang = getLanguage();
  const currency = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
  const token = window.localStorage.getItem("token");

  const [mgGameCode, setMGGameCode] = useState(null);
  const [gameURL, setGameURL] = useState("");
  const [gameHTML, setGameHTML] = useState("");
  const [showHeader, setShowHeader] = useState(true);
  const [showBubble, setShowBubble] = useState(false);
  const [showGamePopup, setShowGamePopup] = useState(false);

  //saba
  const { mutate: redirectToGame } = useRedirectToGame();
  //T1
  const { mutate: redirectToT1 } = useGetT1GameLoginLink();
  //Evo Live
  const { mutate: redirectToEvo } = useGetEvoGameLoginLink();
  //PP slot
  const { mutate: redirectToPp } = useGetPpGameLoginLink();
  //PG
  const { mutate: redirectToPg } = useGetPgGameLoginLink();
  //YGG
  const { mutate: redirectToYgg } = useGetYggGameLoginLink();
  //EVOPLAY
  const { mutate: redirectToEvoplay } = useGetEvoplayGameLoginLink();
  //JILI
  const { mutate: redirectToJili } = useGetJiliGameLoginLink();
  //SPRIBE
  const { mutate: redirectToSpribe } = useGetSpribeGameLoginLink();
  //PT
  const { mutate: redirectToPT } = useGetPTGameLoginLink();
  //PPLIVE
  const { mutate: redirectToPPLive } = useGetPPLiveGameLoginLink();
  //FC
  const { mutate: redirectToFC } = useGetFCGameLoginLink();
  //CQ9
  const { mutate: redirectToCQ9 } = useGetCQ9GameLoginLink();
  //PLAY STAR
  const { mutate: redirectToPlaystar } = useGetPlayStarGameLoginLink();
  //WE LIVE
  const { mutate: redirectToWeLive } = useGetWeliveGameLoginLink();
  //WE LOTTERY
  const { mutate: redirectToWeLottery } = useGetWelotteryGameLoginLink();
  //WE BLOCKCHAIN
  const { mutate: redirectToWeBlockchain } = useGetWeblockchainGameLoginLink();
  //TCG
  const { mutate: redirectToTCG } = useGetTcgGameLoginLink();
  //YOUBET
  const { mutate: redirectToYoubet } = useGetYoubetGameLoginLink();
  //AESEXY
  const { mutate: redirectToAeSexy } = useGetAesexyGameLoginLink();
  //SPRIBEONE
  const { mutate: redirectToSpribeone } = useGetSpribeoneGameLoginLink();
  //WESPORT
  const { mutate: redirectToWesport } = useGetWesportGameLoginLink();
  //LUCKYSPORT
  const { mutate: redirectToLuckySport } = useGetLuckySportGameLoginLink();
  //YBSLOT
  const { mutate: redirectToYBSlot } = useGetYbslotGameLoginLink();
  //WE FISH
  const { mutate: redirectToWeFish } = useGetWefishGameLoginLink();
  //WE SLOT
  const { mutate: redirectToWeSlot } = useGetWeslotGameLoginLink();
  //FCFISH
  const { mutate: redirectToFCFish } = useGetFCFishGameLoginLink();
  //JDBFISH
  const { mutate: redirectToJDBFish } = useGetJDBFishGameLoginLink();
  //JDBSLOT
  const { mutate: redirectToJDBSlot } = useGetJDBSlotGameLoginLink();
  //VIACASINOLIVE
  const { mutate: redirectToViaCasinoLive } = useGetViaCasinoLiveGameLoginLink();
  //MG -> special using GET
  const { refetch: getMGLoginLink, isFetching: isFetchingMgLink } = useGetMgGameLoginLink(
    { content_code: mgGameCode },
    {
      enabled: false,
      onSuccess: (data) => {
        setIsLoading(false);
        setGameURL(data?.url);
        setShowHeader(false);
        setShowBubble(true);
        setShowGamePopup(true);
      },
      onError: (e) => {
        // Toast.show({ icon: "fail", content: e });
        setGameURL(undefined);
        setIsLoading(false);
      },
    },
  );

  const { isHavePixelIntegration, pixelCreateCustomTracker } = useFacebookPixel();

  const [showTestAccountWarning, setShowTestAccountWarning] = useState(false);

  const [showTutorial, setShowTutorial] = useState(false);
  const [showLotteryButton, setShowLotteryButton] = useState(false);
  const [title, setTitle] = useState("");
  const [game3thName, setGame3thName] = useState("");

  const getTutorialSetting = () => {
    const _localSettings = JSON.parse(localStorage.getItem(localStorageKey.LOCAL_SETTINGS));
    if (!_localSettings) return [];
    const _userLocalSettings = _localSettings[userInfo.account_id];
    if (!_userLocalSettings || !_userLocalSettings.skip_tutorial) return [];
    return _userLocalSettings.skip_tutorial.split(",");
  };

  const handleLoginGame = async ({ isPopup, isFromSearch, game3th, gameItem }) => {
    if (userInfo?.billing_type === 0) {
      if (game3th.allow_test_account === 0 || gameItem?.allow_test_account === 0) {
        setShowTestAccountWarning(true);
        return;
      }
    }

    if (gameItem) {
      setRecentPlayGameList({ game: { ...gameItem, is_recent: 1 } });
    }
    if (
      !getTutorialSetting().includes(game3th.game_3th_code) &&
      gameListsEnum[game3th.game_3th_code].haveTutorial === true
    ) {
      setGame3thName(game3th.game_3th_code);
      setShowTutorial(true);
    }

    if (gameListsEnum[game3th.game_3th_code].showLotteryButton === true) {
      setShowLotteryButton(true);
    }

    if (isHavePixelIntegration) {
      pixelCreateCustomTracker({
        eventId: "enterGame",
        props: {
          platform: game3th.game_3th_code,
          game_code: gameItem?.game_code ? gameItem?.game_code : "",
        },
      });
    }

    switch (game3th.game_3th_code) {
      case "t1": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game: game3th.game_3th_code,
          game_code: gameItem.game_code,
        };
        setIsLoading(true);
        await redirectToT1(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(`${success}`);
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "evo": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game: game3th.game_3th_code,
          game_code: gameItem?.game_code ? gameItem.game_code : "",
          game_id: gameItem?.game_id,
        };
        setIsLoading(true);
        await redirectToEvo(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pp": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
        };
        setIsLoading(true);
        await redirectToPp(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pg": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPg(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameHTML(success);
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "ygg": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToYgg(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "evoplay": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToEvoplay(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jili": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToJili(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jdb": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToSpribe(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pt": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPT(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "pplive": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPPLive(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "fc": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "FC",
          game_id: gameItem.game_id,
          platform: "H5",
        };
        setIsLoading(true);
        await redirectToFC(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "cq9": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "CQ9",
          game_id: gameItem.game_id,
          platform: "H5",
        };
        setIsLoading(true);
        await redirectToCQ9(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "playstar": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToPlaystar(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "welive": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeLive(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "weblockchain": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeBlockchain(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "welottery": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeLottery(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "tcg": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToTCG(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jilifish": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToJili(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "youbet": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToYoubet(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "aesexy": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToAeSexy(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "spribeone": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "spribeone",
          game_id: gameItem.game_id,
          platform: "H5",
        };
        setIsLoading(true);
        await redirectToSpribeone(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "wesport": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "SPORT-WEB_SOCCER",
          // game_code: gameItem.game_code,
          // game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWesport(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "luckysport": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "SPORT-WEB_SOCCER",
          // game_code: gameItem.game_code,
          // game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToLuckySport(
          { params: submitReq },
          {
            onSuccess: (success) => {
              // navigate("/luckysport#/", {
              //   state: {
              //     access_token: success.access_token,
              //   },
              // });
              // setGameHTML(success.widget_loader_script);
              setIsLoading(false);
              setGameURL(success.url);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "ybslot": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: "YBNGO_1_1038",
          game_id: gameItem.game_id,
          platform: "H5",
        };
        setIsLoading(true);
        await redirectToYBSlot(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success.gameUrl}` +
                  (success.gameUrl.includes("lang")
                    ? ""
                    : `${success.gameUrl.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "wefish": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeFish(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "weslot": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToWeSlot(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "fcfish": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToFCFish(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jdbfish": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToJDBFish(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "jdbslot": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToJDBSlot(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "viacasinolive": {
        const submitReq = {
          account_id: userInfo.account_id,
          account: userInfo.account,
          billing_type: userInfo.billing_type,
          game_code: gameItem.game_code,
          game_id: gameItem.game_id,
        };
        setIsLoading(true);
        await redirectToViaCasinoLive(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setGame3thName(game3th.game_3th_code);
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "saba": {
        const submitReq = {
          //1 pc 2 mobile
          platform: 2,
          game: game3th.game_3th_code,
        };
        setIsLoading(true);
        await redirectToGame(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              setShowTutorial(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "sabacricket": {
        const submitReq = {
          //1 pc 2 mobile
          platform: 2,
          game: game3th.game_3th_code,
          sport_id: 50,
        };
        setIsLoading(true);
        await redirectToGame(
          { params: submitReq },
          {
            onSuccess: (success) => {
              setGameURL(
                `${success}` +
                  (success.includes("lang")
                    ? ""
                    : `${success.includes("?") ? "&" : "?"}lang=${nowLang}`),
              );
              setIsLoading(false);
              setTitle(t(`games.fullName.${game3th.game_3th_code}`));
              setShowGamePopup(true);
            },
            onError: (error) => {
              setIsLoading(false);
              setShowTutorial(false);
              toast({ content: error, type: "error" });
            },
          },
        );
        break;
      }
      case "77lottery": {
        setGameURL(
          `${gameListsEnum[game3th.game_3th_code].gameRoutePrefix}/${
            gameItem?.game_code ? `lottery/${gameItem?.game_code}` : ""
          }?token=${token?.replace(/\"/gm, "")}&lang=${nowLang}&currency=${currency}`,
        );
        setTitle(
          t([
            `games.fullName.${PRODUCTION_NAME_DEV}.${game3th.game_3th_code}`,
            `games.fullName.${game3th.game_3th_code}`,
          ]),
        );
        setGame3thName(game3th.game_3th_code);
        setShowGamePopup(true);
        break;
      }
      case "winlottery": {
        setGame3thName(game3th.game_3th_code);
        navigate(
          `${
            gameListsEnum[game3th.game_3th_code].gameRoutePrefix ?? "/"
          }${gameItem.game_code?.toLowerCase()}?tab=${gameItem.game_id}`,
          {
            state: {
              isPopup: isPopup,
              isFromSearch: isFromSearch,
            },
          },
        );
        break;
      }
      case "mg": {
        setGame3thName(game3th.game_3th_code);
        setMGGameCode(gameItem.game_code);
        setIsLoading(true);
        break;
      }
      default:
        break;
    }
  };

  const safeTutorialSetting = (game3thName) => {
    const _localSettings = JSON.parse(localStorage.getItem(localStorageKey.LOCAL_SETTINGS)) || {};
    let _userLocalSettings = {};
    if (_localSettings) {
      _userLocalSettings = _localSettings[userInfo.account_id];
    }

    let newClosedTutorial = game3thName;
    if (!_userLocalSettings) {
      const userSetting = {
        ..._localSettings,
        [userInfo.account_id]: {
          ..._userLocalSettings,
          skip_tutorial: newClosedTutorial,
        },
      };
      localStorage.setItem(localStorageKey.LOCAL_SETTINGS, JSON.stringify(userSetting));
    } else {
      const currentClosedTutorial = _userLocalSettings?.skip_tutorial || "";
      newClosedTutorial = `${newClosedTutorial},${currentClosedTutorial}`;
      const newUserSetting = {
        ...[userInfo.account_id],
        skip_tutorial: newClosedTutorial,
      };
      localStorage.setItem(localStorageKey.LOCAL_SETTINGS, JSON.stringify(newUserSetting));
    }
  };

  useEffect(() => {
    if (mgGameCode) {
      getMGLoginLink();
    }
  }, [mgGameCode]);

  const resetState = () => {
    cache.invalidateQueries([queryKey.USER_WALLET]);
    setShowGamePopup(false);
    setGameURL("");
    setTitle("");
    setShowHeader(true);
    setShowBubble(false);
    setMGGameCode(null);
    setGame3thName("");
    setShowTutorial(false);
    setShowLotteryButton(false);
  };

  return (
    <GameLoginContext.Provider
      value={{
        handleLoginGame,
        showGamePopup,
        setShowGamePopup,
      }}
    >
      {children}
      <GamePopup
        title={title}
        visible={showGamePopup}
        onClose={resetState}
        showHeader={showHeader}
        showBubble={showBubble}
        showTutorial={showTutorial}
        setShowTutorial={setShowTutorial}
        gameURL={gameURL}
        gameHTML={gameHTML}
        game3thName={game3thName}
        closeTutorial={(isChecked, game3thName) => {
          setShowTutorial(false);
          if (isChecked === true) {
            safeTutorialSetting(game3thName);
          }
        }}
        showLotteryButton={showLotteryButton}
      />
      <CustomModal
        isModalVisible={showTestAccountWarning}
        setIsModalVisible={setShowTestAccountWarning}
        title={t([
          `${PRODUCTION_NAME_DEV}.popup.notAllowTest.desc`,
          "1bbet.popup.notAllowTest.desc",
        ])}
        content={""}
        needIcon={true}
        okBtnText={t("normal.confirm")}
        isHaveCancel={false}
      />
    </GameLoginContext.Provider>
  );
};
export const useGameLogin = () => {
  const GameLoginValue = useContext(GameLoginContext);
  return GameLoginValue;
};
