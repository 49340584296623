import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { APPLICATION_VERSION, ENV, OPEN_SENTRY, PRODUCTION_NAME_DEV, SENTRY_DSN } from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "antd/dist/antd.variable.min.css";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const currentTheme = require(`./theme/${PRODUCTION_NAME_DEV}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
const finalTheme = { ...defaultTheme, ...currentTheme };

ConfigProvider.config({
  theme: finalTheme,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //ms time until subsequent request will be triggered again
      staleTime: 3000,
      //when request failed, by default will retry 3x with exponential backoff delay before returning error
      //turn off this option on dev mode to make quicker dev experience
      retry: false,
      //option to refetch data when windows on focus to keep showing latest data to user
      //also turn this off on dev env for simplicity
      refetchOnWindowFocus: false,
    },
  },
});
if (OPEN_SENTRY === true) {
  Sentry.init({
    dsn: SENTRY_DSN,
    debug: ENV !== "production",
    environment: ENV,
    release: `${PRODUCTION_NAME_DEV}@${APPLICATION_VERSION}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation: useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes,
      }),
      // Sentry.replayIntegration({
      //   // Additional SDK configuration goes in here, for example:
      //   maskAllText: true,
      //   blockAllMedia: true,
      // }),
      // Sentry.httpClientIntegration({
      //   failedRequestStatusCodes: [400, [402, 599]],
      // }),
      // Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
    sendDefaultPii: true,
    maxBreadcrumbs: 50,
    tracesSampleRate: ENV === "production" ? 0.7 : 1.0,
    // replaysOnErrorSampleRate: ENV === "production" ? 0.7 : 1.0,
    // replaysSessionSampleRate: 0.1,
    tracePropagationTargets: ["localhost"],
    ignoreErrors: [
      /antd/gi,
      /is deprecated in StrictMode/gi,
      /React Intl/gi,
      /Invalid DOM property/gi,
      /Warning/gi,
      /findDOMNode/gi,
      /NOT_FOUND_TOKEN/gi,
      /Account or password error/gi,
    ],
    beforeSend: (event) => {
      const { breadcrumbs } = event;
      let breadcrumbsForInvalidToken = 0;
      if (breadcrumbs) {
        breadcrumbsForInvalidToken = breadcrumbs
          .map((breadcrumb) => breadcrumb.message)
          .indexOf("NOT_FOUND_TOKEN");
      }
      if (
        event?.message?.includes("antd") ||
        event?.message?.includes("is deprecated in StrictMode") ||
        event?.message?.includes("React Intl") ||
        event?.message?.includes("Invalid DOM property") ||
        event?.message?.includes("Warning") ||
        event?.message?.includes("findDOMNode") ||
        event?.message?.includes("NOT_FOUND_TOKEN") ||
        event?.message?.includes("Account or password error")
      ) {
        return null;
      }
      if (
        breadcrumbsForInvalidToken > 0 &&
        (event?.message?.includes("Get global user data error") ||
          event?.message?.includes("HTTP Client Error with status code: 400"))
      ) {
        return null;
      }
      if (ENV !== "production" || process.env.NODE_ENV !== "production") return null;
      return event;
    },
  });
  Sentry.setTag("platform", PRODUCTION_NAME_DEV);
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <App />
      </ConfigProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
