const winBetCategories = [
  {
    game_category_type: "LOT",
    name: "win.wingame.gameCategory.title.LOTWIN5",
    game_type: "LOTWIN5",
    game_category_detail_type: "LOTWIN",
    currency: "INR",
    game_way_type: "LOTWIN5GO",
    key: "LOTWIN5",
    translate: true,
  },
  {
    game_category_type: "LOT",
    name: "win.wingame.gameCategory.title.LOTWIN3",
    game_type: "LOTWIN3",
    game_category_detail_type: "LOTWIN",
    currency: "INR",
    game_way_type: "LOTWIN3GO",
    key: "LOTWIN3",
    translate: true,
  },
  {
    game_category_type: "LOT",
    name: "win.wingame.gameCategory.title.LOTWIN2",
    game_type: "LOTWIN2",
    game_category_detail_type: "LOTWIN",
    currency: "INR",
    game_way_type: "LOTWIN2GO",
    key: "LOTWIN2",
    translate: true,
  },
  {
    game_category_type: "LOT",
    name: "win.wingame.gameCategory.title.LOTWIN1",
    game_type: "LOTWIN1",
    game_category_detail_type: "LOTWIN",
    currency: "INR",
    game_way_type: "LOTWIN1GO",
    key: "LOTWIN1",
    translate: true,
  },
];
const winBetGameWayMethods = {
  inr188: {
    LOTWIN1GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN1",
      game_way_type: "LOTWIN1GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
    LOTWIN2GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN2",
      game_way_type: "LOTWIN2GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
    LOTWIN3GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN3",
      game_way_type: "LOTWIN3GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
    LOTWIN5GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN5",
      game_way_type: "LOTWIN5GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "9",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0",
              highest_single_bet: "100000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
  },
  k7: {
    LOTWIN1GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN1",
      game_way_type: "LOTWIN1GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
    LOTWIN2GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN2",
      game_way_type: "LOTWIN2GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "1.96",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "9999999999",
              lowest_single_bet: "0.98",
              highest_single_bet: "999999999",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
    LOTWIN3GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN3",
      game_way_type: "LOTWIN3GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "1.96",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "10000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
    LOTWIN5GO: {
      game_way_name: "贏彩",
      game_type: "LOTWIN5",
      game_way_type: "LOTWIN5GO",
      game_method: [
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "NUM",
          option: [
            {
              id: 1,
              key: "0",
              name: "0",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 2,
              key: "1",
              name: "1",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 3,
              key: "2",
              name: "2",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 4,
              key: "3",
              name: "3",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 5,
              key: "4",
              name: "4",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 6,
              key: "5",
              name: "5",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 7,
              key: "6",
              name: "6",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 8,
              key: "7",
              name: "7",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 9,
              key: "8",
              name: "8",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 10,
              key: "9",
              name: "9",
              odds: "1.96",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
          ],
          group: null,
          status: 1,
        },
        {
          name: "數字顏色",
          odds: "0",
          odds_type: 1,
          code: "COLOR",
          option: [
            {
              id: 1,
              key: "green",
              name: "GREEN",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 2,
              key: "violet",
              name: "VIOLET",
              odds: "4.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
            {
              id: 3,
              key: "red",
              name: "RED",
              odds: "1.96,1.5",
              status: 1,
              max_payout: "1000000000",
              lowest_single_bet: "0.98",
              highest_single_bet: "1000000000",
            },
          ],
          group: "NUMCOLOR",
          status: 1,
        },
      ],
      fee: "0.02",
    },
  },
};

export { winBetCategories, winBetGameWayMethods };
