import { Grid } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CSIcon, FinancialRecordIcon, WithdrawalIcon } from "../../../../pages/Personal/images";
import styles from "./styleModule";

import { useLogin } from "../../../../context/LoginContext";

const PersonalMenu = () => {
  const { t } = useTranslation();
  const { openCSDrawer } = useLogin();
  const navigate = useNavigate();
  const menuItems = [
    {
      title: t("normal.withdraw"),
      onClick: () => {
        navigate("/exchange?tab=withdraw");
      },
      icon: <img className={styles.icon} src={WithdrawalIcon} alt={t("normal.withdraw")} />,
    },
    {
      title: t("transaction.record.title"),
      onClick: () => {
        navigate("/exchange/history");
      },
      icon: (
        <img
          className={styles.icon}
          src={FinancialRecordIcon}
          alt={t("transaction.record.title")}
        />
      ),
    },
    {
      title: t("personal.cs"),
      onClick: () => {
        openCSDrawer();
      },
      icon: <img className={styles.icon} src={CSIcon} alt={t("personal.cs")} />,
    },
  ];

  return (
    <div className={styles.menuContainer}>
      <Grid columns={3}>
        {menuItems.map((item) => {
          return (
            <Grid.Item key={item.title}>
              <div className={styles.itemContainer} onClick={item.onClick}>
                {item.icon}
                <div className={styles.menuTitle}>
                  <div className={styles.title}>{item.title}</div>
                </div>
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
    </div>
  );
};

export default PersonalMenu;
