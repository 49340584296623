import { request, request77 } from "../../../../apis";
import localStorageKey from "../../../../enumerations/localStorageKey";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiPostGameBet = (params) => request77("post", `/${CURRENCY}/game/bet`, params, true);

export const apiGetGameRecord = (params) =>
  request("get", `/${CURRENCY}/game/lottery/record`, params, true);

export const apiGetCurrentLottery = (params) =>
  request("get", `/${CURRENCY}/game/lottery/now`, params, true);

export const apiGetGameWayMethod = (params) =>
  request77("get", `/${CURRENCY}/game/way/method/${params.game_way_type}`, params, true, false);
