import { Skeleton } from "antd";
import React from "react";

const ListSkeleton = ({ containerStyle = {}, length = 4 }) => {
  const placeholder = Array.from("x".repeat(length));
  return (
    <div style={containerStyle}>
      {placeholder.map((item, index) => {
        return <Skeleton active={true} key={index} />;
      })}
    </div>
  );
};

export default ListSkeleton;
