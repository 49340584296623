import React, { useContext, useEffect, useState } from "react";
import useLocalStorage from "../../useLocalStorage";
import AuthInfo from "./AuthInfo";
// import { apiGetUserInfo } from "../../../apis/api";
import * as Sentry from "@sentry/react";
import { LangContext } from "../../index";
import { apiGetUserInfo, apiGetUserWalletData } from "./service";
// import massageHandler from "../../../components/basicUi/message";

function Context(props) {
  const { lang } = useContext(LangContext);
  const [{ userInfo }, setUser] = useLocalStorage("meta188", {
    userInfo: undefined,
  });
  const [token, setToken] = useLocalStorage("token");
  const [userWallet, setUserWallet] = useState();
  const SET_USER = (obj) => {
    setUser({
      userInfo: { user: obj },
    });
  };
  const SET_TOKEN = (token) => {
    setToken(token);
  };
  const UPDATE_USER_INFO = async () => {
    try {
      const success = await apiGetUserInfo();
      if (success) {
        setUser({
          userInfo: {
            user: success.data.data,
          },
        });
      }
    } catch (err) {}
  };
  const UPDATE_USER_WALLET = async () => {
    try {
      const success = await apiGetUserWalletData();
      if (success) {
        setUserWallet(success.data.data);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (userInfo && userInfo.user) {
      Sentry.setUser({
        id: userInfo.user.account_id.toString(),
        username: userInfo.user.account.toString(),
      });
    } else {
      Sentry.setUser(null);
    }
  }, [userInfo]);

  const contextValue = {
    ...userInfo,
    userWallet,
    token,
    SET_USER,
    SET_TOKEN,
    UPDATE_USER_INFO,
    UPDATE_USER_WALLET,
  };

  return <AuthInfo.Provider value={contextValue}>{props.children}</AuthInfo.Provider>;
}

export default Context;
