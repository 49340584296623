const CopyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6667 3.16732H6.66667V1.83398H12.6667C13.403 1.83398 14 2.43094 14 3.16732V10.5007H12.6667V3.16732ZM2 5.83398C2 5.0976 2.59695 4.50065 3.33333 4.50065H10C10.735 4.50065 11.3333 5.09513 11.3333 5.83291V13.8368C11.3333 14.5758 10.7347 15.1673 10.0009 15.1673H3.33184C2.59351 15.1673 2 14.5684 2 13.834V5.83398ZM10 5.83398H3.33333V13.834H10V5.83398Z"
        fill="var(--color-text-main)"
      />
    </svg>
  );
};

export default CopyIcon;
