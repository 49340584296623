import { Image } from "antd";
import React from "react";
import noData from "./images/noData.png";
import styles from "./styleModule";

const NoData = ({
  title = "",
  height = "auto",
  padding = undefined,
  containerStyle,
  titleStyle,
}) => {
  return (
    <div
      className={`${styles.container} ${containerStyle}`}
      style={{ height: height, padding: padding }}
    >
      <Image width={200} height={200} src={noData} preview={false} />
      <div className={`${styles.title} ${titleStyle}`}>{title}</div>
    </div>
  );
};

export default NoData;
