import { Image, Input } from "antd-mobile";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PRODUCTION_NAME_DEV } from "../../config";
import styles from "./styleModule";

const CustomSearchBar = ({
  setSearchValue = () => {},
  iconPosition = "right",
  customSearchStyle = "",
  customContainerStyle = "",
  currentCategoryCode = "",
}) => {
  const { t } = useTranslation();
  const [localSearchValue, setLocalSearchValue] = useState("");

  const handleSearch = useCallback(
    _.debounce(
      (searchValue) => {
        setSearchValue(searchValue);
      },
      [1000],
      { leading: false },
    ),
    [],
  );

  useEffect(() => {
    return () => {
      setLocalSearchValue("");
    };
  }, [currentCategoryCode]);

  const onSearchValueChange = (val) => {
    setLocalSearchValue(val);
    handleSearch(val);
  };

  return (
    <div className={`${styles.container} ${customContainerStyle}`}>
      <div className={`${styles.searchContainer} ${customSearchStyle}`}>
        {iconPosition === "left" ? (
          <Image
            className={styles.searchIcon}
            src={`/images/logo/${PRODUCTION_NAME_DEV}/SearchIcon.png`}
          />
        ) : null}
        <Input
          className={styles.searchInput}
          placeholder={t("home.search.placeholder")}
          type={"search"}
          value={localSearchValue}
          onChange={onSearchValueChange}
        />
        {iconPosition === "right" ? (
          <Image
            className={styles.searchIcon}
            src={`/images/logo/${PRODUCTION_NAME_DEV}/SearchIcon.png`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CustomSearchBar;
