const SearchIcon = ({ color1 = "#BFDBFC", color2 = "#4785F7" }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.68 27.0523C17.8662 27.0523 20.9218 25.7866 23.1747 23.5337C25.4277 21.2808 26.6934 18.2251 26.6934 15.039C26.6934 11.8528 25.4277 8.79721 23.1747 6.54427C20.9218 4.29134 17.8662 3.02565 14.68 3.02565C11.4939 3.02565 8.43825 4.29134 6.18531 6.54427C3.93237 8.79721 2.66669 11.8528 2.66669 15.039C2.66669 18.2251 3.93237 21.2808 6.18531 23.5337C8.43825 25.7866 11.4939 27.0523 14.68 27.0523Z"
        fill={color1}
      />
      <path
        d="M17.4835 17.4325C16.787 18.129 15.8422 18.5203 14.8571 18.5203C13.872 18.5203 12.9273 18.129 12.2307 17.4325C11.5342 16.7359 11.1428 15.7912 11.1428 14.8061C11.1428 13.821 11.5342 12.8762 12.2307 12.1797C12.9273 11.4831 13.872 11.0918 14.8571 11.0918C15.8422 11.0918 16.787 11.4831 17.4835 12.1797C18.1801 12.8762 18.5714 13.821 18.5714 14.8061C18.5714 15.7912 18.1801 16.7359 17.4835 17.4325Z"
        stroke={color2}
        stroke-width="1.71429"
      />
      <path
        d="M29.32 25.6258C28.88 24.8124 27.9467 24.3591 26.6933 24.3591C25.7467 24.3591 24.9333 24.7458 24.4533 25.4124C23.9733 26.0791 23.8667 26.9724 24.16 27.8658C24.7333 29.5991 25.7333 29.9858 26.28 30.0524C26.36 30.0658 26.44 30.0658 26.5333 30.0658C27.12 30.0658 28.0267 29.8124 28.9067 28.4924C29.6133 27.4658 29.7467 26.4391 29.32 25.6258Z"
        fill={color2}
      />
    </svg>
  );
};

export default SearchIcon;
