import React from "react";
import styles from "./style.module.scss";
import { LangSelectorBtn } from "../../../../components";
import { PRODUCTION_NAME_DEV } from "../../../../config";
function Header() {
  return (
    <div className={`${styles.header}`}>
      <div className={`${styles.logaArea}`}>
        <img
          style={{ width: 120, marginLeft: 16 }}
          src={`/images/logo/${PRODUCTION_NAME_DEV}/logo.png`}
          alt="logo"
        />
      </div>
      <div className={`${styles.langArea}`} style={{ padding: "0px 16px" }}>
        <LangSelectorBtn />
      </div>
    </div>
  );
}

export default Header;
