import React, { useState } from "react";
import GameInfo from "./GameInfo";
import { apiGetBreakevenGameList } from "./service";

function Context(props) {
  const [breakEvenGameList, setBreakEvenGameList] = useState();
  const GET_BREAKEVEN_GAMES = async () => {
    try {
      const success = await apiGetBreakevenGameList();
      if (success) {
        // console.log(success.data.data);
        setBreakEvenGameList(success.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const contextValue = {
    breakEvenGameList,
    GET_BREAKEVEN_GAMES,
  };

  return (
    <GameInfo.Provider value={contextValue}>{props.children}</GameInfo.Provider>
  );
}

export default Context;
