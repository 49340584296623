import { Grid } from "antd-mobile";
import styles from "./style.module.scss";

const TimerItem = ({ title = "", content = "0", type = "" }) => {
  return (
    <Grid.Item className={styles.itemContainer}>
      <div className={styles.content} id={type}>
        {content}
      </div>
      <div className={styles.title}>{title}</div>
    </Grid.Item>
  );
};

export default TimerItem;
