import { DOUBLE7_LOTTERY_SHOW_POPUP, DOUBLE_SEVEN_MOBILE_URL } from "../../../config";
const gameListsEnum = {
  winlottery: {
    column: 3,
    isFrame: false,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    gameItemOnClick: "navigateToRoute",
    gameRoutePrefix: "/",
    frameUrl: "",
    imgPathPrefix: "/images/win/games/",
    game_list: [
      {
        code: "LOTLAUNCH",
        name: "极速火箭",
        image: "/images/game/selfGames/LOTLAUNCH.png",
      },
      {
        code: "LOTFEDERAL",
        name: "LOTFEDERAL",
        image: "/images/game/selfGames/LOTFEDERAL.png",
      },
      {
        code: "LOTGOPHERS",
        name: "GOPHERS",
        image: "/images/game/selfGames/LOTGOPHERS.png",
      },
    ],
  },
  mg: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: false,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    //gameItemOnClick type: openGameInPopup
    //need to set api in homePage
    gameItemOnClick: "openGameInPopup",
    frameUrl: "https://gamemgslotstaging.meta188.cc",
    game_list: [
      {
        code: "SMG_queenofAlexandria",
        name: "Queen of Alexandria",
        image: "/images/game/mg/SMG_queenofAlexandria.png",
      },
      {
        code: "SMG_amazingLinkZeus",
        name: "Amazing Link Zeus",
        image: "/images/game/mg/SMG_amazingLinkZeus.png",
      },
      {
        code: "SMG_luckyClucks",
        name: "Lucky Clucks",
        image: "/images/game/mg/SMG_luckyClucks.png",
      },
      // {
      //   code: "SMG_777superBigBuildUpDeluxe",
      //   name: "777 Super Big Buildup Deluxe",
      //   image: "/images/game/mg/SMG_777superBigBuildUpDeluxe.png",
      // },
      // {
      //   code: "SMG_amazingLinkApollo",
      //   name: "Amazing Link Apollo",
      //   image: "/images/game/mg/SMG_amazingLinkApollo.png",
      // },
      // {
      //   code: "SMG_blazingMammoth",
      //   name: "Blazing Mammoth",
      //   image: "/images/game/mg/SMG_blazingMammoth.png",
      // },
      // {
      //   code: "SMG_cashNRichesMegaways",
      //   name: "Cash Riches Megaways",
      //   image: "/images/game/mg/SMG_cashNRichesMegaways.png",
      // },
      // {
      //   code: "SMG_cashNRichesWOWPOT",
      //   name: "Cash Riches WOW POT",
      //   image: "/images/game/mg/SMG_cashNRichesWOWPOT.png",
      // },
      // {
      //   code: "SMG_hyperGold",
      //   name: "Hyper Gold",
      //   image: "/images/game/mg/SMG_hyperGold.png",
      // },
      // {
      //   code: "SMG_maskOfAmun",
      //   name: "Mask of Anum",
      //   image: "/images/game/mg/SMG_maskOfAmun.png",
      // },
      // {
      //   code: "SMG_squealinRiches",
      //   name: "Squelin Riches",
      //   image: "/images/game/mg/SMG_squealinRiches.png",
      // },
      // {
      //   code: "SMG_thunderstruck",
      //   name: "Thunder Struck",
      //   image: "/images/game/mg/SMG_thunderstruck.png",
      // },
    ],
  },
  "77lottery": {
    column: 3,
    isFrame: true,
    moreGameIsPopup: false,
    //USE IN V2
    moreGameIsPopupV2: DOUBLE7_LOTTERY_SHOW_POPUP,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameCustomLink",
    gameRoutePrefix: DOUBLE_SEVEN_MOBILE_URL,
    frameUrl: "https://game77lotterystaging.meta188.cc?currency=MYR&lang=en",
    game_list: [
      {
        code: "LOTEC1",
        name: "LOTEC1",
        image: "/images/game/77lottery/LOTEC1DWD.png",
      },
      {
        code: "LOTEC3",
        name: "LOTEC3",
        image: "/images/game/77lottery/LOTEC3DWD.png",
      },
      {
        code: "LOTEC5",
        name: "LOTEC5",
        image: "/images/game/77lottery/LOTEC5DWD.png",
      },
    ],
  },
  saba: {
    column: 1,
    isFrame: true,
    moreGameIsPopup: false,
    //USE IN V2
    moreGameIsPopupV2: false,
    haveTutorial: true,
    showLotteryButton: false,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    frameUrl: "",
    game_list: [
      {
        code: "saba",
        name: "saba",
        image: "/images/game/saba/saba.png",
      },
    ],
  },
  sabacricket: {
    column: 1,
    isFrame: true,
    moreGameIsPopup: false,
    //USE IN V2
    moreGameIsPopupV2: false,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    frameUrl: "",
    game_list: [
      {
        code: "saba",
        name: "saba",
        image: "/images/game/saba/saba.png",
      },
    ],
  },
  t1: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [
      {
        code: "crash",
        name: "crash",
        image: "/images/game/t1/crash.png",
      },
      {
        code: "dice",
        name: "dice",
        image: "/images/game/t1/dice.png",
      },
      {
        code: "mine",
        name: "mine",
        image: "/images/game/t1/mine.png",
      },
      {
        code: "double",
        name: "double",
        image: "/images/game/t1/double.png",
      },
      {
        code: "hilo",
        name: "hilo",
        image: "/images/game/t1/hilo.png",
      },
      {
        code: "limbo",
        name: "limbo",
        image: "/images/game/t1/limbo.png",
      },
      {
        code: "plinko",
        name: "plinko",
        image: "/images/game/t1/plinko.png",
      },
      {
        code: "tower",
        name: "tower",
        image: "/images/game/t1/tower.png",
      },
      {
        code: "triple",
        name: "triple",
        image: "/images/game/t1/triple.png",
      },
      {
        code: "crypto",
        name: "crypto",
        image: "/images/game/t1/crypto.png",
      },
      {
        code: "keno",
        name: "keno",
        image: "/images/game/t1/keno.png",
      },
      {
        code: "coin",
        name: "coin",
        image: "/images/game/t1/coin.png",
      },
    ],
  },
  evo: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: false,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    //gameItemOnClick type: openIFrameApiLink
    gameItemOnClick: "openIFrameApiLink",
    game_list: [
      {
        id: "CrazyTime0000001",
        code: "crazytime",
        name: "Crazy Time",
        image: "/images/game/evo/Crazy_Time.png",
      },
      {
        id: "MOWDream00000001",
        code: "moneywheel",
        name: "Dream Catcher",
        image: "/images/game/evo/Dream_Catcher.png",
      },
      {
        id: "vctlz20yfnmp1ylr",
        code: "roulette",
        name: "Roulette",
        image: "/images/game/evo/Roulette.png",
      },
      {
        id: "rng-topcard00001",
        code: "rng-topcard",
        name: "Top Card",
        image: "/images/game/evo/First_Person_Top_Card_DNT.png",
      },
      {
        id: "MegaBall00000001",
        code: "megaball",
        name: "Mega Ball",
        image: "/images/game/evo/Mega_Ball.png",
      },
      {
        id: "Monopoly00000001",
        code: "monopoly",
        name: "Monopoly Live",
        image: "/images/game/evo/Monopoly_Live.png",
      },
      {
        id: "CrazyCoinFlip001",
        code: "crazycoinflip",
        name: "Crazy Coin Flip",
        image: "/images/game/evo/Crazy_Coin_Flip.png",
      },
      {
        id: "MonBigBaller0001",
        code: "monopolybigballer",
        name: "MONOPOLY Big Baller DNT",
        image: "/images/game/evo/MONOPOLY_Big_Baller_DNT.png",
      },
      {
        id: "AmericanTable001",
        code: "americanroulette",
        name: "American Roulette",
        image: "/images/game/evo/American_Roulette.png",
      },
      {
        id: "o7347okwaeasvy2y",
        code: "blackjack",
        name: "Blackjack VIP 1",
        image: "/images/game/evo/Blackjack_VIP_1.png",
      },
      {
        id: "InstantRo0000001",
        code: "instantroulette",
        name: "Instant Roulette",
        image: "/images/game/evo/Instant_Roulette.png",
      },
      {
        id: "BacBo00000000001",
        code: "bacbo",
        name: "Bac Bo",
        image: "/images/game/evo/Bac_Bo.png",
      },
    ],
  },
  pp: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [
      {
        code: "vs20olympgate",
        name: "Gates of Olympus",
        image: "https://api.prerelease-env.biz/game_pic/square/200/vs20olympgate.png",
      },
      {
        code: "vs20fruitsw",
        name: "Sweet Bonanza",
        image: "https://api.prerelease-env.biz/game_pic/square/200/vs20fruitsw.png",
      },
      {
        code: "vs20sugarrush",
        name: "Sugar Rush",
        image: "https://api.prerelease-env.biz/game_pic/square/200/vs20sugarrush.png",
      },
    ],
  },
  pg: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [
      {
        game_id: 42,
        game_code: "ganesha-gold",
        name: "Ganesha Gold",
        image: "https://meta-game-share.s3.ap-east-1.amazonaws.com/pg/icon/ganesha-gold.png",
      },
      {
        game_id: 126,
        game_code: "fortune-tiger",
        name: "Fortune Tiger",
        image: "https://meta-game-share.s3.ap-east-1.amazonaws.com/pg/icon/fortune-tiger.png",
      },
      {
        game_id: 53,
        game_code: "the-great-icescape",
        name: "The Great Icescape",
        image: "https://meta-game-share.s3.ap-east-1.amazonaws.com/pg/icon/the-great-icescape.png",
      },
    ],
  },
  ygg: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [
      {
        game_id: 55,
        game_code: "880102",
        name: "Raptor Doublemax",
        image: "https://meta-game-share.s3.ap-east-1.amazonaws.com/ygg/icon/880102.png",
      },
      {
        game_id: 86,
        game_code: "880143",
        name: "Vallet of the Gods",
        image: "https://meta-game-share.s3.ap-east-1.amazonaws.com/ygg/icon/880143.png",
      },
      {
        game_id: 95,
        game_code: "880153",
        name: "Lucky Neko",
        image: "https://meta-game-share.s3.ap-east-1.amazonaws.com/ygg/icon/880153.png",
      },
    ],
  },
  evoplay: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },

  jili: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  jdb: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },

  pt: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },

  pplive: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: false,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  fc: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  cq9: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  playstar: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  welive: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  weblockchain: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  welottery: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  tcg: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  jilifish: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    //USE IN V2
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    //END OF USE IN V2
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  youbet: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  aesexy: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  spribeone: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  wesport: {
    column: 1,
    isFrame: true,
    moreGameIsPopup: false,
    moreGameIsPopupV2: false,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  luckysport: {
    column: 1,
    isFrame: true,
    moreGameIsPopup: false,
    moreGameIsPopupV2: false,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  ybslot: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  wefish: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  weslot: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  fcfish: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  jdbfish: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  jdbslot: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
  viacasinolive: {
    column: 3,
    isFrame: true,
    moreGameIsPopup: true,
    moreGameIsPopupV2: true,
    haveTutorial: false,
    showLotteryButton: true,
    gameItemOnClick: "openIFrameApiLink",
    game_list: [],
  },
};

export default gameListsEnum;
