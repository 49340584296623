import { PopoverMenu } from "../../../../../../../../components";
import styles from "./styleModule";
const MenuItem = ({ buttonSetting = {}, textStyle = "" }) => {
  switch (buttonSetting.type) {
    case "url":
      return (
        <div className={styles.menuItem} onClick={buttonSetting.onClick}>
          <div className={styles.image}>{buttonSetting.icon}</div>
          <div className={`${styles.menuText} ${textStyle}`}>{buttonSetting.name}</div>
        </div>
      );
    case "popover":
      return (
        <PopoverMenu
          actions={buttonSetting.popoverMenu.filter((_menu) => !_menu.hideItem)}
          placement={"bottom-end"}
          mode={"dark"}
          trigger={"click"}
          destroyOnHide={true}
          setShowPopover={buttonSetting.setShowPopover}
        >
          <div className={styles.menuItem}>
            <div
              className={`${styles.image} ${
                buttonSetting.showPopover && buttonSetting.key === "more" ? styles.open : ""
              }`}
            >
              {buttonSetting.icon}
            </div>
            <div className={`${styles.menuText} ${textStyle}`}>{buttonSetting.name}</div>
          </div>
        </PopoverMenu>
      );
    default:
      break;
  }
};

export default MenuItem;
