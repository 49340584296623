import { useMutation } from "@tanstack/react-query";
import { apiPutDepositBankReceipt, apiPutDepositVirtualReceipt } from "../service";

export const usePutDepositVirtualReceipt = () => {
  return (
    useMutation(({params})=> apiPutDepositVirtualReceipt({params}).then((res)=> {
      return res.data.data;
    }),{
      onError: (error) => {
        console.debug(JSON.stringify("ERR",error))
      }
    })
  )
}

export const usePutDepositBankReceipt = () => {
  return (
    useMutation(({params})=> apiPutDepositBankReceipt({params}).then((res)=> {
      return res.data.data;
    }),{
      onError: (error) => {
        console.debug(JSON.stringify("ERR",error))
      }
    })
  )
}
