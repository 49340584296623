import styles from "./style.module.scss";
const VIPIcon = ({ vipLevel = "VIP0", imageStyle = "" }) => {
  return (
    <img
      className={`${styles.image} ${imageStyle}`}
      src={`/images/vip/secondVersion/smallIcon/${vipLevel}_S.png`}
      alt={vipLevel}
    />
  );
};
export default VIPIcon;
