const HomeIcon = ({ color1 = "#F4BFAE", color2 = "#EFA78C" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.705 8.01002L15.155 2.77002C13.875 1.75002 11.875 1.74002 10.605 2.76002L4.05499 8.01002C3.11499 8.76002 2.54499 10.26 2.74499 11.44L4.00499 18.98C4.29499 20.67 5.86499 22 7.57499 22H18.175C19.865 22 21.465 20.64 21.755 18.97L23.015 11.43C23.195 10.26 22.625 8.76002 21.705 8.01002ZM13.625 18C13.625 18.41 13.285 18.75 12.875 18.75C12.465 18.75 12.125 18.41 12.125 18V15C12.125 14.59 12.465 14.25 12.875 14.25C13.285 14.25 13.625 14.59 13.625 15V18Z"
        fill="url(#paint0_linear_4247_26261)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4247_26261"
          x1="12.8766"
          y1="2"
          x2="12.8766"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color1} />
          <stop offset="1" stop-color={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HomeIcon;
