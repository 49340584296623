import { CaretDownOutlined } from "@ant-design/icons";
import React from "react";
import styles from "./styleModule";

function FilterConditionGroup({
  searchCondition,
  nowCondition,
  setNowCondition,
  itemOnClick = () => {},
  textRender = () => {},
}) {
  return (
    <div className={`${styles.filterConditionGroup}`}>
      {searchCondition &&
        searchCondition.map((item) => {
          return (
            <div
              className={`${styles.searchCondition}`}
              key={item.id}
              onClick={() => {
                if (item.disabled) return;
                setNowCondition(item.id);
                itemOnClick(item);
              }}
            >
              <span
                className={`${styles.conditionText}`}
                style={{ fontWeight: item.id == nowCondition ? "600" : "" }}
              >
                {textRender(item)}
              </span>
              <CaretDownOutlined
                style={{
                  color:
                    item.id == nowCondition
                      ? "var(--color-text-main)"
                      : "var(--color-radio-inactive)",
                  transition: "transform 0.3s ease",
                }}
                rotate={item.id == nowCondition ? 180 : 0}
              />
            </div>
          );
        })}
    </div>
  );
}

export default FilterConditionGroup;
