import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CaretDownOutlined } from "@ant-design/icons";
import LangSelector from "../LangSelector";
import langDict from "../LangSelector/langDict.json";
import styles from "./style.module.scss";

function LangSelectorPopover({
  changeStyle,
  customContainerStyle = "",
  titleStyle = "",
  showArrow = false,
  showTitle = false,
  showIcon = true,
}) {
  const [showPopover, setShowPopover] = useState(false);
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language;
  const nowLang = getLanguage();

  const getTitle = () => {
    const found = langDict.find((i) => i.value === nowLang);
    if (found) return found.label;
    return "";
  };

  return (
    <LangSelector visible={showPopover} type="popover" setShowPopover={setShowPopover}>
      <div
        className={`${styles.langSelectorBtn} ${
          showPopover ? styles.active : ""
        } ${customContainerStyle}`}
      >
        {showIcon ? (
          <div className={`${changeStyle ? styles.changeStyleGroup : styles.group}`}>
            <div className={`${styles.imgContainer}`}>
              <img src={`/images/langIcons/${nowLang}.png`} alt={nowLang} />
            </div>
          </div>
        ) : null}
        {showTitle ? <div className={`${styles.title} ${titleStyle}`}>{getTitle()}</div> : null}
        {showArrow ? (
          <CaretDownOutlined rotate={showPopover ? 180 : 0} className={styles.arrow} />
        ) : null}
      </div>
    </LangSelector>
  );
}

export default LangSelectorPopover;
