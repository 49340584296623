import { Grid, Image } from "antd-mobile";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import styles from "./style.module.scss";

const HeaderItem = ({ title = "", icon = undefined, content = "0", isMoney = false }) => {
  return (
    <Grid.Item className={styles.itemContainer}>
      <Image className={styles.image} src={icon} />
      <div className={styles.content}>{isMoney ? moneyFormat(content) : parseInt(content)}</div>
      <div className={styles.title}>{title}</div>
    </Grid.Item>
  );
};

export default HeaderItem;
