import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Element } from "react-scroll";
import { useGetUserInfo } from "../hooks/personal/personal.hook";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import styles from "./style.module.scss";

import { PRODUCTION_NAME_DEV } from "../config";
import { disableUserInfoPage } from "../enumerations/disablePathList";
import Background from "./components/Background";

const DefaultLayoutContext = createContext({
  checkLoginProcess: () => {},
  setCheckLoginProcess: () => {},
});
// let checkLoginProcess = () => {};

const Layout = (props) => {
  const [params] = useSearchParams();
  const [checkLoginProcess, setLocalCheckLoginProcess] = useState({
    process: () => true,
  });
  const setCheckLoginProcess = (newFunc) => {
    // checkLoginProcess = newFunc;
    setLocalCheckLoginProcess({ process: newFunc });
  };
  const { navType } = props;
  const dontReloadPages = [
    "/signin",
    "/signup",
    "/",
    "/emailVerify",
    "/forgotPassword",
    "/accessDenied",
    "/inMaintenance",
    "/appDownload",
  ];
  const { data } = useGetUserInfo({
    enabled: !disableUserInfoPage.includes(window.location.pathname),
  });
  let navigate = useNavigate();
  useEffect(() => {
    if (data) {
      if (
        data.email_status == 0 &&
        !dontReloadPages.includes(window.location.pathname) &&
        data.mobile_status == 0
      ) {
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    }
  }, [data, window.location.pathname]);

  // useEffect(() => {
  //   if (params.get("app")) {
  //     window.localStorage.setItem(localStorageKey.IS_APP, true);
  //   }
  // }, [params]);

  return (
    <DefaultLayoutContext.Provider value={{ checkLoginProcess, setCheckLoginProcess }}>
      <Background navType={navType}>
        <div
          className={styles.main}
          style={{
            overflow: "hidden",
            maxWidth: "450px",
            height: navType.doNotInitWindowHeight ? "" : "calc(var(--vh, 1vh) * 100)", //vh * 100,
            margin: "0 auto",
          }}
        >
          {navType.hideHeader ? (
            <></>
          ) : (
            <Navigation
              {...navType}
              goBack={(backURL) => {
                navigate(backURL, { replace: true });
              }}
            />
          )}
          <Element
            className={styles.container}
            id="containerElement"
            style={{
              flex: 1,
              overflow: "scroll",
              background: navType.background
                ? typeof navType.background === "object"
                  ? `linear-gradient(180deg, ${navType.background.start} 0%, ${navType.background.end} 100%)`
                  : navType.background
                : `url("/images/logo/${PRODUCTION_NAME_DEV}/normal_bg.png") no-repeat top / cover #ffffff`,
            }}
          >
            {props.children}
          </Element>
          {navType.hideFooter ? <></> : <Footer boxBg={navType.footerBoxBg} />}
        </div>
      </Background>
    </DefaultLayoutContext.Provider>
  );
};
export default Layout;

export const useDefaultLayout = () => {
  const LayoutContext = useContext(DefaultLayoutContext);
  return LayoutContext;
};
