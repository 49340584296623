import { DownOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetColumns } from "./Columns";
import "./style";
import styles from "./styleModule";

function DetailItem({ data, nowGameText, game }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const columns = useGetColumns();
  //!neccessary when implement new game - start
  const transTimeParamsDict = {
    saba: "bet_time",
    "77lottery": "trans_time",
    bglottery: "order_time",
    blockchain: "trans_time",
    mg: "trans_time",
    default: "trans_time",
  };
  //!neccessary when implement new game - end

  // console.log(data, nowGameText, game);

  useEffect(() => {
    setOpen(false);
  }, [game]);

  return (
    <div className={`${styles.detailItem} ${open && styles.open} bet-record-detail-item`}>
      <div id="copy-group" />
      <div className={`${styles.firstLine}`}>
        <div className={`${styles.gameNameBox}`}>
          <div className={`${styles.gameNameBar}`} />
          <span className={`${styles.gameName}`}>{nowGameText}</span>
        </div>
        <div className={`${styles.updateTime}`}>
          {data[transTimeParamsDict[game] ?? transTimeParamsDict["default"]]}
          <DownOutlined
            style={{ marginLeft: "8px" }}
            className={open && styles.open}
            onClick={() => {
              setOpen(!open);
            }}
            rotate={open ? 180 : 0}
          />
        </div>
      </div>
      <Descriptions title={false} column={1}>
        {columns.getColumns(game)?.map((column) => {
          return column.collapse ? (
            <Descriptions.Item
              label={column.title}
              key={column.dataIndex}
              style={{
                display: open ? "block" : "none",
              }}
            >
              {column.render ? column.render(data) : data[column.dataIndex]}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label={column.title} key={column.dataIndex}>
              {column.render ? column.render(data) : data[column.dataIndex]}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </div>
  );
}

export default DetailItem;
