import { createContext, useContext, useEffect, useState } from "react";

const FocusContext = createContext({
  focusState: true,
});

export const FocusContextProvider = ({ children }) => {
  const [focusState, setFocusState] = useState(true);
  const onFocus = (isFocus) => {
    setFocusState(isFocus);
  };

  useEffect(() => {
    window.addEventListener("focus", () => onFocus(true));
    window.addEventListener("blur", () => onFocus(false));
    return () => {
      window.removeEventListener("focus", () => onFocus(true));
      window.removeEventListener("blur", () => onFocus(false));
    };
  }, []);
  return (
    <FocusContext.Provider
      value={{
        focusState,
      }}
    >
      {children}
    </FocusContext.Provider>
  );
};
export const useFocus = () => {
  const FocusValue = useContext(FocusContext);
  return FocusValue;
};
