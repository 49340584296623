import { Radio } from "antd";
import React from "react";
import "./style";

function ButtonGroup({
  chosenVal,
  setChosenVal,
  options,
  amountInOneRow = 3,
  buttonOnChange = () => {},
}) {
  const classDic = {
    1: "",
    2: "two-in-one-row",
    3: "three-in-one-row",
  };
  return (
    <div className="custom-button-group">
      <Radio.Group
        value={chosenVal}
        buttonStyle="solid"
        options={options}
        optionType="button"
        onChange={(e) => {
          setChosenVal(e.target.value);
          buttonOnChange(e.target.value);
        }}
        className={classDic[amountInOneRow]}
      />
    </div>
  );
}

export default ButtonGroup;
