
const CDN_URL = "https://dl.ball188.cc";
const GLOBAL_CDN = "https://dl-game.ball188.cc";
const GLOBAL_SETTING_API = "globalsetting.ball188.cc";
const GLOBAL_USER_API = "globaluser.ball188.cc";
const IMAGE_API = "image.ball188.cc";
const MAIN_API = "api.ball188.cc";
const WHEEL_API = "rouletteapi.ball188.cc";
const SIGNIN_API = "api.ball188.cc";
const BET_API = "gamebet.ball188.cc";
const PC_URL = "";
const WIN_URL = "gamewinlotteryapi.ball188.cc";
const HISTORY_URL = "gamehistory.ball188.cc";
const PROTOCOL = "https";
const AGENT_CENTER_URL = "http://188agentweb.ball188.cc";
const AGENT_MOBILE_URL = "https://188agent.ball188.cc";
const GAME_SOCKET_URL = "gamewinlotterydata.ball188.cc/ws";
const STOCK_SOCKET_URL = "game77lotterydatastaging.meta188.cc/ws";
const WS_PROTOCOL = "wss";
const DOUBLE_SEVEN_MOBILE_URL = "https://game77lottery.ball188.cc";
const MG_SLOT_MAIN_API = "gamemgapi.ball188.cc";
const T1_MAIN_API = "gamet1api.ball188.cc";
const EVO_MAIN_API = "gameevoapi.ball188.cc";
const PP_SLOT_MAIN_API = "gameppapi.ball188.cc";
const PG_SLOT_MAIN_API = "gamepgapi.ball188.cc";
const YGG_SLOT_MAIN_API = "gameyggapi.ball188.cc";
const EVOPLAY_SLOT_MAIN_API = "gameevoplayapi.ball188.cc";
const JILI_SLOT_MAIN_API = "gamejiliapi.ball188.cc";
const DOUBLE_SEVEN_MAIN_API = "game77lotteryapi.ball188.cc";
const GALAXSYS_MAIN_API = "gamegalaxsysapi.ball188.cc";
const SPRIBE_MAIN_API = "gamejdbapi.ball188.cc";
const PT_MAIN_API = "gameptapi.ball188.cc";
const PPLIVE_MAIN_API = "gameppliveapi.ball188.cc";
const ONEPLAY_MAIN_API = "gameoneapi.ball188.cc";
const PLAYSTAR_MAIN_API = "gameplaystarapi.ball188.cc";
const WEBLOCKCHAIN_MAIN_API = "gameweapi.ball188.cc";
const WELOTTERY_MAIN_API = "gameweapi.ball188.cc";
const WELIVE_MAIN_API = "gameweapi.ball188.cc";
const WESPORT_MAIN_API = "gameweapi.ball188.cc";
const WEFISH_MAIN_API = "gameweapi.ball188.cc";
const WESLOT_MAIN_API = "gameweapi.ball188.cc";
const TCG_MAIN_API = "gametcgapi.ball188.cc";
const YOUBET_MAIN_API = "gameyoubetapi.ball188.cc";
const AESEXY_MAIN_API = "gameaesexyapi.ball188.cc";
const SPRIBEONE_MAIN_API = "gameoneapi.ball188.cc";
const JILICHESS_MAIN_API = {"dev":"gameoneapidev.meta188.cc/api","stag":"gameoneapi.ball188.cc","prod":"gameoneapi.ball188.cc"};
const YBSLOT_MAIN_API = "gameoneapi.ball188.cc";
const LUCKYSPORT_MAIN_API = "gameluckyapi.ball188.cc";
const FCFISH_MAIN_API = "gameoneapi.ball188.cc";
const JDBSLOT_MAIN_API = "gameoneapi.ball188.cc";
const JDBFISH_MAIN_API = "gameoneapi.ball188.cc";
const VIACASINOLIVE_MAIN_API = "gameviacasinoliveapi.ball188.cc";
const DECIMAL_SEPARATOR = '.';
const DEFAULT_CURRENCY = 'brl';
const TIMEZONE_CURRENCY = {};
const DEFAULT_LANG = 'zh';
const FIXED_NUMBER = 5;
const MIN_DECIMAL_POINT = 2;
const MAX_DECIMAL_POINT = 2;
const HOME_HEADER_BACKGROUND_IMAGE = '/images/logo/ball188/background_header.png';
const BINANCE_URL = "https://api.binance.com/api/v3";
const PRODUCTION_NAME_DEV = 'ball188';
const PRODUCTION_NAME_DISPLAY = 'Ball188';
const BASE_PROJECT = ["1bbetv2"]
const THOUSANDS_SEPARATOR = ',';
const HEADER_SHOW_TRANS_RECORD = true;
const ADD_PADDING_BOTTOM = 0;
const HIDE_VIP_CATEGORY = []
const EXTERNAL_GAME = []
const ACCOUNT_VERIFY_METHOD = ["email","phone"]
const GCODE = "GTM-PV45Z6C";
const DOWNLOAD_PAGE_LOGO_FILE_EXTENTION = 'png';
const IS_WALLET_IN_PERSONAL_PAGE = true;
const HIDE_OFFLINE_BANK_CARD_DEPOSIT = false;
const HIDE_INHOUSE_CRYPTO = false;
const LAST_PLAY_COUNT = 3;
const GOOGLE_PLAY_DOWNLOAD_URL = '-';
const APPLICATION_VERSION = "3.3.3";
const SHOW_WITHDRAW_LIST = false;
const SHOW_PROMOTION_DATE = true;
const DOUBLE7_LOTTERY_SHOW_POPUP = true;
const HIDE_PROFILE_PHONE = false;
const SHOW_DEPOSIT_PROMO_IMAGE = false;
const SHOW_DAILY_PROMO = false;
const FORCE_OPEN_DAILY_PROMO = false;
const HOT_GAMES_COUNT = 12;
const WIN_QUICK_BET_POINT = [10,50,100,500,1000,10000]
const FIXED_WIN_OPTIONS = false;
const SENTRY_DSN = "https://45bf6a72e8dfb5a0622ea4196786ac5c@o4507110666272768.ingest.us.sentry.io/4507185783308288";
const OPEN_SENTRY = false;
const OPEN_SABA_CRICKET = false;
const WIN_RECORD_DELAY_TIMER = 5;
const WIN_UNFOCUS_RELOAD_POPUP = true;
const SHOW_HOT_AS_PLATFORM_LIST = false;
const LOGIN_CLOUDFLARE_KEY = "";
const SHOW_YOUTUBE_CAROUSEL = false;
const HEAD_FBPIXEL_ID = "1322587768340544";
const HEAD_51LA_ID = "";
const HEAD_FBVERIFY_CONTENT = "";
const SHOW_TG_BUBBLE = true;
const ENV = "production";

 export {
CDN_URL,
GLOBAL_CDN,
GLOBAL_SETTING_API,
GLOBAL_USER_API,
IMAGE_API,
MAIN_API,
WHEEL_API,
SIGNIN_API,
BET_API,
PC_URL,
WIN_URL,
HISTORY_URL,
PROTOCOL,
AGENT_CENTER_URL,
AGENT_MOBILE_URL,
GAME_SOCKET_URL,
STOCK_SOCKET_URL,
WS_PROTOCOL,
DOUBLE_SEVEN_MOBILE_URL,
MG_SLOT_MAIN_API,
T1_MAIN_API,
EVO_MAIN_API,
PP_SLOT_MAIN_API,
PG_SLOT_MAIN_API,
YGG_SLOT_MAIN_API,
EVOPLAY_SLOT_MAIN_API,
JILI_SLOT_MAIN_API,
DOUBLE_SEVEN_MAIN_API,
GALAXSYS_MAIN_API,
SPRIBE_MAIN_API,
PT_MAIN_API,
PPLIVE_MAIN_API,
ONEPLAY_MAIN_API,
PLAYSTAR_MAIN_API,
WEBLOCKCHAIN_MAIN_API,
WELOTTERY_MAIN_API,
WELIVE_MAIN_API,
WESPORT_MAIN_API,
WEFISH_MAIN_API,
WESLOT_MAIN_API,
TCG_MAIN_API,
YOUBET_MAIN_API,
AESEXY_MAIN_API,
SPRIBEONE_MAIN_API,
JILICHESS_MAIN_API,
YBSLOT_MAIN_API,
LUCKYSPORT_MAIN_API,
FCFISH_MAIN_API,
JDBSLOT_MAIN_API,
JDBFISH_MAIN_API,
VIACASINOLIVE_MAIN_API,
DECIMAL_SEPARATOR,
DEFAULT_CURRENCY,
TIMEZONE_CURRENCY,
DEFAULT_LANG,
FIXED_NUMBER,
MIN_DECIMAL_POINT,
MAX_DECIMAL_POINT,
HOME_HEADER_BACKGROUND_IMAGE,
BINANCE_URL,
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
BASE_PROJECT,
THOUSANDS_SEPARATOR,
HEADER_SHOW_TRANS_RECORD,
ADD_PADDING_BOTTOM,
HIDE_VIP_CATEGORY,
EXTERNAL_GAME,
ACCOUNT_VERIFY_METHOD,
GCODE,
DOWNLOAD_PAGE_LOGO_FILE_EXTENTION,
IS_WALLET_IN_PERSONAL_PAGE,
HIDE_OFFLINE_BANK_CARD_DEPOSIT,
HIDE_INHOUSE_CRYPTO,
LAST_PLAY_COUNT,
GOOGLE_PLAY_DOWNLOAD_URL,
APPLICATION_VERSION,
SHOW_WITHDRAW_LIST,
SHOW_PROMOTION_DATE,
DOUBLE7_LOTTERY_SHOW_POPUP,
HIDE_PROFILE_PHONE,
SHOW_DEPOSIT_PROMO_IMAGE,
SHOW_DAILY_PROMO,
FORCE_OPEN_DAILY_PROMO,
HOT_GAMES_COUNT,
WIN_QUICK_BET_POINT,
FIXED_WIN_OPTIONS,
SENTRY_DSN,
OPEN_SENTRY,
OPEN_SABA_CRICKET,
WIN_RECORD_DELAY_TIMER,
WIN_UNFOCUS_RELOAD_POPUP,
SHOW_HOT_AS_PLATFORM_LIST,
LOGIN_CLOUDFLARE_KEY,
SHOW_YOUTUBE_CAROUSEL,
HEAD_FBPIXEL_ID,
HEAD_51LA_ID,
HEAD_FBVERIFY_CONTENT,
SHOW_TG_BUBBLE,
ENV,
}