import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style";

const CustomTab = ({
  onTabChange = () => {},
  tabItems = [],
  activeKey,
  tabBarStyle = {},
  className = "",
}) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  return (
    <div className={className ? className : "tab-container"}>
      <Tabs
        tabBarStyle={className ? {} : { display: "flex", flexGrow: "1", ...tabBarStyle }}
        tabBarGutter={0}
        centered
        onChange={onTabChange}
        activeKey={activeKey}
      >
        {tabItems?.map((tabItem, index) => {
          return (
            <TabPane
              key={`${tabItem.key}`}
              tab={tabItem.translate ? t(`${tabItem.name}`) : tabItem.name}
            >
              {tabItem.component}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default CustomTab;
